import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import Util from "../../../../util/Util";
import {
  addDeskProduct,
  updateDeskProduct,
  addDeskProductFile,
  addDeskCategoryRelated,
  addDeskProductRelated,
  deleteDeskProductRelated,
  deleteDeskCategoryRelated,
  checkIfDeskProductRefExists,
} from "../../../../actions/settings/deskCategories/admin/deskProducts";
import FileDropZone from "../../../sub/FileDropZone";
import Select from "react-select";
import ConfirmationModal from "../../../sub/modals/ConfirmationModal";
import APIUrl from "../../../../APIUrl";
import { Alert, Col, Modal, Tab, Tabs, Form, Row } from "react-bootstrap";
import MenuButton from "../../../sub/bootstrap/MenuButton";
import InputLength from "../../../../enums/InputLength.js";
import InputTextElement from "../../../sub/InputTextElement.js";
import TextareaCounter from "../../../sub/TextareaCounter";
import DeskProductsControlPoints from "./DeskProductsControlPoints.js";
import DeskProductsCatalogProducts from "./DeskProductsCatalogProducts";
import SwitchToggle from "../../../sub/SwitchToggle";
import CustomLabel from "../../../sub/CustomLabel";

class DeskProductsModalAdmin extends React.Component {
  constructor(props) {
    super(props);

    this.defaultPrescriptionText =
      "L'état de santé de M/Mme .......................... nécessite :";

    this.state = {
      image: "",
      modal: null,
      name: "",
      ref: "",
      category_id: this.props.deskCategoryId,
      description_text: "",
      isRenewalObligatory: false,
      refundable: false,
      vte_loc: "Location",
      renouv_vte: "",
      prescription_type_vte_text: "",
      prescription_type_loc_text: "",
      type: "",
      disabled: false,
      productRelatedId: "",
      categoryRelatedId: "",
      productRelatedValue: {},
      categoryRelatedValue: {},
    };

    if (this.props.deskProductId) {
      const product = this.props.deskProducts.find(
        (r) => r._id === this.props.deskProductId,
      );
      if (product) {
        this.state = {
          name: product.name,
          category_id: product.category_id,
          description_text: product.description_text,
          ref: product.ref,
          isRenewalObligatory: product.is_renewal_obligatory,
          refundable: product.refundable,
          vte_loc: product.vte_loc,
          renouv_vte: product.renouv_vte,
          prescription_type_vte_text: product.prescription_type_vte_text,
          prescription_type_loc_text: product.prescription_type_loc_text,
          nameError: null,
          disabled: false,
          modal: null,
        };
      }
    }
  }

  openConfModal(title, content, successCallback) {
    this.setState({
      modal: (
        <ConfirmationModal
          title={title}
          variant="warning"
          onAccept={successCallback}
          onDecline={() => this.closeModal()}
        >
          {content}
        </ConfirmationModal>
      ),
    });
  }

  closeModal() {
    this.setState({ modal: null });
  }

  onDropFile(file, clearCallback) {
    if (!file || file.length === 0) return;

    // Update mode. Immediately send file to BE
    if (this.props.deskProductId) {
      this.setState({ disabledFileUpload: true });

      let formData = new FormData();
      formData.append(file.name, file);

      var successCallback = () => {
        this.setState({ disabledFileUpload: false });
        clearCallback();
      };

      // Send to BE
      this.props.onAddDeskProductFile(
        this.props.deskProductId,
        formData,
        successCallback,
      );
    }
    // Create mode. Store the file and wait for the onComplete event
    else {
      this.setState({ file: file });
    }
  }

  onSubmit() {
    var sendfile = (product) => {
      if (!this.state.file || this.state.file.length === 0)
        return this.props.closeModal();

      let formData = new FormData();
      formData.append(this.state.file.name, this.state.file);

      this.props.onAddDeskProductFile(product._id, formData, () =>
        this.props.closeModal(),
      );
    };

    var imageExist = !!this.state.file;

    const deskProductsCategory = this.props.deskProducts.filter(
      (p) => p.category_id === this.props.deskCategoryId,
    );

    var data = {
      name: this.state.name,
      ref: this.state.ref,
      description_text: this.state.description_text,
      image: imageExist,
      category_id: this.state.category_id,
      refundable: this.state.refundable,
      vte_loc: this.state.vte_loc,
      renouv_vte: this.state.renouv_vte,
      prescription_type_vte_text: this.state.prescription_type_vte_text,
      prescription_type_loc_text: this.state.prescription_type_loc_text,
      sortIndex: deskProductsCategory ? deskProductsCategory.length : 1,
    };

    this.props.onAddDeskProduct(data, sendfile);
  }

  disabled() {
    return (
      Util.emptyString(this.state.name) ||
      Util.emptyString(this.state.ref) ||
      Util.emptyString(this.state.categorie_type)
    );
  }

  onChange(field, value) {
    this.setState({ [field]: value });
  }

  checkErrors() {
    return (
      this.state.nameError ||
      this.state.refError ||
      this.state.disabled ||
      Util.emptyString(this.state.name) ||
      Util.emptyString(this.state.ref)
    );
  }

  checkIfDeskProductRefExists(value) {
    this.props.onCheckIfDeskProductRefExists(
      { ref: value },
      () =>
        this.setState({
          refError: <FormattedMessage id={"Product.Ref.Already.Exists"} />,
        }),
      () => this.setState({ nameError: "" }),
    );
  }

  onUpdate(updatedField, updatedValue) {
    if (!this.props.deskProductId || this.checkErrors()) return;

    if (updatedField === "name" && Util.emptyString(updatedValue)) return;

    const data = {
      _id: this.props.deskProductId,
      [updatedField]: updatedValue,
    };

    this.props.onUpdateDeskProduct(data);
  }

  handleProductRelatedChange = (newValue, actionMeta) => {
    if (newValue && newValue.value) {
      this.setState({
        productRelatedValue: newValue,
        productRelatedId: newValue.value,
      });
    }
  };

  AddProductRelated() {
    if (!this.props.deskProductId || !this.state.productRelatedId) return;

    const product = this.props.deskProducts.find(
      (r) => r._id === this.props.deskProductId,
    );

    if (product.relatedProducts.indexOf(this.state.productRelatedId) === -1) {
      var data = {
        deskProductId: this.props.deskProductId,
        productRelatedId: this.state.productRelatedId,
      };

      this.props.onAddProductRelated(data);

      this.setState({ productRelatedValue: null });
    } else {
      alert("Le produit est deja dans la liste");
    }
  }

  deleteProductRelated(productRelatedId, index) {
    if (!this.props.deskProductId) return;

    var data = {
      deskProductId: this.props.deskProductId,
      productRelatedId: productRelatedId,
      index: index,
    };
    this.props.onDeleteDeskProductRelated(data);
  }

  handleCategoryRelatedChange = (newValue, actionMeta) => {
    if (newValue && newValue.value) {
      this.setState({
        categoryRelatedValue: newValue,
        categoryRelatedId: newValue.value,
      });
    }
  };

  AddCategoryRelated() {
    if (!this.props.deskProductId || !this.state.categoryRelatedId) return;

    const product = this.props.deskProducts.find(
      (r) => r._id === this.props.deskProductId,
    );

    if (
      product.relatedCategories.indexOf(this.state.categoryRelatedId) === -1
    ) {
      var data = {
        deskProductId: this.props.deskProductId,
        categoryRelatedId: this.state.categoryRelatedId,
      };

      this.props.onAddCategoryRelated(data);

      this.setState({ categoryRelatedValue: null });
    } else {
      alert("La categorie est deja dans la liste des categories associes");
    }
  }

  deleteCategoryRelated(categoryRelatedId, index) {
    if (!this.props.deskProductId) return;

    var data = {
      deskProductId: this.props.deskProductId,
      categoryRelatedId: categoryRelatedId,
      index: index,
    };

    this.props.onDeleteDeskCategoryRelated(data);
  }

  onSort(fieldName, array, index, direction) {
    direction = direction === "up" || direction === "down" ? direction : "down";

    const newIndex = direction === "up" ? index - 1 : index + 1;

    const newArray = [...array];
    const temp = newArray[index];
    newArray[index] = newArray[newIndex];
    newArray[newIndex] = temp;

    const data = {
      _id: this.props.deskProductId,
      [fieldName]: newArray,
    };

    this.props.onUpdateDeskProduct(data);
  }

  render() {
    const { deskProducts, deskCategories, deskProductId } = this.props;

    if (!deskCategories) return null;

    const product = deskProducts.find((p) => p._id === deskProductId);

    const modalTitle = <FormattedMessage id="Confirm" />;
    const modalContent = (type) => (
      <FormattedMessage
        id={
          type === " product"
            ? "Type.Associated.Product.Remove.Confirmation"
            : "Type.Associated.Category.Remove.Confirmation"
        }
      />
    );

    const productsRelatedFilter =
      product && product.relatedProducts
        ? deskProducts.filter(
            (p) =>
              !product.relatedProducts.includes(p._id) && product._id !== p._id,
          )
        : [];
    const productsRelated = productsRelatedFilter.map((deskProduct) => {
      return {
        value: deskProduct._id,
        label: deskProduct.name,
      };
    });

    var productsRelatedNodes = "";
    if (product && product.relatedProducts && product.relatedProducts.length) {
      productsRelatedNodes = product.relatedProducts.map(
        (productRelatedId, idx) => {
          const productRelated = deskProducts.find(
            (p) => p._id === productRelatedId,
          );
          const disabledUp = idx === 0;
          const disabledDown = idx >= product.relatedProducts.length - 1;
          return (
            <tr key={"productrelated-" + productRelated._id}>
              <td className="align-middle">{productRelated.name}</td>
              <td className="w-auto text-end text-nowrap">
                <MenuButton
                  icon="arrow-up"
                  className="me-1"
                  onClick={() =>
                    !disabledUp &&
                    this.onSort(
                      "relatedProducts",
                      product.relatedProducts,
                      idx,
                      "up",
                    )
                  }
                  disabled={disabledUp}
                />
                <MenuButton
                  icon="arrow-down"
                  className="me-1"
                  onClick={() =>
                    !disabledDown &&
                    this.onSort(
                      "relatedProducts",
                      product.relatedProducts,
                      idx,
                      "down",
                    )
                  }
                  disabled={disabledDown}
                />
                <MenuButton
                  icon="trash"
                  onClick={() =>
                    this.openConfModal(
                      modalTitle,
                      modalContent("product"),
                      () => this.deleteProductRelated(productRelated._id, idx),
                    )
                  }
                />
              </td>
            </tr>
          );
        },
      );
    } else {
      productsRelatedNodes = (
        <tr>
          <td colSpan={2}>
            <Alert variant="warning">
              <FormattedMessage
                id="Empty.Types.Products.Associated"
                values={{ type: this.state.name }}
              />
            </Alert>
          </td>
        </tr>
      );
    }

    const categoriesRelatedFilter =
      product && product.relatedCategories
        ? deskCategories.filter(
            (c) =>
              !product.relatedCategories.includes(c._id) &&
              product.category_id !== c._id,
          )
        : [];
    const categoriesRelated = categoriesRelatedFilter.map((deskCategory) => {
      return {
        value: deskCategory._id,
        label: deskCategory.name,
      };
    });

    var categoriesRelatedNodes = "";
    if (
      product &&
      product.relatedCategories &&
      product.relatedCategories.length
    ) {
      categoriesRelatedNodes = product.relatedCategories.map(
        (categoryRelatedId, idx) => {
          const categoryRelated = deskCategories.find(
            (c) => c._id === categoryRelatedId,
          );
          const disabledUp = idx === 0;
          const disabledDown = idx >= product.relatedCategories.length - 1;
          return (
            <tr key={"categoryRelated-" + categoryRelated._id}>
              <td className="align-middle">{categoryRelated.name}</td>
              <td className="col-auto text-nowrap">
                <MenuButton
                  icon="arrow-up"
                  className="me-1"
                  onClick={() =>
                    !disabledUp &&
                    this.onSort(
                      "relatedCategories",
                      product.relatedCategories,
                      idx,
                      "up",
                    )
                  }
                  disabled={disabledUp}
                />
                <MenuButton
                  className="me-1"
                  icon="arrow-down"
                  onClick={() =>
                    !disabledDown &&
                    this.onSort(
                      "relatedCategories",
                      product.relatedCategories,
                      idx,
                      "down",
                    )
                  }
                  disabled={disabledDown}
                />
                <MenuButton
                  icon="trash"
                  onClick={() =>
                    this.openConfModal(
                      modalTitle,
                      modalContent("category"),
                      () =>
                        this.deleteCategoryRelated(categoryRelated._id, idx),
                    )
                  }
                />
              </td>
            </tr>
          );
        },
      );
    } else {
      categoriesRelatedNodes = (
        <tr>
          <td colSpan={2}>
            <Alert variant="warning">
              <FormattedMessage
                id="Empty.Types.Categories.Associated"
                values={{ type: this.state.name }}
              />
            </Alert>
          </td>
        </tr>
      );
    }

    let img;

    if (product) {
      const imgSrc =
        APIUrl.getDeskProductImg +
        product._id +
        "/" +
        Math.random() +
        "?token=" +
        APIUrl.jwtToken;
      img = (
        <img
          src={imgSrc}
          className="col-form-label m-3"
          alt={"desk_category_img" + Math.random()}
          height="150"
          width="150"
        />
      );
    }

    const customStyles = {
      control: (base, state) => ({
        ...base,
        width: 300,
        background: "#fff",
        borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
        boxShadow: state.isFocused ? null : null,
      }),
      menu: (base) => ({
        ...base,
        borderRadius: 0,
        hyphens: "auto",
        marginTop: 0,
        textAlign: "left",
        wordWrap: "break-word",
      }),
      menuList: (base) => ({
        ...base,
        padding: 0,
      }),
    };

    return (
      <Modal
        show={true}
        onHide={() => this.props.closeModal()}
        backdrop={"static"}
        size={"xl"}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {product ? (
              <>
                <FormattedMessage id="Update.Type.Product" /> : {product.name}
              </>
            ) : (
              <FormattedMessage id="Add.Product" />
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tabs defaultActiveKey="general" id="deskProductTabs" mountOnEnter>
            <Tab
              eventKey="general"
              title={this.props.intl.formatMessage({
                id: "General.Informations",
              })}
            >
              {product && (
                <div className="d-none d-lg-block w-150 rounded light-card text-center">
                  <div className="m-0 p-2">
                    {img}
                    {product.image && (
                      <MenuButton
                        icon="trash"
                        variant="outline-dark"
                        onClick={() => this.onUpdate("image", false)}
                        hover={<FormattedMessage id="Restore.Default.Image" />}
                      />
                    )}
                  </div>
                </div>
              )}

              {/* Name */}
              <Form.Group as={Row} className="align-items-center mb-3">
                <CustomLabel
                  label={<FormattedMessage id="Name" />}
                  htmlFor="name"
                  displayAsCol
                  required
                />
                <Col md={7}>
                  <InputTextElement
                    className="form-control"
                    type="text"
                    id="name"
                    maxLength={InputLength.NAME}
                    autoComplete="off"
                    value={this.state.name}
                    onChange={(e) => this.onChange("name", e.target.value)}
                    onBlur={(e) => this.onUpdate("name", e.target.value)}
                  />
                </Col>
              </Form.Group>

              {/* Ref */}
              <Form.Group as={Row} className="align-items-center mb-3">
                <CustomLabel
                  label={<FormattedMessage id="Ref" />}
                  htmlFor="ref"
                  displayAsCol
                  required
                />
                <Col md={7}>
                  <InputTextElement
                    className="form-control"
                    type="text"
                    id="ref"
                    maxLength={InputLength.ID}
                    autoComplete="off"
                    value={this.state.ref}
                    onChange={(e) => this.onChange("ref", e.target.value)}
                    onBlur={(e) => {
                      this.checkIfDeskProductRefExists(this.state.ref);
                      this.onUpdate("ref", e.target.value);
                    }}
                  />
                  <div className="text-danger">
                    <small>{this.state.refError}</small>
                  </div>
                </Col>
              </Form.Group>

              {/* Category  */}
              <Form.Group as={Row} className="align-items-center mb-3">
                <CustomLabel
                  label={<FormattedMessage id="Category" />}
                  htmlFor="categorie_type"
                  displayAsCol
                  required
                />
                <Col md={7}>
                  <select
                    className="form-control w-100"
                    value={this.state.category_id}
                    onChange={(e) =>
                      this.onChange("category_id", e.target.value)
                    }
                    onBlur={(e) => this.onUpdate("category_id", e.target.value)}
                  >
                    <option value="" disabled={true}></option>
                    {deskCategories.map((c) => (
                      <option value={c._id} key={c._id}>
                        {c.name}
                      </option>
                    ))}
                  </select>
                </Col>
              </Form.Group>

              {/* Visuel */}
              <Form.Group as={Row} className="align-items-center mb-3">
                <CustomLabel
                  label={<FormattedMessage id="Visuel" />}
                  htmlFor="logo"
                  displayAsCol
                />
                <Col md={7}>
                  <FileDropZone
                    disabled={this.state.disabledDropZone}
                    onDropFile={(file, clearCallback) =>
                      this.onDropFile(file, clearCallback)
                    }
                    acceptedExtensions={["jpg", "png", "jpeg"]}
                    multiple={false}
                  />
                </Col>
              </Form.Group>

              {/* Txt Descriptif */}
              <Form.Group as={Row} className="align-items-center mb-3">
                <CustomLabel
                  label={<FormattedMessage id="Description" />}
                  htmlFor="defaultText"
                  displayAsCol
                />
                <Col md={7} id="defaultText">
                  <TextareaCounter
                    countLimit={InputLength.TEXT_LONG}
                    id="description"
                    rows="7"
                    value={this.state.description_text}
                    onChange={(e) =>
                      this.onChange("description_text", e.target.value)
                    }
                    onBlur={(e) =>
                      this.onUpdate(
                        "description_text",
                        this.state.description_text,
                      )
                    }
                  />
                </Col>
              </Form.Group>
            </Tab>
            <Tab
              eventKey="prescription"
              disabled={!this.props.deskProductId}
              title={this.props.intl.formatMessage({ id: "Prescription" })}
            >
              {this.props.deskProductId && (
                <>
                  {/* Select Type Locations / Ventes vte_loc */}
                  <Form.Group as={Row} className="align-items-center mb-3">
                    <CustomLabel
                      label={<FormattedMessage id="Type" />}
                      htmlFor="type"
                      className="col-12 col-sm-5 col-form-label"
                      displayAsCol
                    />
                    <Col md={7}>
                      <select
                        className="form-control w-100"
                        value={this.state.vte_loc}
                        onChange={(e) =>
                          this.onChange("vte_loc", e.target.value)
                        }
                        onBlur={(e) => this.onUpdate("vte_loc", e.target.value)}
                      >
                        <option value="Vente">
                          {this.props.intl.formatMessage({
                            id: "EqSellMode.0",
                          })}
                        </option>
                        <option value="Location">
                          {this.props.intl.formatMessage({
                            id: "EqSellMode.1",
                          })}
                        </option>
                        <option value="LocationVente">
                          {this.props.intl.formatMessage({
                            id: "EqSellMode.2",
                          })}
                        </option>
                      </select>
                    </Col>
                  </Form.Group>

                  {/* Refundable */}
                  <Form.Group as={Row} className="align-items-center mb-3">
                    <CustomLabel
                      label={<FormattedMessage id="Refundable" />}
                      htmlFor="on_leave-switch"
                      displayAsCol
                    />
                    <Col md={7}>
                      <SwitchToggle
                        id="on_leave-switch"
                        onChange={(e) =>
                          this.onChange("refundable", !this.state.refundable)
                        }
                        checked={this.state.refundable}
                        onBlur={(e) =>
                          this.onUpdate("refundable", this.state.refundable)
                        }
                      />
                    </Col>
                  </Form.Group>

                  {this.state.refundable && (
                    <>
                      {this.state.vte_loc !== "Location" && (
                        <Form.Group
                          as={Row}
                          className="align-items-center mb-3"
                        >
                          <CustomLabel
                            label={<FormattedMessage id="Purchase.Sails" />}
                            htmlFor="purchase_sail"
                            displayAsCol
                          />
                          <Col md={7}>
                            <select
                              className="form-control d-inline"
                              id="renouv_vte"
                              value={this.state.renouv_vte}
                              onChange={(e) =>
                                this.onChange("renouv_vte", e.target.value)
                              }
                              onBlur={(e) =>
                                this.onUpdate(
                                  "renouv_vte",
                                  this.state.renouv_vte,
                                )
                              }
                            >
                              <option
                                value="1"
                                defaultValue={
                                  this.props.renouv_vte === "1" ? true : false
                                }
                              >
                                1{" "}
                                {this.props.intl.formatMessage({ id: "Year" })}
                              </option>
                              <option
                                value="2"
                                defaultValue={
                                  this.props.renouv_vte === "2" ? true : false
                                }
                              >
                                2{" "}
                                {this.props.intl.formatMessage({ id: "Years" })}
                              </option>
                              <option
                                value="3"
                                defaultValue={
                                  this.props.renouv_vte === "3" ? true : false
                                }
                              >
                                3{" "}
                                {this.props.intl.formatMessage({ id: "Years" })}
                              </option>
                              <option
                                value="5"
                                defaultValue={
                                  this.props.renouv_vte === "5" ? true : false
                                }
                              >
                                5{" "}
                                {this.props.intl.formatMessage({ id: "Years" })}
                              </option>
                            </select>
                          </Col>
                        </Form.Group>
                      )}

                      <Form.Group as={Row} className="align-items-center mb-3">
                        <CustomLabel
                          label={<FormattedMessage id="Renewal.Obligatory" />}
                          htmlFor="is_renewal_obligatory"
                          displayAsCol
                        />
                        <Col md={7}>
                          <SwitchToggle
                            id="is_renewal_obligatory"
                            onChange={(e) =>
                              this.onChange(
                                "isRenewalObligatory",
                                !this.state.isRenewalObligatory,
                              )
                            }
                            checked={this.state.isRenewalObligatory}
                            onBlur={(e) =>
                              this.onUpdate(
                                "is_renewal_obligatory",
                                this.state.isRenewalObligatory,
                              )
                            }
                          />
                        </Col>
                      </Form.Group>

                      {/* Prescription Type Location */}
                      {(this.state.vte_loc === "Location" ||
                        this.state.vte_loc === "LocationVente") && (
                        <Form.Group
                          as={Row}
                          className="align-items-center mb-3"
                        >
                          <CustomLabel
                            label={<FormattedMessage id="Prescription.Loc" />}
                            htmlFor="prescription_type_loc_text"
                            displayAsCol
                          />
                          <Col md={7} id="prescription_type_loc_text">
                            <TextareaCounter
                              countLimit={InputLength.TEXT}
                              value={this.state.prescription_type_loc_text}
                              id="prescription_type_loc_text"
                              rows="4"
                              placeholder={this.defaultPrescriptionText}
                              onChange={(e) =>
                                this.onChange(
                                  "prescription_type_loc_text",
                                  e.target.value,
                                )
                              }
                              onBlur={(e) =>
                                this.onUpdate(
                                  "prescription_type_loc_text",
                                  this.state.prescription_type_loc_text,
                                )
                              }
                            />
                          </Col>
                        </Form.Group>
                      )}

                      {/* Prescription Type Vente */}
                      {(this.state.vte_loc === "Vente" ||
                        this.state.vte_loc === "LocationVente") && (
                        <Form.Group
                          as={Row}
                          className="align-items-center mb-3"
                        >
                          <CustomLabel
                            label={<FormattedMessage id="Prescription.Sail" />}
                            htmlFor="prescription_type_vte_text"
                            displayAsCol
                          />
                          <Col md={7} id="prescription_type_vte_text">
                            <TextareaCounter
                              countLimit={InputLength.TEXT}
                              value={this.state.prescription_type_vte_text}
                              id="prescription_type_vte_text"
                              rows="4"
                              placeholder={this.defaultPrescriptionText}
                              onChange={(e) =>
                                this.onChange(
                                  "prescription_type_vte_text",
                                  e.target.value,
                                )
                              }
                              onBlur={(e) =>
                                this.onUpdate(
                                  "prescription_type_vte_text",
                                  this.state.prescription_type_vte_text,
                                )
                              }
                            />
                          </Col>
                        </Form.Group>
                      )}
                    </>
                  )}
                </>
              )}
            </Tab>
            {this.props.deskProductId && (
              <Tab
                eventKey="association"
                disabled={!this.props.deskProductId}
                title={this.props.intl.formatMessage({ id: "Associations" })}
              >
                <Tabs
                  defaultActiveKey="associatedProducts"
                  id="Associations"
                  mountOnEnter
                >
                  <Tab
                    eventKey="associatedProducts"
                    disabled={!this.props.deskProductId}
                    title={`${this.props.intl.formatMessage({ id: "Types.Products.Associated" })}`}
                  >
                    {/* Associated Products */}
                    <Alert variant="info">
                      <FormattedMessage id="Types.Products.Associated.Notice" />
                    </Alert>
                    <table className="table table-striped tablee4mad mt-3">
                      <thead>
                        <tr>
                          <th className="align-middle w-100">
                            <Row className="align-items-center">
                              <Col className="col-auto pe-0">
                                <Select
                                  styles={customStyles}
                                  isClearable
                                  value={this.state.productRelatedValue}
                                  onChange={this.handleProductRelatedChange}
                                  options={productsRelated}
                                />
                              </Col>
                              <Col>
                                <MenuButton
                                  type="button"
                                  icon="square-plus"
                                  size="sm"
                                  onClick={() => this.AddProductRelated()}
                                />
                              </Col>
                            </Row>
                          </th>
                          <th className="align-middle text-center">
                            <FormattedMessage id="Actions" />
                          </th>
                        </tr>
                      </thead>
                      {/* Types af associated products */}
                      {productsRelatedNodes !== "" && (
                        <tbody>{productsRelatedNodes}</tbody>
                      )}
                    </table>
                  </Tab>
                  <Tab
                    eventKey="associatedCategories"
                    disabled={!this.props.deskProductId}
                    title={`${this.props.intl.formatMessage({ id: "Types.Categories.Associated" })}`}
                  >
                    {/* Types af associated categories */}
                    <Alert variant="info">
                      <FormattedMessage id="Types.Categories.Associated.Notice" />
                    </Alert>
                    <table className="table table-striped tablee4mad mt-3">
                      <thead>
                        <tr>
                          <th className="align-middle w-100">
                            <Row className="align-items-center">
                              <Col className="col-auto pe-0">
                                <Select
                                  styles={customStyles}
                                  isClearable
                                  value={this.state.categoryRelatedValue}
                                  onChange={this.handleCategoryRelatedChange}
                                  options={categoriesRelated}
                                  //placeholder="Search..."
                                />
                              </Col>
                              <Col>
                                <MenuButton
                                  icon="square-plus"
                                  size="sm"
                                  onClick={() => this.AddCategoryRelated()}
                                />
                              </Col>
                            </Row>
                          </th>
                          <th className="align-middle text-center">
                            <FormattedMessage id="Actions" />
                          </th>
                        </tr>
                      </thead>
                      {categoriesRelatedNodes !== "" && (
                        <tbody>{categoriesRelatedNodes}</tbody>
                      )}
                    </table>
                  </Tab>
                  <Tab
                    eventKey="productsFromCatalog"
                    disabled={!this.props.deskProductId}
                    title={`${this.props.intl.formatMessage({ id: "Products.From.Catalog" })}`}
                  >
                    <DeskProductsCatalogProducts
                      deskProductId={this.props.deskProductId}
                      productsFromCatalog={product.productsFromCatalog}
                    />
                  </Tab>
                </Tabs>
              </Tab>
            )}
            <Tab
              eventKey="arguments"
              disabled={!this.props.deskProductId}
              title={`${this.props.intl.formatMessage({ id: "Arguments" })}`}
            >
              <Tabs defaultActiveKey="questionnary" id="Arguments" mountOnEnter>
                <Tab
                  eventKey="questionnary"
                  disabled={!this.props.deskProductId}
                  title={`${this.props.intl.formatMessage({ id: "Questionnary" })}`}
                >
                  {this.props.deskProductId && (
                    <>
                      <DeskProductsControlPoints
                        deskProductId={this.props.deskProductId}
                        controlPoints={product.relatedQuestions}
                        productTypeName={this.state.name}
                        controlPointType={"question"}
                      />
                    </>
                  )}
                </Tab>
                <Tab
                  eventKey="recommendations"
                  disabled={!this.props.deskProductId}
                  title={`${this.props.intl.formatMessage({ id: "Recommendations" })}`}
                >
                  {this.props.deskProductId && (
                    <>
                      <DeskProductsControlPoints
                        deskProductId={this.props.deskProductId}
                        controlPoints={product.relatedRecommendations}
                        productTypeName={this.state.name}
                        controlPointType={"recommendation"}
                      />
                    </>
                  )}
                </Tab>
              </Tabs>
            </Tab>
          </Tabs>
        </Modal.Body>

        {!this.props.deskProductId && (
          <Modal.Footer>
            <MenuButton
              variant="secondary"
              onClick={() => this.props.closeModal()}
            >
              <FormattedMessage id="Cancel" />
            </MenuButton>
            <MenuButton
              disabled={this.disabled()}
              onClick={() => this.onSubmit()}
            >
              <FormattedMessage id="Add" />
            </MenuButton>
          </Modal.Footer>
        )}

        {this.state.modal}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    deskProducts: state.deskProducts,
    deskCategories: state.deskCategories,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddDeskProduct: (deskProduct, successCallback) =>
      dispatch(addDeskProduct(deskProduct, successCallback)),
    onUpdateDeskProduct: (data) => dispatch(updateDeskProduct(data)),
    onAddDeskProductFile: (deskProductId, data, successCallback) =>
      dispatch(addDeskProductFile(deskProductId, data, successCallback)),
    onAddProductRelated: (data, successCallback) =>
      dispatch(addDeskProductRelated(data)),
    onAddCategoryRelated: (data, successCallback) =>
      dispatch(addDeskCategoryRelated(data)),
    onDeleteDeskCategoryRelated: (data, successCallback) =>
      dispatch(deleteDeskCategoryRelated(data)),
    onDeleteDeskProductRelated: (data, successCallback) =>
      dispatch(deleteDeskProductRelated(data)),
    onCheckIfDeskProductRefExists: (data, existsCallback, noExistsCallback) =>
      dispatch(
        checkIfDeskProductRefExists(data, existsCallback, noExistsCallback),
      ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(DeskProductsModalAdmin));
