import React from "react";
import { FormattedMessage } from "react-intl";
import { Modal } from "react-bootstrap";
import InputLength from "../../../enums/InputLength";
import TextareaCounter from "../../sub/TextareaCounter";
import MenuButton from "../../sub/bootstrap/MenuButton";

export default class DeskProductComment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      comment: this.props.deskStoreProduct.comment,
    };
  }

  confirm() {
    const data = this.props.deskStoreProduct;
    data.comment = this.state.comment;
    this.props.onDeskStore(this.props.product, data, () =>
      this.props.closeModal(),
    );
  }

  render() {
    return (
      <Modal
        show={true}
        onHide={() => this.props.closeModal()}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id="Comment" />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <TextareaCounter
            countLimit={InputLength.TEXT}
            id="comment"
            rows={6}
            value={this.state.comment}
            onChange={(e) => this.setState({ comment: e.target.value })}
          />
        </Modal.Body>

        <Modal.Footer>
          <MenuButton onClick={() => this.confirm()}>
            <FormattedMessage id="Confirm" />
          </MenuButton>
        </Modal.Footer>
      </Modal>
    );
  }
}
