import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import APIUrl from "../../../APIUrl";
import Icon from "../../sub/Icon";
import { Alert, Container, Col, Card, Row, FormSelect } from "react-bootstrap";
import TableToolbar from "../../sub/bootstrap/TableToolbar";
import MenuButton from "../../sub/bootstrap/MenuButton";
import Notification from "../../sub/Notification";
import ConfirmationModal from "../../sub/modals/ConfirmationModal";
import { nanoid } from "nanoid";
import ImageWithFallback from "../../sub/ImageWithFallback";
import DeskProductCard from "./DeskProductCard";
import PopoverHelper from "../../sub/bootstrap/PopoverHelper";

class DeskProductDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
      vte_loc:
        this.props.product.vte_loc !== "LocationVente"
          ? this.props.product.vte_loc
          : "Location",
    };
  }

  addToCart(product) {
    const callback = () => {
      this.props.scrollToTopDesk();

      const displayNotification = () => {
        Notification({
          type: "success",
          description: `${this.props.intl.formatMessage({ id: "Cart.Add.Success" })} ${product.name}`,
        });
      };

      displayNotification();

      const mainProduct = this.props.deskProducts.find(
        (p) => p._id === this.props.product_id,
      );

      let areRelatedProductsInCart = true;
      for (const pId of mainProduct.relatedProducts) {
        if (!Object.keys(this.props.deskStore).includes(pId))
          areRelatedProductsInCart = false;
      }

      if (mainProduct.relatedProducts.length > 0 && !areRelatedProductsInCart) {
        this.props.openDeskProductRelatedProductsModal();
      } else if (product.relatedCategories.length > 0) {
        this.props.setOngoingState({ relatedCategories: true, modal: null });
      }
    };

    this.props.onDeskStore(
      product,
      {
        vte_loc: this.state.vte_loc,
        comment: "",
      },
      callback,
    );
  }

  deleteFromCart(product) {
    Notification({
      type: "info",
      description: this.props.intl.formatMessage(
        { id: "Cart.Remove.Success" },
        { product: product.name },
      ),
    });

    const defaultVteLocValue =
      product.vte_loc !== "LocationVente" ? product.vte_loc : "Location";
    this.props.delDeskStore(product._id, () =>
      this.setState({ vte_loc: defaultVteLocValue }),
    );
  }

  backToCategory(categoryId) {
    if (!this.props.initialProductId && !this.props.isReadOnly)
      return this.props.setOngoingState({
        relatedCategories: false,
        product_id: null,
      });

    if (this.props.isRelatedProduct)
      return this.props.openDeskProductRelatedProductsModal(this.props.product);

    if (this.props.deskStore && Object.keys(this.props.deskStore).length > 0)
      return this.props.setOngoingState({
        relatedCategories: true,
        product_id: null,
      });

    this.props.backToCategory(categoryId);
  }

  closeModal() {
    this.setState({ modal: null });
  }

  openConfirmationModal(title, content, successCallback) {
    this.setState({
      modal: (
        <ConfirmationModal
          title={title}
          variant="warning"
          onAccept={successCallback}
          onDecline={() => this.closeModal()}
        >
          {content}
        </ConfirmationModal>
      ),
    });
  }

  getControlPointsNode(controlPoints) {
    if (!controlPoints || controlPoints.length < 1) return null;

    return (
      <ul style={{ listStyleType: "square" }}>
        {controlPoints.map((txt) => (
          <li key={nanoid()}>{txt}</li>
        ))}
      </ul>
    );
  }

  getAvailabilityImages() {
    const { product } = this.props;
    const images = [];
    const img = (src) => (
      <img
        key={nanoid()}
        alt="img"
        src={src}
        style={{ width: "3em", height: "auto" }}
      />
    );

    if (product.refundable)
      images.push(img(`${APIUrl.rootUrl}/img/medical_advice.svg`));
    if (product.vte_loc === "Location" || product.vte_loc === "LocationVente")
      images.push(img(`${APIUrl.rootUrl}/img/picto_location.svg`));
    if (product.vte_loc === "Vente" || product.vte_loc === "LocationVente")
      images.push(img(`${APIUrl.rootUrl}/img/picto_vente.svg`));

    return images;
  }

  render() {
    const {
      product,
      deskCategories,
      deskProducts,
      deskStore,
      intl,
      isReadOnly,
      setOngoingState,
      scrollToTopDesk,
    } = this.props;

    if (!deskProducts || !product) return null;

    const category = deskCategories.find((c) => c._id === product.category_id);

    const imgSrc = `${APIUrl.getDeskProductImg}${product._id}?token=${APIUrl.jwtToken}`;

    const deskRecommendationsNodes = this.getControlPointsNode(
      product.relatedRecommendations,
    );
    const deskQuestionsNodes = this.getControlPointsNode(
      product.relatedQuestions,
    );

    const titleConfirm = <FormattedMessage id="Confirm" />;
    const contentConfirmDeleteProduct = (
      <FormattedMessage id="Product.Remove.Confirmation.2" />
    );
    const delFromCart = () =>
      this.openConfirmationModal(
        titleConfirm,
        contentConfirmDeleteProduct,
        () => this.deleteFromCart(product),
      );

    const isProductInCart = deskStore && deskStore[product._id];
    const action = isProductInCart ? (
      <FormattedMessage id="Cart.Remove" />
    ) : (
      <FormattedMessage id="Add.Cart" />
    );
    const actionIcon = isProductInCart ? "trash" : "cart-plus";
    const onClickAction = () =>
      isProductInCart ? delFromCart() : this.addToCart(product);

    const relatedProducts = deskProducts.filter((p) =>
      product.relatedProducts.includes(p._id),
    );

    const relatedProductsNode = relatedProducts.map((p) => (
      <DeskProductCard
        key={p._id}
        scrollToTopDesk={() => scrollToTopDesk()}
        setOngoingState={(state) => setOngoingState(state)}
        product={p}
        deskStore={deskStore}
      />
    ));

    return (
      <Container fluid className="pb-3 pt-3">
        <TableToolbar>
          <MenuButton
            className="ms-auto"
            onClick={() => this.backToCategory(category._id)}
          >
            <Icon icon="arrow-circle-left" className="me-2" />
            {this.props.deskStore &&
            Object.keys(this.props.deskStore).length > 0 ? (
              <FormattedMessage id="Return" />
            ) : (
              category.name
            )}
          </MenuButton>
        </TableToolbar>

        <Row className="ps-3 pe-3">
          <Col sm={12} className="text-justify p-0">
            <Card className="pt-3 pb-5">
              <Container>
                <Row>
                  <Col>
                    <Alert
                      variant="primary"
                      style={{ textTransform: "uppercase" }}
                    >
                      <h5 className="m-0">
                        <strong>{product.name}</strong>
                      </h5>
                    </Alert>
                    <Row>
                      <Col
                        sm={5}
                        className="justify-content-center align-items-center"
                      >
                        <Row>
                          <Col className="text-center">
                            <ImageWithFallback
                              src={imgSrc}
                              height="auto"
                              maxheight="300"
                              width="300"
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12} className="text-center mb-5">
                            {this.getAvailabilityImages()}
                          </Col>
                        </Row>
                      </Col>
                      <Col className="pe-5 pt-3">
                        <h4 className="text-dark">
                          <FormattedMessage id="Description" />
                        </h4>
                        <Card.Text className="text-justify pb-4">
                          {product.description_text}
                        </Card.Text>
                        <Card.Footer
                          className="text-end ps-0 pe-0"
                          style={{ backgroundColor: "white" }}
                        >
                          <Row>
                            <Col className="text-start">
                              <Row>
                                <Col>
                                  <label
                                    htmlFor="type"
                                    className="text-dark mb-2"
                                  >
                                    <Icon
                                      icon="bag-shopping"
                                      className="me-2"
                                    />
                                    <small>
                                      <FormattedMessage id="Availability" />
                                    </small>
                                  </label>
                                  <FormSelect
                                    value={
                                      isProductInCart
                                        ? deskStore[product._id].vte_loc
                                        : this.state.vte_loc
                                    }
                                    onChange={(e) =>
                                      this.setState({ vte_loc: e.target.value })
                                    }
                                    disabled={
                                      product.vte_loc !== "LocationVente" ||
                                      isProductInCart
                                    }
                                  >
                                    <option value="Location">
                                      {intl.formatMessage({
                                        id: "EqSellMode.1",
                                      })}
                                    </option>
                                    <option value="Vente">
                                      {intl.formatMessage({
                                        id: "EqSellMode.0",
                                      })}
                                    </option>
                                  </FormSelect>
                                </Col>
                              </Row>
                            </Col>
                            {!isReadOnly && (
                              <Col className="mt-auto">
                                <MenuButton
                                  icon={actionIcon}
                                  className="me-2"
                                  variant={
                                    isProductInCart ? "danger" : "primary"
                                  }
                                  onClick={() => onClickAction()}
                                >
                                  {action}
                                </MenuButton>
                              </Col>
                            )}
                          </Row>
                          {product.refundable &&
                            product.renouv_vte !== undefined &&
                            this.state.vte_loc !== "Location" && (
                              <Row className="mt-4">
                                <Col className="text-start text-success">
                                  <Icon icon="circle-info" className="me-2" />
                                  <FormattedMessage
                                    id="Product.Renew.Period"
                                    values={{ years: product.renouv_vte }}
                                  />
                                </Col>
                              </Row>
                            )}
                        </Card.Footer>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                {/* Recommendations */}
                {deskRecommendationsNodes && (
                  <Row className="pt-4 pe-5">
                    <Col>
                      <Card.Title className="text-dark pt-4">
                        <Icon
                          icon="clipboard-list"
                          size="xl"
                          className="me-2"
                        />
                        <FormattedMessage id="Recommendations" />
                      </Card.Title>
                      <div className="mt-2">{deskRecommendationsNodes}</div>
                    </Col>
                  </Row>
                )}

                {/* Questions */}
                {deskQuestionsNodes && (
                  <Row className="pt-4">
                    <Col>
                      <Card.Title className="text-dark pt-4">
                        <Icon
                          icon="clipboard-question"
                          size="xl"
                          className="me-2"
                        />
                        <FormattedMessage id="In.Practice" />
                      </Card.Title>
                      <div className="mt-2">{deskQuestionsNodes}</div>
                    </Col>
                  </Row>
                )}

                {/* Related Products */}
                {isReadOnly &&
                  relatedProducts &&
                  relatedProducts.length !== 0 && (
                    <Row className="pt-4">
                      <Col>
                        <Card.Title className="text-dark pt-4 pb-2">
                          <Icon icon="cart-plus" size="xl" className="me-2" />
                          <FormattedMessage id="Related.Products" />
                          <PopoverHelper>
                            <FormattedMessage id="Hint.Additional.Products" />
                          </PopoverHelper>
                        </Card.Title>
                        <Row md={4}>{relatedProductsNode}</Row>
                      </Col>
                    </Row>
                  )}
              </Container>
            </Card>
          </Col>
        </Row>
        {this.state.modal}
      </Container>
    );
  }
}

export default injectIntl(DeskProductDetail);
