import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import DeskCategoriesModalAdmin from "./DeskCategoriesModalAdmin";
import ConfirmationModal from "../../../sub/modals/ConfirmationModal";
import TableToolbar from "../../../sub/bootstrap/TableToolbar";
import DeskCategoriesRowAdmin from "./DeskCategoriesRowAdmin";
import { Alert, Col, Dropdown, Row } from "react-bootstrap";
import Icon from "../../../sub/Icon";
import FileUtil from "../../../../util/FileUtil";
import APIUrl from "../../../../APIUrl";
import ExcelUtil from "../../../../util/ExcelUtil";
import Notification from "../../../sub/Notification";
import ImportExcelFileModal from "../../../sub/modals/ImportExcelFileModal";
import ImportExcelFileColumnsModal from "../../../sub/modals/ImportExcelFileColumnsModal";
import { getDeskCategories } from "../../../../actions/settings/deskCategories/admin/deskCategories";
import {
  getDeskProducts,
  importDeskProducts,
} from "../../../../actions/settings/deskCategories/admin/deskProducts";
import { injectIntl } from "react-intl";
import Util from "../../../../util/Util";
import ObjectUtil from "../../../../util/ObjectUtil";
import DeskProductMapping from "../../../../util/DeskProductMapping";
import InputLength from "../../../../enums/InputLength";

class DeskCategories extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: null,
      fileName: null,
      fileData: null,
    };
  }

  componentDidMount() {
    this.props.onGetDeskCategories();
    this.props.onGetDeskProducts();
  }

  closeModal() {
    this.setState({ modal: null });
  }

  openDeskCategoriesModal(deskCategory, target) {
    this.setState({
      modal: (
        <DeskCategoriesModalAdmin
          closeModal={() => this.closeModal()}
          deskCategory={deskCategory}
          target={target}
        />
      ),
    });
  }

  openConfModal(title, content, successCallback) {
    this.setState({
      modal: (
        <ConfirmationModal
          title={title}
          mandatoryConfirmation
          onAccept={successCallback}
          onDecline={() => this.closeModal()}
        >
          {content}
        </ConfirmationModal>
      ),
    });
  }

  downloadTemplate() {
    return FileUtil.dowloadFileHack(
      APIUrl.getDeskTemplate,
      "template_desk",
      "xlsx",
    );
  }

  exportDeskProducts() {
    return FileUtil.dowloadFileHack(
      APIUrl.exportDeskProducts,
      "matrice_desk",
      "xlsx",
    );
  }

  // Reset all previous import params in state when aborting import process
  abortAndCloseModal() {
    this.setState({
      fileName: null,
      fileData: null,
    });

    this.closeModal();
  }

  /**
   * Performs checks upon data.
   * We try to avoid sending data that will be rejected by the backend (because of Mongo/Mongoose field types for example)
   *
   * @param {*} columnsReferenceList
   */
  async checkDataIntegrity(fileData, columnsReferenceList, maxErrorToDisplay) {
    let errorsFound = [];
    const itemMapping = DeskProductMapping;
    var columns = {};

    // Store association between required column and matching column in the file (labels can be different if we didnt used automatching or partial automatching)
    for (let key of Object.keys(itemMapping)) {
      columns[key] = itemMapping[key];
    }

    let currentError;

    // Default maxlength for a value (if checked)
    // May be locally changed on some values
    let maxlength;

    // Add an error to the stack
    const addError = (error) => {
      if (Util.typeOf(error) === "Object") {
        errorsFound.push(error);
      }
    };

    // Loop through file rows
    for (let row of fileData) {
      if (errorsFound.length === maxErrorToDisplay) {
        break;
      }

      // Get current row keys
      let currentRowKeys = Object.keys(row);

      /**
       * CHECK SPECIAL FIELDS (where we know that value must be an integer or a float for example)
       */
      for (let key of currentRowKeys) {
        if (errorsFound.length === maxErrorToDisplay) {
          break;
        }

        let itemMappingReferenceKey = ObjectUtil.getKeyByValue(
          columnsReferenceList,
          key,
        );

        if (
          (itemMappingReferenceKey === "category" &&
            Util.emptyString(row[key])) ||
          (itemMappingReferenceKey === "name" && Util.emptyString(row[key])) ||
          (itemMappingReferenceKey === "ref" && Util.emptyString(row[key])) ||
          (itemMappingReferenceKey === "refundable" &&
            Util.emptyString(row[key])) ||
          (itemMappingReferenceKey === "vte_loc" && Util.emptyString(row[key]))
        ) {
          currentError = {
            numRow: row.__rowNum__ + 1,
            field: key,
            targetField: itemMapping[itemMappingReferenceKey],
            value: row[key],
            hint: <FormattedMessage id="Field.Cant.Be.Empty" />,
          };

          addError(currentError);
          break;
        }

        switch (true) {
          case itemMappingReferenceKey === "category":
          case itemMappingReferenceKey === "name":
            maxlength = InputLength.NAME;
            // Check maxlength
            if (!Util.safeGenericString(row[key], 1, maxlength)) {
              currentError = {
                numRow: row.__rowNum__ + 1,
                field: key,
                targetField: itemMapping[itemMappingReferenceKey],
                value: row[key],
                hint: (
                  <FormattedMessage
                    id="Value.Is.Not.Valid.Data"
                    values={{ value: row[key], maxlength: maxlength }}
                  />
                ),
              };
              addError(currentError);
            }
            break;

          case itemMappingReferenceKey === "ref":
            if (this.props.deskProducts.find((p) => p.ref === row[key])) {
              currentError = {
                numRow: row.__rowNum__ + 1,
                field: key,
                targetField: itemMapping[itemMappingReferenceKey],
                value: row[key],
                hint: (
                  <span>
                    <FormattedMessage id="Product.Ref.Already.Exists" />
                  </span>
                ),
              };
              addError(currentError);
            }
            break;

          case itemMappingReferenceKey === "relatedProducts_1":
          case itemMappingReferenceKey === "relatedProducts_2":
          case itemMappingReferenceKey === "relatedProducts_3":
          case itemMappingReferenceKey === "relatedProducts_4":
          case itemMappingReferenceKey === "relatedProducts_5":
          case itemMappingReferenceKey === "relatedProducts_6":
          case itemMappingReferenceKey === "relatedProducts_7":
          case itemMappingReferenceKey === "relatedProducts_8":
          case itemMappingReferenceKey === "relatedProducts_9":
          case itemMappingReferenceKey === "relatedProducts_10":
          case itemMappingReferenceKey === "relatedProducts_11":
          case itemMappingReferenceKey === "relatedProducts_12":
          case itemMappingReferenceKey === "relatedProducts_13":
          case itemMappingReferenceKey === "relatedProducts_14":
          case itemMappingReferenceKey === "relatedProducts_15":
          case itemMappingReferenceKey === "relatedCategories_1":
          case itemMappingReferenceKey === "relatedCategories_2":
          case itemMappingReferenceKey === "relatedCategories_3":
          case itemMappingReferenceKey === "relatedCategories_4":
          case itemMappingReferenceKey === "relatedCategories_5":
          case itemMappingReferenceKey === "relatedCategories_6":
          case itemMappingReferenceKey === "relatedCategories_7":
          case itemMappingReferenceKey === "relatedCategories_8":
          case itemMappingReferenceKey === "relatedCategories_9":
          case itemMappingReferenceKey === "relatedCategories_10":
          case itemMappingReferenceKey === "relatedCategories_11":
          case itemMappingReferenceKey === "relatedCategories_12":
          case itemMappingReferenceKey === "relatedCategories_13":
          case itemMappingReferenceKey === "relatedCategories_14":
          case itemMappingReferenceKey === "relatedCategories_15":
            maxlength = InputLength.NAME;
            // Check maxlength
            if (!Util.emptyString(row[key]) && row[key].length > maxlength) {
              currentError = {
                numRow: row.__rowNum__ + 1,
                field: key,
                targetField: itemMapping[itemMappingReferenceKey],
                value: row[key],
                hint: (
                  <FormattedMessage
                    id="Value.Is.Not.Valid.Data"
                    values={{ value: row[key], maxlength: maxlength }}
                  />
                ),
              };
              addError(currentError);
            }
            break;

          case itemMappingReferenceKey === "relatedQuestions_1":
          case itemMappingReferenceKey === "relatedQuestions_2":
          case itemMappingReferenceKey === "relatedQuestions_3":
          case itemMappingReferenceKey === "relatedQuestions_4":
          case itemMappingReferenceKey === "relatedQuestions_5":
          case itemMappingReferenceKey === "relatedQuestions_6":
          case itemMappingReferenceKey === "relatedQuestions_7":
          case itemMappingReferenceKey === "relatedQuestions_8":
          case itemMappingReferenceKey === "relatedQuestions_9":
          case itemMappingReferenceKey === "relatedQuestions_10":
          case itemMappingReferenceKey === "relatedRecommendations_1":
          case itemMappingReferenceKey === "relatedRecommendations_2":
          case itemMappingReferenceKey === "relatedRecommendations_3":
          case itemMappingReferenceKey === "relatedRecommendations_4":
          case itemMappingReferenceKey === "relatedRecommendations_5":
          case itemMappingReferenceKey === "relatedRecommendations_6":
          case itemMappingReferenceKey === "relatedRecommendations_7":
          case itemMappingReferenceKey === "relatedRecommendations_8":
          case itemMappingReferenceKey === "relatedRecommendations_9":
          case itemMappingReferenceKey === "relatedRecommendations_10":
          case itemMappingReferenceKey === "prescription_type_vte_text":
          case itemMappingReferenceKey === "prescription_type_loc_text":
          case itemMappingReferenceKey === "renouv_vte":
          case itemMappingReferenceKey === "is_renewal_obligatory":
            maxlength = InputLength.TEXT;
            // Check maxlength
            if (!Util.emptyString(row[key]) && row[key].length > maxlength) {
              currentError = {
                numRow: row.__rowNum__ + 1,
                field: key,
                targetField: itemMapping[itemMappingReferenceKey],
                value: row[key],
                hint: (
                  <FormattedMessage
                    id="Value.Is.Not.Valid.Data"
                    values={{ value: row[key], maxlength: maxlength }}
                  />
                ),
              };
              addError(currentError);
            }
            break;

          case itemMappingReferenceKey === "description_text":
            maxlength = InputLength.TEXT_LONG;
            // Check maxlength
            if (!Util.emptyString(row[key]) && row[key].length > maxlength) {
              currentError = {
                numRow: row.__rowNum__ + 1,
                field: key,
                targetField: itemMapping[itemMappingReferenceKey],
                value: row[key],
                hint: (
                  <FormattedMessage
                    id="Value.Is.Not.Valid.Data"
                    values={{ value: row[key], maxlength: maxlength }}
                  />
                ),
              };
              addError(currentError);
            }
            break;

          default:
            // Convert field to string to evaluate it
            let fieldValue = row[key].toString();
            // Double check string fields that may contain only spaces (so they are not considered as empty)
            // We trim the value in order to catch'em as well eventually
            if (fieldValue.trim() === "") {
              currentError = {
                numRow: row.__rowNum__ + 1,
                field: key,
                targetField: itemMapping[itemMappingReferenceKey],
                value: "",
                hint: <FormattedMessage id="Field.Cant.Be.Empty" />,
              };

              addError(currentError);
            }
            break;
        }
      }
    }

    return errorsFound;
  }

  // Step 1 : Import excel file
  initStep1(e) {
    e.preventDefault();
    e.stopPropagation();

    this.setState({
      mode: "create",
      modal: (
        <ImportExcelFileModal
          title={<FormattedMessage id="Import.Products.Type" />}
          templateFileUrl={APIUrl.getDeskTemplate}
          templateFileName={"template_desk"}
          closeModal={() => this.abortAndCloseModal()}
          onComplete={(fileData) => this.initStep2(fileData)}
        />
      ),
    });
  }

  // Step 2 : File column mapping and data validation
  initStep2(file) {
    // Parse the excel file and then move on to next modal
    ExcelUtil.parse(file, (fileData) => {
      // Save the passed params
      this.setState({
        fileName: file.name,
        fileData: fileData,
      });

      // Close current modal
      this.closeModal();

      // Open next step modal
      this.setState({
        modal: (
          <ImportExcelFileColumnsModal
            closeModal={() => this.abortAndCloseModal()}
            fileData={fileData}
            onComplete={(columns) => this.initStep3(columns)}
            title={<FormattedMessage id="Import.Products.Type" />}
            itemMapping={DeskProductMapping}
            checkDataIntegrity={(columnsReferenceList, maxErrorToDisplay) =>
              this.checkDataIntegrity(
                fileData,
                columnsReferenceList,
                maxErrorToDisplay,
              )
            }
          />
        ),
      });
    });
  }

  // Step 3 : Send data to the backend
  initStep3(columns) {
    this.setState({
      columns: columns,
    });

    let successCallback;

    successCallback = () => {
      this.closeModal();

      Notification({
        type: "success",
        description: this.props.intl.formatMessage({
          id: "Import.Successful",
        }),
      });
    };

    // Send the products to the BE

    // First, fix data with correct columns
    const products = this.fixDeskProductsCols(this.state.fileData, columns);

    this.props.onImportDeskProducts(
      {
        fileName: this.state.fileName,
        products: products,
      },
      successCallback,
    );
  }

  // Perform checks on columns <-> data associations for products
  fixDeskProductsCols(products, columns) {
    let newProducts = [];
    for (let e of products) {
      var newProduct = {};
      for (let col of Object.keys(columns)) newProduct[col] = e[columns[col]];
      newProducts.push(newProduct);
    }

    return newProducts;
  }

  render() {
    const { deskCategories } = this.props;

    const deskCategoryRowNode =
      deskCategories &&
      deskCategories.map((deskCategory) => {
        return (
          <DeskCategoriesRowAdmin
            key={deskCategory._id}
            deskCategory={deskCategory}
            openDeskCategoriesModal={(target) =>
              this.openDeskCategoriesModal(deskCategory, target)
            }
            openConfModal={(title, content, successCallback) =>
              this.openConfModal(title, content, successCallback)
            }
          />
        );
      });

    const menuAction = (
      <Dropdown>
        <Dropdown.Toggle id="dropdownMenuLink">
          <FormattedMessage id="Actions" />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {!this.props.clientId && (
            <>
              <Dropdown.Item onClick={(e) => this.openDeskCategoriesModal()}>
                <Icon icon="circle-plus" className={"me-2"} />
                <FormattedMessage id="Add.Categories" />
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item onClick={(e) => this.initStep1(e)}>
                <Icon icon="upload" className={"me-2"} />
                <FormattedMessage id="Import.Products.Type" />
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item onClick={(e) => this.exportDeskProducts()}>
                <Icon icon="download" className={"me-2"} />
                <FormattedMessage id="Export.Products.Type" />
              </Dropdown.Item>
            </>
          )}
        </Dropdown.Menu>
      </Dropdown>
    );

    return (
      <>
        <TableToolbar>
          <Row>
            <Col className="text-end">{menuAction}</Col>
          </Row>
        </TableToolbar>

        {(!deskCategories || deskCategories.length <= 0) && (
          <Alert variant="secondary" className="mt-3">
            <FormattedMessage id="Empty.Categories" />
          </Alert>
        )}

        {deskCategories && deskCategories.length !== 0 && (
          <table className="table table-striped tablee4mad table-bordered">
            <thead>
              <tr>
                <th className="col">
                  <FormattedMessage id="Name" />
                </th>
                <th className="text-center col-auto">
                  <FormattedMessage id="Types.Products" />
                </th>
                <th className="text-center col-auto">
                  <FormattedMessage id="Actions" />
                </th>
              </tr>
            </thead>
            <tbody>{deskCategoryRowNode}</tbody>
          </table>
        )}

        {this.state.modal}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    deskCategories: state.deskCategories,
    deskProducts: state.deskProducts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onImportDeskProducts: (data, successCallback, failureCallback) =>
      dispatch(importDeskProducts(data, successCallback, failureCallback)),
    onGetDeskCategories: () => dispatch(getDeskCategories()),
    onGetDeskProducts: () => dispatch(getDeskProducts()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(DeskCategories));
