import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import EstimateStatus from "../../enums/EstimateStatus";
import Roles from "../../enums/Roles";
import DateUtil from "../../util/DateUtil";
import { updateRequest, deleteRequest } from "../../actions/requests/requests";
import ActionMenu from "../sub/ActionMenu";
import { Alert, FormSelect } from "react-bootstrap";

class EstimateRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      status: this.props.request.status,
    };
  }

  deleteRequestAdmin(request) {
    const title = <FormattedMessage id={"Confirm"} />;
    const content = <FormattedMessage id={"Request.Remove.Confirmation"} />;
    const onAccept = () =>
      this.props.onDeleteRequest({ requestId: request._id });

    this.props.openConfModal(title, content, onAccept);
  }

  updateRequestStatus(status, request) {
    const data = {
      updatedValue: status,
      updatedField: "status",
      requestId: request._id,
    };

    this.setState({ status: status }, () => this.props.onUpdateRequest(data));
  }

  getStatusNode(items) {
    return Object.values(items).map((item) => {
      return (
        <option key={item} value={item}>
          {this.props.intl.formatMessage({ id: "Estimate.Status." + item })}
        </option>
      );
    });
  }

  getStatusClassName(status) {
    switch (parseInt(status)) {
      case EstimateStatus.ACCEPTED:
        return "success";
      case EstimateStatus.PARTIALLY_ACCEPTED:
        return "warning";
      case EstimateStatus.PENDING:
        return "info";
      case EstimateStatus.DECLINED:
        return "danger";
      default:
        return "light";
    }
  }

  render() {
    const { user, request, limit } = this.props;

    const isClient = user.role === Roles.CLIENT;

    const statusNode = this.getStatusNode(EstimateStatus, "estimate");
    const statusClassName = this.getStatusClassName(request.status);
    const status = (
      <Alert className="p-1 px-2 mb-0" variant={statusClassName}>
        <FormattedMessage id={"Estimate.Status." + request.status} />
      </Alert>
    );

    const menuItems = [];

    isClient &&
      menuItems.push({
        icon: "eye",
        action: () => this.props.openEstimateModal(request),
        text: <FormattedMessage id="Consult.Request" />,
      });

    !isClient &&
      menuItems.push({
        icon: "pen-to-square",
        action: () => this.props.openEstimateModal(request),
        text: <FormattedMessage id="Modify" />,
      });

    !isClient &&
      menuItems.push({
        icon: "trash",
        action: () => this.deleteRequestAdmin(request),
        text: <FormattedMessage id="Delete" />,
      });

    const menuAction = (
      <ActionMenu
        icon={
          request && request.files && request.files.length > 0 && "download"
        }
        items={menuItems}
      />
    );

    return (
      <tr>
        {!isClient && (
          <td className="align-middle">
            <span>
              {request.client?.name || <FormattedMessage id="User.Deleted" />}
            </span>
          </td>
        )}
        <td className="align-middle">
          <div className="truncate">{request.precisions}</div>
        </td>
        <td className="align-middle">{DateUtil.toDate(request.createdAt)}</td>
        {isClient ? (
          <td className="align-middle">{status}</td>
        ) : (
          <td className="align-middle">
            <FormSelect
              className={"alert alert-" + statusClassName + " p-1 px-2 mb-0"}
              onChange={(e) =>
                this.updateRequestStatus(e.target.value, request)
              }
              value={this.state.status}
            >
              {statusNode}
            </FormSelect>
          </td>
        )}

        {!limit && <td className="align-middle text-end">{menuAction}</td>}
      </tr>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateRequest: (data) => dispatch(updateRequest(data)),
    onDeleteRequest: (requestId) => dispatch(deleteRequest(requestId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(EstimateRow));
