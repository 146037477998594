import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import Util from "../../util/Util";
import { addPatient, updPatientAdmin } from "../../actions/patients/patients";
import DateUtil from "../../util/DateUtil";
import Roles from "../../enums/Roles";
import CustomLabel from "../sub/CustomLabel";
import ComboBox from "../sub/ComboBox";
import Geolocation from "../api/gouv/Geolocation";
import TextareaCounter from "../sub/TextareaCounter";
import {
  checkIfEmailIsValid,
  checkIfUsernameExists,
  sendWelcomeEmail,
} from "../../actions/user/user";
import { addClient, updClient } from "../../actions/clients/clients";
import { Form, Row, Col, Alert, FormSelect } from "react-bootstrap";
import Icon from "../sub/Icon";
import MercurialStatus from "../../enums/MercurialStatus";
import PatientAccountModal from "./PatientAccountModal";
import InputLength from "../../enums/InputLength";
import InputTextElement from "../sub/InputTextElement";
import Fieldset from "../sub/Fieldset";
import Legend from "../sub/Legend";
import MenuButton from "../sub/bootstrap/MenuButton";
import ConfirmationModal from "../sub/modals/ConfirmationModal";

class PatientGeneralInfo extends React.Component {
  constructor(props) {
    super(props);

    const { patient } = this.props;

    this.isUpdate = !!patient;

    this.patientAccountData = this.isUpdate
      ? this.props.clients.find((c) => c._id === patient.user_id[0])
      : null;

    this.state = this.initState();
  }

  initState() {
    const { patient } = this.props;

    const getValue = (field) => (patient ? patient[field] : "");

    return {
      first_name: getValue("first_name"),
      name: getValue("name"),
      birth_date: getValue("birth_date") || "",
      address: getValue("address"),
      addressAdditionnal: getValue("addressAdditionnal") || "",
      postal_code: getValue("postal_code") || "",
      city: getValue("city"),
      cityDisabled: getValue("postal_code") ? false : true,
      phone: getValue("phone"),
      mobile: getValue("mobile"),
      email: getValue("email"),
      codeCRM: getValue("codeCRM"),
      comments: getValue("comments"),
      username:
        (this.patientAccountData && this.patientAccountData.username) || "",
      mercurial_Id:
        (this.patientAccountData && this.patientAccountData.mercurial_Id) || "",
      notifyByEmail: false,
      phoneError: null,
      mobileError: null,
      emailError: null,
      postal_codeError: null,
      usernameError: null,
      listCities: getValue("city")
        ? [{ _id: getValue("city"), name: getValue("city") }]
        : [],
      loading: false,
      hasLogin:
        this.patientAccountData && this.patientAccountData.username
          ? true
          : false,
      modal: null,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.patient !== this.props.patient) {
      this.setState(this.initState());
    }
  }

  getCityList(postal_code) {
    if (
      !postal_code ||
      postal_code === this.state.postal_code ||
      this.state.postal_code === ""
    ) {
      return;
    } else {
      Geolocation.getCitiesByPostalCode(
        postal_code,
        this.state.city,
        (liste) => {
          this.setState({ listCities: liste, cityDisabled: false });
        },
      );
    }
  }

  onSubmit() {
    this.setState({ loading: true });

    const patient = {
      first_name: this.state.first_name,
      name: this.state.name,
      birth_date: this.state.birth_date,
      address: this.state.address,
      addressAdditionnal: this.state.addressAdditionnal,
      postal_code: this.state.postal_code,
      city: this.state.city,
      phone: this.state.phone,
      mobile: this.state.mobile,
      email: this.state.email,
      codeCRM: this.state.codeCRM,
      comments: this.state.comments,
      active: 1,
    };

    const client = {
      first_name: this.state.first_name,
      name: this.state.name,
      address: this.state.address,
      addressAdditionnal: this.state.addressAdditionnal,
      postal_code: this.state.postal_code,
      city: this.state.city,
      phone: this.state.phone,
      mobile: this.state.mobile,
      email: this.state.email,
      username: this.state.username,
      client_type: "patient",
      account_type: "0",
      notifyByEmail: this.state.notifyByEmail,
    };

    if (this.state.mercurial_Id !== "")
      client.mercurial_Id = this.state.mercurial_Id;

    const close = (patient) =>
      this.setState({ loading: false }, () => this.props.openModal(patient));
    const successCallback = (client) => {
      patient.user_id = client._id;

      const updateClient = (patient) => {
        const updatedClient = {
          _id: client._id,
          updatedField: "patient_id",
          updatedValue: patient._id,
        };

        this.props.onUpdClient(updatedClient, () => close(patient));
      };

      this.props.onAddPatient(patient, updateClient);
    };

    // Send to BE
    if (this.state.hasLogin && !Util.emptyString(this.state.username))
      return this.props.onAddClient(client, successCallback);
    return this.props.onAddPatient(patient, close);
  }

  disabled() {
    return (
      Util.emptyString(this.state.name) ||
      Util.emptyString(this.state.first_name) ||
      Util.emptyString(this.state.address) ||
      Util.emptyString(this.state.postal_code) ||
      Util.emptyString(this.state.city) ||
      (this.state.hasLogin && Util.emptyString(this.state.username)) ||
      (this.state.hasLogin && Util.emptyString(this.state.email)) ||
      this.state.phoneError ||
      this.state.mobileError ||
      this.state.emailError ||
      this.state.usernameError ||
      this.state.postal_codeError ||
      this.state.loading
    );
  }

  onChange(field, value) {
    if (field === "postal_code" && value.length > 5) {
      return;
    }

    this.setState({ [field]: value });

    if (field === "phone") {
      if (!Util.emptyString(value) && !Util.isPhone(value))
        this.setState({
          phoneError: <FormattedMessage id="Invalid.Phone.Number.Error" />,
        });
      else this.setState({ phoneError: null });
    } else if (field === "postal_code") {
      if (!Util.emptyString(value) && !Util.isPostal(value)) {
        this.setState({
          postal_codeError: (
            <FormattedMessage id="Invalid.Postal.Number.Error" />
          ),
          city: "",
        });
      } else {
        this.setState(
          {
            postal_codeError: null,
            listCities: this.state.city
              ? [{ _id: this.state.city, name: this.state.city }]
              : [],
          },
          this.getCityList(value),
        );
      }
    } else if (field === "mobile") {
      if (!Util.emptyString(value) && !Util.isPhone(value))
        this.setState({
          mobileError: <FormattedMessage id="Invalid.Phone.Number.Error" />,
        });
      else this.setState({ mobileError: null });
    } else if (field === "username") {
      this.setState({ usernameError: null });

      this.setState({ [field]: value });

      if (Util.emptyString(value)) {
        this.setState({
          usernameError: <FormattedMessage id="Empty.Username" />,
        });
        return;
      }

      var checkUsername = Util.isValidUserName(value, 3, 20);

      if (checkUsername instanceof Object === true) {
        this.setState({
          usernameError: (
            <FormattedMessage
              id="Invalid.Username"
              values={{
                minLength: checkUsername.minLength,
                maxLength: checkUsername.maxLength,
              }}
            />
          ),
        });
        return;
      } else {
        this.setState({ usernameError: null });
      }

      if (
        !this.isUpdate ||
        (this.isUpdate && this.props.patient.username !== value)
      ) {
        this.props.onCheckIfUsernameExists(
          value,
          () => {
            this.setState({
              usernameError: (
                <FormattedMessage
                  id="Username.Already.Exists"
                  values={{ username: value }}
                />
              ),
            });
          },
          () => {
            this.setState({ usernameError: null });
          },
        );
      }
    } else if (field === "email") {
      if (Util.emptyString(value)) this.setState({ emailError: null });

      if (Util.emptyString(value) && !Util.emptyString(this.state.username))
        this.setState({
          emailError: <FormattedMessage id="Error.Account.Email.Required" />,
        });

      if (!Util.emptyString(value) && !Util.isEmail(value)) {
        this.setState({
          emailError: <FormattedMessage id="Invalid.Email.Error" />,
        });
      } else if (!Util.emptyString(value) && Util.isEmail(value)) {
        this.props.onCheckIfEmailIsValid(
          value,
          (response) => {
            this.setState({ emailError: null });
          },
          (response) => {
            this.setState({
              emailError: (
                <span>
                  <FormattedMessage id="Invalid.Email.Error" /> (
                  {response.data.reason})
                </span>
              ),
            });
            return;
          },
        );
      }
    } else if (field === "city") {
      let list = this.state.listCities;
      let response = false;
      for (let listobject of list) {
        if (listobject._id === value) {
          response = true;
        }
      }
      if (!response) {
        list.push({ _id: value, name: value });
        this.setState({
          listCities: list,
        });
        this.setState({ [field]: value });
      }
    }
  }

  onBlur(field, value) {
    if (!this.isUpdate) return;

    if (field === "phone" && this.state.phoneError) return;
    else if (field === "mobile" && this.state.mobileError) return;
    else if (field === "postal_code" && this.state.postal_codeError) return;
    else if (field === "email" && this.state.emailError) return;
    // Required fields
    else if (field === "name" && Util.emptyString(this.state.name)) return;
    else if (field === "first_name" && Util.emptyString(this.state.first_name))
      return;
    else if (field === "city" && Util.emptyString(this.state.city)) return;
    else if (field === "address" && Util.emptyString(this.state.address))
      return;
    else if (
      field === "postal_code" &&
      Util.emptyString(this.state.postal_code)
    )
      return;
    else if (field === "username" && this.state.usernameError) return;
    else if (
      field === "mercurial_Id" &&
      (Util.emptyString(this.state.username) || this.state.usernameError)
    )
      return;

    const patient = {
      _id: this.props.patient._id,
      [field]: value,
    };

    const updatedClient = {
      _id: this.props.patient.user_id[0],
      updatedField: field,
      updatedValue: value,
    };

    const newClient = {
      first_name: this.state.first_name,
      name: this.state.name,
      address: this.state.address,
      addressAdditionnal: this.state.addressAdditionnal,
      postal_code: this.state.postal_code,
      city: this.state.city,
      phone: this.state.phone,
      mobile: this.state.mobile,
      email: this.state.email,
      username: this.state.username,
      client_type: "patient",
      account_type: "0",
      notifyByEmail: this.state.notifyByEmail,
      patient_id: this.props.patient._id,
    };

    const successCallback = (client) => {
      const updatedPatient = {
        _id: this.props.patient._id,
        user_id: client._id,
      };

      this.props.onUpdPatientAdmin(updatedPatient);
    };

    if (field === "username" && this.props.patient.user_id.length === 0)
      return this.props.onAddClient(newClient, successCallback);
    if (
      (field === "username" || field === "mercurial_Id") &&
      this.props.patient.user_id.length > 0
    )
      return this.props.onUpdClient(updatedClient);

    // Send to BE
    if (this.state.hasLogin && !Util.emptyString(this.state.username))
      return this.props.onUpdPatientAdmin(patient, () =>
        this.props.onUpdClient(updatedClient),
      );
    return this.props.onUpdPatientAdmin(patient);
  }

  closeModal() {
    this.setState({ modal: null });
  }

  openPatientUserModal() {
    this.setState({
      modal: (
        <PatientAccountModal
          patient={this.props.patient}
          closeModal={(patient) => this.closeModal(patient)}
        />
      ),
    });
  }

  openEmailSent() {
    this.setState({
      modal: (
        <ConfirmationModal
          variant={"success"}
          title={<FormattedMessage id="Mail.Account.Created" />}
          cancelButtonDisabled
          confirmButtonLabel={this.props.intl.formatMessage({
            id: "Close",
          })}
          onAccept={() => {
            this.closeModal();
          }}
        >
          <FormattedMessage id="Mail.Sent.Success" />
        </ConfirmationModal>
      ),
    });
  }

  render() {
    const { user } = this.props;
    const { role } = user;

    if (role === undefined) return null;

    const now = new Date();

    const canManage =
      !user.supervisor_id ||
      (user.supervisor_id && user.has_write_permission);

    return (
      <React.Fragment>
        {!this.isUpdate && (
          <Alert variant="info">
            <FormattedMessage id="Patient.Account.Creation.Info" />
          </Alert>
        )}
        <Fieldset>
          <Legend>
            <FormattedMessage id="Identity" />
          </Legend>
          <Form.Group as={Row} className="mb-3 align-items-center">
            <CustomLabel
              label={this.props.intl.formatMessage({ id: "Name" })}
              htmlFor="name"
              displayAsCol
              required
            />
            <Col md={7}>
              <InputTextElement
                disabled={!canManage || role === Roles.CLIENT}
                type="text"
                id="name"
                autoComplete="off"
                maxLength={InputLength.NAME}
                value={this.state.name}
                onChange={(e) =>
                  canManage &&
                  this.onChange("name", e.target.value.toUpperCase())
                }
                onBlur={(e) => canManage && this.onBlur("name", e.target.value)}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 align-items-center">
            <CustomLabel
              label={this.props.intl.formatMessage({ id: "First.Name" })}
              htmlFor="first_name"
              displayAsCol
              required
            />
            <Col md={7}>
              <InputTextElement
                disabled={!canManage || role === Roles.CLIENT}
                className="text-capitalize"
                type="text"
                id="first_name"
                autoComplete="off"
                maxLength={InputLength.NAME}
                value={this.state.first_name}
                onChange={(e) =>
                  canManage &&
                  this.onChange("first_name", e.target.value.toLowerCase())
                }
                onBlur={(e) =>
                  canManage && this.onBlur("first_name", e.target.value)
                }
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 align-items-center">
            <CustomLabel
              label={this.props.intl.formatMessage({ id: "Birth.Date" })}
              htmlFor="birth_date"
              displayAsCol
            />
            <Col md={7}>
              <InputTextElement
                disabled={!canManage || role === Roles.CLIENT}
                type="date"
                name="birth_date"
                value={DateUtil.toyyyyMMddPatient(this.state.birth_date)}
                onChange={(e) => this.onChange("birth_date", e.target.value)}
                onBlur={(e) => this.onBlur("birth_date", e.target.value)}
              />
            </Col>
          </Form.Group>
        </Fieldset>

        <Fieldset>
          <Legend>
            <FormattedMessage id="Contact.Information" />
          </Legend>
          {this.state.hasLogin ? (
            <Form.Group as={Row} className="mb-3 align-items-center">
              <CustomLabel
                label={this.props.intl.formatMessage({ id: "Email" })}
                htmlFor="email"
                displayAsCol
                required
              />
              <Col md={7}>
                <InputTextElement
                  disabled={!canManage || user.role === Roles.CLIENT}
                  type="text"
                  id="email"
                  autoComplete="off"
                  maxLength={InputLength.EMAIL}
                  value={this.state.email}
                  onChange={(e) =>
                    canManage && this.onChange("email", e.target.value)
                  }
                  onBlur={(e) =>
                    canManage &&
                    this.isUpdate &&
                    this.onBlur("email", e.target.value)
                  }
                />
                <div className="text-danger">
                  <small>{this.state.emailError}</small>
                </div>
              </Col>
            </Form.Group>
          ) : (
            <Form.Group as={Row} className="mb-3 align-items-center">
              <CustomLabel
                label={this.props.intl.formatMessage({ id: "Email" })}
                htmlFor="email"
                displayAsCol
              />
              <Col md={7}>
                <InputTextElement
                  disabled={!canManage || role === Roles.CLIENT}
                  type="text"
                  id="email"
                  autoComplete="off"
                  maxLength={InputLength.EMAIL}
                  value={this.state.email}
                  onChange={(e) =>
                    canManage && this.onChange("email", e.target.value)
                  }
                  onBlur={(e) =>
                    canManage && this.onBlur("email", e.target.value)
                  }
                />
                <div className="text-danger">
                  <small>{this.state.emailError}</small>
                </div>
              </Col>
            </Form.Group>
          )}

          <Form.Group as={Row} className="mb-3 align-items-center">
            <CustomLabel
              label={this.props.intl.formatMessage({ id: "Mailing.Address" })}
              htmlFor="address"
              displayAsCol
              required
            />
            <Col md={7}>
              <InputTextElement
                disabled={!canManage || role === Roles.CLIENT}
                type="text"
                id="address"
                autoComplete="off"
                maxLength={InputLength.ADDRESS}
                value={this.state.address}
                onChange={(e) =>
                  canManage && this.onChange("address", e.target.value)
                }
                onBlur={(e) =>
                  canManage && this.onBlur("address", e.target.value)
                }
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 align-items-center">
            <CustomLabel
              label={this.props.intl.formatMessage({ id: "Address.Compl" })}
              htmlFor="address"
              displayAsCol
            />
            <Col md={7}>
              <InputTextElement
                disabled={!canManage || role === Roles.CLIENT}
                type="text"
                id="address"
                autoComplete="off"
                maxLength={InputLength.ADDRESS}
                value={this.state.addressAdditionnal}
                onChange={(e) =>
                  canManage &&
                  this.onChange("addressAdditionnal", e.target.value)
                }
                onBlur={(e) =>
                  canManage && this.onBlur("addressAdditionnal", e.target.value)
                }
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 align-items-center">
            <CustomLabel
              label={this.props.intl.formatMessage({ id: "Postal.Code" })}
              htmlFor="postal_code"
              displayAsCol
              required
            />
            <Col md={7}>
              <InputTextElement
                disabled={!canManage || role === Roles.CLIENT}
                type="number"
                id="postal_code"
                autoComplete="off"
                maxLength={InputLength.POSTAL_CODE}
                value={this.state.postal_code}
                onChange={(e) =>
                  canManage && this.onChange("postal_code", e.target.value)
                }
                onBlur={(e) =>
                  canManage && this.onBlur("postal_code", e.target.value)
                }
              />
              <div className="text-danger">
                <small>{this.state.postal_codeError}</small>
              </div>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 align-items-center">
            <CustomLabel
              label={this.props.intl.formatMessage({ id: "City" })}
              htmlFor="city"
              displayAsCol
              required
            />
            <Col md={7}>
              {role !== Roles.CLIENT && (
                <ComboBox
                  isValidNewOption={() => false}
                  onCreateOption={(option, successCallback) => {
                    successCallback({ _id: option.name, name: option.name });
                  }}
                  onChange={(e) => {
                    canManage && this.onChange("city", e);
                  }}
                  onBlur={(e) => {
                    canManage && this.onBlur("city", e);
                  }}
                  defaultOption={this.state.city}
                  options={this.state.listCities}
                  disabled={
                    !canManage ||
                    role === Roles.CLIENT ||
                    this.state.cityDisabled
                  }
                  placeholder={
                    this.state.listCities.length
                      ? this.props.intl.formatMessage({ id: "Select" })
                      : this.props.intl.formatMessage({
                          id: "Type.Postal.Code.First",
                        })
                  }
                />
              )}
              {role === Roles.CLIENT && (
                <InputTextElement
                  disabled={!canManage || role === Roles.CLIENT}
                  type="text"
                  id="city"
                  autoComplete="off"
                  maxLength={InputLength.NAME}
                  value={this.state.city}
                  onChange={(e) =>
                    canManage && this.onChange("city", e.target.value)
                  }
                  onBlur={(e) =>
                    canManage && this.onBlur("city", e.target.value)
                  }
                />
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 align-items-center">
            <CustomLabel
              label={this.props.intl.formatMessage({ id: "Phone" })}
              htmlFor="phone"
              displayAsCol
            />
            <Col md={7}>
              <InputTextElement
                disabled={!canManage || role === Roles.CLIENT}
                type="number"
                id="phone"
                autoComplete="off"
                maxLength={InputLength.PHONE}
                value={this.state.phone}
                onChange={(e) =>
                  canManage && this.onChange("phone", e.target.value)
                }
                onBlur={(e) =>
                  canManage && this.onBlur("phone", e.target.value)
                }
              />
              <div className="text-danger">
                <small>{this.state.phoneError}</small>
              </div>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 align-items-center">
            <CustomLabel
              label={this.props.intl.formatMessage({ id: "Mobile" })}
              htmlFor="mobile"
              displayAsCol
            />
            <Col md={7}>
              <InputTextElement
                disabled={!canManage || role === Roles.CLIENT}
                type="number"
                id="mobile"
                autoComplete="off"
                maxLength={InputLength.PHONE}
                value={this.state.mobile}
                onChange={(e) =>
                  canManage && this.onChange("mobile", e.target.value)
                }
                onBlur={(e) =>
                  canManage && this.onBlur("mobile", e.target.value)
                }
              />
              <div className="text-danger">
                <small>{this.state.mobileError}</small>
              </div>
            </Col>
          </Form.Group>
        </Fieldset>
        {role !== Roles.CLIENT && (
          <Fieldset>
            <Legend>
              <FormattedMessage id="Various" />
            </Legend>
            <Form.Group as={Row} className="mb-3 align-items-center">
              <CustomLabel
                label={this.props.intl.formatMessage({ id: "Code.CRM" })}
                htmlFor="codeCRM"
                displayAsCol
                help={<FormattedMessage id="Hint.Code.CRM" />}
              />
              <Col md={7}>
                <InputTextElement
                  disabled={!canManage || role === Roles.CLIENT}
                  type="text"
                  id="codeCRM"
                  autoComplete="off"
                  maxLength={InputLength.NAME}
                  value={this.state.codeCRM}
                  onChange={(e) =>
                    canManage && this.onChange("codeCRM", e.target.value)
                  }
                  onBlur={(e) =>
                    canManage && this.onBlur("codeCRM", e.target.value)
                  }
                />
              </Col>
            </Form.Group>
            {role !== Roles.CLIENT && (
              <Form.Group as={Row} className="mb-3 align-items-center">
                <CustomLabel
                  label={this.props.intl.formatMessage({
                    id: "Internal.Comment",
                  })}
                  htmlFor="comments"
                  displayAsCol
                  help={this.props.intl.formatMessage({
                    id: "Hint.Internal.Comment",
                  })}
                />
                <Col md={7}>
                  <TextareaCounter
                    id="comments"
                    countLimit={InputLength.TEXT}
                    value={this.state.comments}
                    onChange={(e) =>
                      canManage && this.onChange("comments", e.target.value)
                    }
                    onBlur={(e) =>
                      canManage && this.onBlur("comments", e.target.value)
                    }
                    disabled={!canManage}
                  />
                </Col>
              </Form.Group>
            )}
          </Fieldset>
        )}

        {((role !== Roles.CLIENT && this.isUpdate) || this.state.hasLogin) && (
          <Fieldset>
            <Legend>
              <FormattedMessage id="Access.PHARMAMAD" />
            </Legend>

            {this.isUpdate &&
              user.role !== Roles.CLIENT &&
              this.props.patient.user_id.length === 0 && (
                <Row>
                  <Col>
                    <Alert variant="warning" className="text-center">
                      <Icon icon="warning" />{" "}
                      <FormattedMessage id="Patient.Has.No.Access" />{" "}
                      {canManage && (
                        <MenuButton
                          icon="user"
                          variant="warning"
                          onClick={() => this.openPatientUserModal()}
                        >
                          <FormattedMessage id="Create.Access" />
                        </MenuButton>
                      )}
                    </Alert>
                  </Col>
                </Row>
              )}

            {this.state.hasLogin && (
              <>
                <Form.Group as={Row} className="mb-3 align-items-center">
                  <CustomLabel
                    label={this.props.intl.formatMessage({
                      id: "Username.Connexion",
                    })}
                    htmlFor="username"
                    displayAsCol
                    required
                  />
                  <Col md={7}>
                    <Form.Group as={Row} className="align-items-center mb-0">
                      <Col>
                        <InputTextElement
                          disabled={
                            !canManage || this.props.user.role === Roles.CLIENT
                          }
                          type="text"
                          id="username"
                          autoComplete="off"
                          maxLength={InputLength.NAME}
                          value={this.state.username}
                          onChange={(e) =>
                            canManage &&
                            this.onChange(
                              "username",
                              e.target.value.toLowerCase(),
                            )
                          }
                          onBlur={(e) =>
                            canManage &&
                            this.isUpdate &&
                            this.onBlur(
                              "username",
                              e.target.value.toLowerCase(),
                            )
                          }
                        />
                      </Col>
                      <Col md={4}>-{this.props.company.url}</Col>
                    </Form.Group>
                    <div className="text-danger">
                      <small>{this.state.usernameError}</small>
                    </div>
                  </Col>
                </Form.Group>

                {!Util.emptyString(this.state.username) &&
                  !this.state.usernameError && (
                    <Form.Group as={Row} className="mb-3 align-items-center">
                      <CustomLabel
                        label={this.props.intl.formatMessage({
                          id: "Mercurial.Linked",
                        })}
                        htmlFor="mercurial_Id"
                        help={<FormattedMessage id="Hint.Linked.Catalog" />}
                        displayAsCol
                      />
                      <Col md={7}>
                        <FormSelect
                          disabled={!canManage}
                          value={this.state.mercurial_Id}
                          onChange={(e) =>
                            canManage &&
                            this.onChange("mercurial_Id", e.target.value)
                          }
                          onBlur={(e) =>
                            canManage &&
                            this.isUpdate &&
                            this.onBlur("mercurial_Id", e.target.value)
                          }
                        >
                          <option value="">
                            {this.props.intl.formatMessage({ id: "None" })}
                          </option>
                          {this.props.mercurials.map((t) => {
                            const hasExpired =
                              t.end_date &&
                              (new Date(t.start_date) > now ||
                                new Date(t.end_date) < now);
                            if (
                              hasExpired ||
                              t.status === MercurialStatus.INACTIVE
                            )
                              return null;

                            return (
                              <option
                                key={t._id}
                                value={t._id}
                                disabled={hasExpired}
                              >
                                {t.name}
                              </option>
                            );
                          })}
                        </FormSelect>
                      </Col>
                    </Form.Group>
                  )}

                {this.isUpdate &&
                  user.role !== Roles.CLIENT &&
                  this.patientAccountData &&
                  this.patientAccountData.password === null && (
                    <Row>
                      <Col>
                        <Alert variant="warning" className="text-center">
                          <Icon icon="warning" />{" "}
                          <FormattedMessage id="User.Has.No.Access" />{" "}
                          <MenuButton
                            icon="paper-plane"
                            variant="warning"
                            onClick={() =>
                              this.props.onSendWelcomeEmail(
                                { userId: this.patientAccountData._id },
                                () => this.openEmailSent(),
                              )
                            }
                          >
                            <FormattedMessage id="Send.Mail.Notify.Created.Account" />
                          </MenuButton>
                        </Alert>
                      </Col>
                    </Row>
                  )}
              </>
            )}
          </Fieldset>
        )}
        {!this.isUpdate && (
          <Form.Group as={Row} className="justify-content-center pt-3">
            <Col md={6}>
              <MenuButton
                className="w-100"
                disabled={this.disabled()}
                onClick={() => this.onSubmit()}
              >
                <FormattedMessage id="Add" />
              </MenuButton>
            </Col>
          </Form.Group>
        )}
        {this.state.modal}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    company: state.company,
    mercurials: state.mercurials,
    clients: state.clients,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddClient: (client, successCallback) =>
      dispatch(addClient(client, successCallback)),
    onAddPatient: (patient, successCallback) =>
      dispatch(addPatient(patient, successCallback)),
    onUpdPatientAdmin: (patient, successCallback) =>
      dispatch(updPatientAdmin(patient, successCallback)),
    onUpdClient: (client, successCallback) =>
      dispatch(updClient(client, successCallback)),
    onCheckIfUsernameExists: (username, existsCallback, noExistsCallback) =>
      dispatch(
        checkIfUsernameExists(username, existsCallback, noExistsCallback),
      ),
    onCheckIfEmailIsValid: (email, isValidCallback, notValidCallback) =>
      dispatch(checkIfEmailIsValid(email, isValidCallback, notValidCallback)),
    onSendWelcomeEmail: (data, successCallback) =>
      dispatch(sendWelcomeEmail(data, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(PatientGeneralInfo));
