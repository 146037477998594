import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { Modal, Alert, Form, Row, Col } from "react-bootstrap";
import Util from "../../util/Util";
import CustomLabel from "../sub/CustomLabel";
import axios from "axios";
import APIUrl from "../../APIUrl";
import InputLength from "../../enums/InputLength";
import InputTextElement from "../sub/InputTextElement";
import MenuButton from "../sub/bootstrap/MenuButton";

class DocumentFolderModal extends React.Component {
  constructor(props) {
    super(props);

    const defaultUserScope = {
      prescriber: false,
      patient: true,
    };

    this.state = {
      name: (this.props.folder && this.props.folder.name) || "",
      parent:
        (this.props.folder && this.props.folder.parent) ||
        this.props.parentId ||
        "",
      parentName:
        (this.props.folder && this.props.folder.parent) ||
        this.props.parentName ||
        "",
      userScope:
        (this.props.folder && this.props.folder.user_scope) || defaultUserScope,
      disabled: false,
    };
  }

  disabled() {
    return (
      Util.emptyString(this.state.name) ||
      Util.emptyString(this.state.parent) ||
      this.state.disabled
    );
  }

  onSubmit() {
    if (this.disabled()) return;

    this.setState({ disabled: true });

    if (!this.props.folder) {
      const newFolder = {
        name: this.state.name,
        parent: this.state.parent,
        user_scope: this.state.userScope,
      };

      axios.post(APIUrl.addDocumentsDbCategory, newFolder).then((response) => {
        this.props.syncData();
        this.props.close();
      });
    } else {
      const updatedDocument = {
        _id: this.props.folder._id,
        name: this.state.name,
        user_scope: this.state.userScope,
      };

      axios
        .post(APIUrl.updateDocumentsDbCategory, updatedDocument)
        .then((response) => {
          this.props.syncData();
          this.props.close();
        });
    }
  }

  render() {
    const { folder, intl } = this.props;

    return (
      <Modal
        show={true}
        onHide={() => this.props.close()}
        backdrop="static"
        keyboard={false}
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {folder ? (
              <>
                <FormattedMessage id={"Rename"} /> "{folder.name}"
              </>
            ) : (
              <FormattedMessage id={"Create.Folder"} />
            )}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {this.state.parentName && !folder && (
            <Alert variant="info">
              <FormattedMessage id={"Creation.Folder.Message"} />{" "}
              <strong>"{this.state.parentName}"</strong>.
            </Alert>
          )}
          <Form.Group as={Row} className="mb-3 align-items-center">
            <CustomLabel
              label={intl.formatMessage({ id: "Name" })}
              htmlFor="name"
              displayAsCol
              required
            />
            <Col md={7}>
              <InputTextElement
                maxLength={InputLength.NAME}
                type="text"
                id="name"
                autoComplete="off"
                value={this.state.name}
                onChange={(e) => this.setState({ name: e.target.value })}
              />
            </Col>
          </Form.Group>

          <Row className="mt-5">
            <div className="col-12 col-lg-6 offset-lg-3">
              <MenuButton
                className="w-100"
                disabled={this.disabled()}
                onClick={() => this.onSubmit()}
              >
                <FormattedMessage id={folder ? "Rename" : "Create"} />
              </MenuButton>
            </div>
          </Row>
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(DocumentFolderModal));
