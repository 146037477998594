import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { Modal, Form, Row, Col, FormSelect } from "react-bootstrap";
import TextareaCounter from "../sub/TextareaCounter";

import Util from "../../util/Util";

import RequestTypes from "../../enums/RequestTypes";
import EstimateStatus from "../../enums/EstimateStatus";

import {
  addRequestFiles,
  createRequest,
  createRequestAdmin,
  deleteRequestFile,
  updateRequest,
} from "../../actions/requests/requests";
import InputLength from "../../enums/InputLength";
import MenuButton from "../sub/bootstrap/MenuButton";
import Roles from "../../enums/Roles";
import APIUrl from "../../APIUrl";
import CustomLabel from "../sub/CustomLabel";
import FileDropZone from "../sub/FileDropZone";
import FileUtil from "../../util/FileUtil";

class EstimateModal extends React.Component {
  constructor(props) {
    super(props);

    const maxFiles = 3;

    this.state = {
      client_id: this.props.request?.client?._id || "",
      precisions: this.props.request?.precisions || "",
      response: this.props.request?.response || "",
      disabled: false,
      files: this.props.request?.files || [],
      errorFilesDropped: null,
      disabledDropZone: this.props.request?.files.length >= maxFiles,
      maxFiles: maxFiles,
      maxFileSize: process.env.REACT_APP_UPLOAD_MAXFILESIZE || 2097152, // If not defined in .env, we set it to 2mb (in bytes)
    };
  }

  create() {
    if (this.hasEmptyField() || this.state.disabled) return;
    this.setState({ disabled: true });

    let data = {
      client_id: this.state.client_id,
      request_type: RequestTypes.ESTIMATE,
      precisions: this.state.precisions,
      status: EstimateStatus.PENDING,
    };

    if (this.props.user.role !== Roles.CLIENT) {
      this.props.onCreateRequestAdmin(data, () => this.props.close());
    } else {
      data.client_id = this.props.user._id;
      this.props.onCreateRequest(data, () => this.props.close());
    }
  }

  update(request) {
    let response = this.state.response;

    let data = {
      requestId: request._id,
      updatedField: "response",
      updatedValue: response,
    };

    this.props.onUpdateRequest(data, () => {});
  }

  hasEmptyField() {
    return (
      (this.props.user.role !== Roles.CLIENT &&
        Util.emptyString(this.state.client_id)) ||
      Util.emptyString(this.state.precisions)
    );
  }

  onChange(key, value) {
    this.setState({ [key]: value });
  }

  checkDataLengthFile(files) {
    let response = false;
    for (let file of files) {
      if (file.size > this.state.maxFileSize) {
        response = true;
      }
    }
    return response;
  }

  onDropFiles(files, request, clearCallback) {
    let formData = new FormData();

    // Required to lookup for possible duplicates
    let fileCount = 0;
    formData.set("fileCount", fileCount);

    if (this.state.files.length + files.length <= this.state.maxFiles) {
      if (!this.checkDataLengthFile(files)) {
        let value = [];
        value = this.state.files;

        for (let file of files) {
          // Avoid duplicates
          if (value.indexOf(file.name) === -1) {
            value.push(file.name);
            this.setState({ files: value });
            formData.append(file.name, file);
            fileCount++;
          }
        }

        // this.setState({ disabledDropZone: true });

        var successCallback = () => {
          clearCallback();
          if (this.state.files.length >= this.state.maxFiles) {
            this.setState({ disabledDropZone: true });
          } else {
            this.setState({ disabledDropZone: false });
          }
        };

        this.setState({ errorFilesDropped: null });

        // Upload only valid files and total files below or equal max allowed files to upload
        if (fileCount > 0) {
          this.props.onAddRequestFiles(request._id, formData, successCallback);
        }
      } else {
        this.setState({
          errorFilesDropped: (
            <FormattedMessage
              id="File.Oversized"
              values={{ value: FileUtil.bytesToSize(this.state.maxFileSize) }}
            />
          ),
        });
      }
    } else {
      this.setState({
        errorFilesDropped: (
          <FormattedMessage
            id="File.Maxlength"
            values={{
              maxFiles: this.state.maxFiles,
              submittedFiles: files.length,
            }}
          />
        ),
      });
    }
  }

  removeFile = (request, file) => {
    // Prepare data to be sent to the BE
    let data = {
      requestId: request._id,
      fileName: file,
    };

    // Define a successcalback on file deletion
    const successCallback = () => {
      // Update state value for files
      const newStateFiles = this.state.files.filter(
        (stateFile) => stateFile !== file,
      );
      this.setState({ files: newStateFiles });

      if (this.state.files.length <= this.state.maxFileSize) {
        this.setState({ disabledDropZone: false });
      }
    };

    this.props.ondeleteRequestFile(data, successCallback);
  };

  render() {
    const { user, clients, request, intl } = this.props;

    let clientsSelectOptions = null;

    if (clients.length > 0) {
      clientsSelectOptions = clients
        .filter((c) => c.client_type !== "desk_user")
        .map((client) => {
          return (
            <option key={client._id} value={client._id}>
              {Util.formatFullName(client.first_name, client.name)}
            </option>
          );
        });
    }

    let adminResponseNode = null;
    adminResponseNode = (
      <Form.Group as={Row} className="align-items-center mt-3">
        <CustomLabel
          label={intl.formatMessage({ id: "Response" })}
          htmlFor="response"
        />
        <Col md={7}>
          <TextareaCounter
            countLimit={InputLength.TEXT_LONG}
            id="response"
            rows={5}
            value={this.state.response}
            disabled={user.role === Roles.CLIENT}
            onChange={(e) => this.onChange("response", e.target.value)}
            onBlur={() => this.update(request)}
          />
        </Col>
      </Form.Group>
    );

    let filesNode = null;
    if (request && this.state.files && this.state.files.length > 0) {
      filesNode = this.state.files.map((file, key) => {
        return (
          <div key={key} className="mb-1">
            <small>
              {user.role !== Roles.CLIENT && (
                <MenuButton
                  icon="trash"
                  size={"sm"}
                  variant="danger"
                  className="me-2"
                  onClick={() => this.removeFile(request, file)}
                />
              )}
              <a
                role="button"
                href={`${APIUrl.getRequestFile}${request._id}/${file}?token=${APIUrl.jwtToken}`}
                rel="noopener noreferrer"
                download={file}
                target="_blank"
              >
                {file}
              </a>
            </small>
          </div>
        );
      });
    }

    return (
      <Modal
        show={true}
        onHide={() => this.props.close()}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage
              id={request ? "Request.New.Material" : "Create.Request"}
            />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {user.role !== Roles.CLIENT && !request && (
            <Form.Group as={Row} className="align-items-center">
              <Col>
                <FormSelect
                  className="mb-3"
                  id="clientId"
                  value={this.state.client_id}
                  onChange={(e) => this.onChange("client_id", e.target.value)}
                  placeholder={this.props.intl.formatMessage({
                    id: "Client",
                  })}
                >
                  <option value="">
                    <FormattedMessage id="Choose.Client" />
                  </option>
                  {clientsSelectOptions}
                </FormSelect>
              </Col>
            </Form.Group>
          )}

          <Form.Group as={Row} className="align-items-center">
            <Col>
              <TextareaCounter
                countLimit={InputLength.TEXT_LONG}
                id="precisions"
                rows={7}
                value={this.state.precisions}
                onChange={(e) =>
                  !request && this.onChange("precisions", e.target.value)
                }
                disabled={request}
                placeholder={this.props.intl.formatMessage({
                  id: "Request.Details",
                })}
              />
            </Col>
          </Form.Group>

          {request && (filesNode || adminResponseNode) && (
            <>
              <hr />
              <Form.Group as={Row} className="align-items-center mt-3">
                <div className="col-12 col-sm-5">
                  <span>
                    <FormattedMessage id="Files" />
                  </span>
                </div>
                <Col md={7}>
                  {user.role !== Roles.CLIENT && (
                    <div className="mb-2">
                      <FileDropZone
                        disabled={this.state.disabledDropZone}
                        onDropFiles={(files, clearCallback) =>
                          this.onDropFiles(files, request, clearCallback)
                        }
                        acceptedExtensions={[
                          "pdf",
                          "doc",
                          "docx",
                          "jpg",
                          "png",
                          "jpeg",
                        ]}
                        multiple={true}
                      />
                      <FormattedMessage id="Attachments" />:{" "}
                      {this.state.files.length} / {this.state.maxFiles}
                      <p className="text-danger">
                        {this.state.errorFilesDropped}
                      </p>
                    </div>
                  )}

                  {filesNode}
                </Col>
              </Form.Group>
              {adminResponseNode}
            </>
          )}
        </Modal.Body>

        {!request && (
          <Modal.Footer>
            <MenuButton variant="secondary" onClick={() => this.props.close()}>
              <FormattedMessage id="Cancel" />
            </MenuButton>
            <MenuButton
              onClick={() => this.create()}
              disabled={this.state.disabled || this.hasEmptyField()}
            >
              <FormattedMessage id="Create" />
            </MenuButton>
          </Modal.Footer>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    clients: state.clients,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCreateRequest: (data, successCallback) =>
      dispatch(createRequest(data, successCallback)),
    onCreateRequestAdmin: (data, successCallback) =>
      dispatch(createRequestAdmin(data, successCallback)),
    onUpdateRequest: (data, successCallback) =>
      dispatch(updateRequest(data, successCallback)),
    onAddRequestFiles: (requestId, data, successCallback) =>
      dispatch(addRequestFiles(requestId, data, successCallback)),
    ondeleteRequestFile: (data, successCallback) =>
      dispatch(deleteRequestFile(data, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(EstimateModal));
