import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import {
  Alert,
  Col,
  FormSelect,
  InputGroup,
  Modal,
  Row,
} from "react-bootstrap";
import CustomLabel from "../sub/CustomLabel";
import { connect } from "react-redux";
import Maths from "../../util/Maths";
import InputNumber from "../sub/fields/input/InputNumber";
import Notification from "../sub/Notification";
import { productsMarginUpdate } from "../../actions/products/products";
import Icon from "../sub/Icon";
import MenuButton from "../sub/bootstrap/MenuButton";
import ProductRow from "./ProductRow";
import ProductsParserUtil from "../../util/ProductsParserUtil";

class ProductsMarginModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      taux_marge: "",
      taux_marque: "",
      margeError: "",
      subCategory: "",
    };
  }

  onSubmit() {
    if (this.disabled()) return;

    const displayNotification = () => {
      Notification({
        type: "success",
        description: this.props.intl.formatMessage({
          id: "Products.Updated",
        }),
      });
    };

    const successCallback = () => {
      displayNotification();
      this.props.closeModal();
    };

    const marginValue =
      this.props.productsSettingsAdmin.margin_selected === "margin"
        ? this.state.taux_marge
        : this.state.taux_marque;

    const data = {
      mercurialId: this.props.mercurialId,
      familyId: this.props.famille._id,
      subCategory: this.state.subCategory,
      marginSelected: this.props.productsSettingsAdmin.margin_selected,
      margin: marginValue,
    };

    this.props.onProductsMarginUpdate(data, successCallback);
  }

  disabled() {
    return (
      (this.props.productsSettingsAdmin.margin_selected === "margin" &&
        this.state.taux_marge === "") ||
      (this.props.productsSettingsAdmin.margin_selected !== "margin" &&
        this.state.taux_marque === "")
    );
  }

  checkMargin(margin, marginMin, marginMax, messageId) {
    if (margin < marginMin || margin > marginMax) {
      this.setState({
        margeError: (
          <FormattedMessage
            id={messageId}
            values={{
              min: marginMin,
              max: marginMax,
            }}
          />
        ),
      });
    } else {
      this.setState({ margeError: "" });
    }
  }

  checkMarginsRate(marginRate, grossMarginRate) {
    let marginRateToCheck = marginRate;
    let rateMin = this.props.productsSettingsAdmin.margin_rate_min;
    let rateMax = this.props.productsSettingsAdmin.margin_rate_max;
    let errorMsg = "Margin.Rate.Out.Of.Bounds";

    if (this.props.productsSettingsAdmin.margin_selected !== "margin") {
      marginRateToCheck = grossMarginRate;
      rateMin = this.props.productsSettingsAdmin.gross_margin_rate_min;
      rateMax = this.props.productsSettingsAdmin.gross_margin_rate_max;
      errorMsg = "Gross.Margin.Rate.Out.Of.Bounds";
    }

    this.checkMargin(marginRateToCheck, rateMin, rateMax, errorMsg);
  }

  onChange(field, value) {
    this.setState({ [field]: Maths.round(value, 2) }, () => {
      this.checkMarginsRate(this.state.taux_marge, this.state.taux_marque);
    });
  }

  getPriceHt(product) {
    const marge =
      this.props.productsSettingsAdmin.margin_selected === "margin"
        ? this.state.taux_marge
        : this.state.taux_marque;

    return product.prix_achat_ht * (1 + marge / 100);
  }

  getPriceTtc(product) {
    const prix_ht = this.getPriceHt(product);

    return prix_ht * (1 + product.tva);
  }

  render() {
    const familyProducts = this.props.products.filter(
      ({ famille_id, prix_achat_ht }) =>
        famille_id === this.props.famille._id && prix_achat_ht > 0,
    );

    const filteredProducts = this.state.subCategory
      ? familyProducts.filter(
          ({ sous_famille }) => sous_famille === this.state.subCategory,
        )
      : familyProducts;

    const previewData = filteredProducts.slice(0, 5).map((product) => ({
      ...product,
      preview_taux_marque: this.state.taux_marque,
      preview_taux_marge: this.state.taux_marge,
      preview_prix_ht: this.getPriceHt(product),
      preview_prix_ttc: this.getPriceTtc(product),
    }));

    const formattedPreviewData = previewData.length
      ? ProductsParserUtil.parseProducts(previewData, previewData[0].famille)
      : [];

    console.log(formattedPreviewData);

    const getAlert = (context, title, error) => (
      <Row className="mt-3 mb-1">
        <Col>
          <Alert variant={context}>
            <p>
              <strong>
                <FormattedMessage id={title} />
              </strong>
            </p>
            {error}
          </Alert>
        </Col>
      </Row>
    );

    return (
      <Modal
        show={true}
        onHide={() => this.props.closeModal()}
        backdrop="static"
        size="xl"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id="Products.Update" />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row className="mt-1 mb-2">
            <Col>
              <Alert variant="warning">
                <h5>
                  <Icon icon="triangle-exclamation" className="me-2" />
                  <FormattedMessage id="Warning" />
                </h5>
                <p>
                  <FormattedMessage id="Products.Update.Info" />
                </p>
              </Alert>
            </Col>
          </Row>

          <Row className="mt-1 mb-2">
            <Col>
              <CustomLabel
                label={<FormattedMessage id="Products.Concerned" />}
                htmlFor="sub_family"
              />
            </Col>
            <Col>
              <FormSelect
                id="sub_family"
                value={this.state.subCategory}
                onChange={(e) => this.setState({ subCategory: e.target.value })}
              >
                <option value="">
                  {this.props.intl.formatMessage({ id: "All.Products" })}
                </option>
                {this.props.subCategories &&
                  this.props.subCategories.length > 0 &&
                  this.props.subCategories.map((c) => (
                    <option value={c} key={c}>
                      {c}
                    </option>
                  ))}
              </FormSelect>
            </Col>
          </Row>

          {this.props.productsSettingsAdmin.margin_selected === "margin" && (
            <Row>
              <Col>
                <CustomLabel
                  label={<FormattedMessage id="Margin.Rate" />}
                  htmlFor="margin_rate"
                />
              </Col>
              <Col>
                <InputGroup style={{ borderBottom: 0 }} id="margin_rate">
                  <InputNumber
                    id={"inputGroupMarginRate"}
                    min={1}
                    defaultValue={this.state.taux_marge}
                    onChange={(value) => this.onChange("taux_marge", value)}
                  />
                  <InputGroup.Text id="inputGroupMarginRate">%</InputGroup.Text>
                </InputGroup>
              </Col>
            </Row>
          )}

          {this.props.productsSettingsAdmin.margin_selected !== "margin" && (
            <Row>
              <Col>
                <CustomLabel
                  label={<FormattedMessage id="Gross.Margin.Rate" />}
                  htmlFor="gross_margin_rate"
                />
              </Col>
              <Col>
                <InputGroup style={{ borderBottom: 0 }} id="gross_margin_rate">
                  <InputNumber
                    id={"inputGroupGrossMarginRate"}
                    min={1}
                    max={99}
                    defaultValue={this.state.taux_marque}
                    onChange={(value) => this.onChange("taux_marque", value)}
                  />
                  <InputGroup.Text id="inputGroupGrossMarginRate">
                    %
                  </InputGroup.Text>
                </InputGroup>
              </Col>
            </Row>
          )}

          {this.state.margeError &&
            getAlert("warning", "Warning", this.state.margeError)}

          {(this.state.taux_marge > 0 || this.state.taux_marque > 0) && (
            <>
              <h4 className="mt-5 mb-3">
                {" "}
                <FormattedMessage id="Overview.Of.Changes" />
              </h4>
              {formattedPreviewData && formattedPreviewData.length !== 0 ? (
                <table className="table table-striped tablee4mad">
                  <thead>
                    <tr>
                      <th className="col-2">
                        <FormattedMessage id="Product" />
                      </th>
                      <th className="col-auto">
                        <FormattedMessage id="Description" />
                      </th>
                      <th className="col-2">
                        <FormattedMessage id="Features" />
                      </th>
                      <th className="text-nowrap">
                        <FormattedMessage id="Unit.Price" />
                      </th>

                      <th className="text-nowrap">
                        <FormattedMessage
                          id={
                            this.props.productsSettingsAdmin.margin_selected ===
                            "margin"
                              ? "Margin.Rate"
                              : "Gross.Margin.Rate"
                          }
                        />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {formattedPreviewData.map((p) => (
                      <ProductRow
                        product={p}
                        key={p._id}
                        mode={"margin_preview"}
                      />
                    ))}
                  </tbody>
                </table>
              ) : (
                <Alert variant="secondary">
                  <FormattedMessage id="No.Products.Available.For.Update" />
                </Alert>
              )}
            </>
          )}
        </Modal.Body>

        <Modal.Footer>
          <MenuButton
            variant="secondary"
            onClick={() => this.props.closeModal()}
          >
            <FormattedMessage id="Cancel" />
          </MenuButton>
          <MenuButton
            onClick={() => this.onSubmit()}
            disabled={this.disabled() || filteredProducts.length === 0}
          >
            <FormattedMessage id="Confirm" />
          </MenuButton>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    productsSettingsAdmin: state.productsSettingsAdmin,
    products: state.products,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onProductsMarginUpdate: (data, successCallback) =>
      dispatch(productsMarginUpdate(data, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(ProductsMarginModal));
