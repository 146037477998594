import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import DateUtil from "../../util/DateUtil";
import APIUrl from "../../APIUrl";
import Roles from "../../enums/Roles";
import AuditCommentModal from "./AuditCommentModal";
import {
  deleteAudit,
  getAuditsAdmin,
  sendAudit,
} from "../../actions/audits/audits";
import ConfirmationModal from "../sub/modals/ConfirmationModal";
import FileUtil from "../../util/FileUtil";
import Util from "../../util/Util";
import EmailModal from "../sub/modals/EmailModal";
import { Alert, Modal } from "react-bootstrap";
import MenuButton from "../sub/bootstrap/MenuButton";

class PatientAudit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
      patientAuditsNode: null,
    };
  }

  getAuditsPatient(audits, patient) {
    return audits.filter((p) => p.patient_id === patient._id);
  }

  commentModal(audit) {
    this.setState({ modal: null }, () =>
      this.setState({
        modal: (
          <AuditCommentModal
            isOpen={true}
            audit={audit ? audit : null}
            close={() => this.closeModal()}
          />
        ),
      }),
    );
  }

  closeModal() {
    this.setState({ modal: null });
  }

  getClient(id) {
    return this.props.clients.find((c) => c._id === id);
  }

  emailModal(audit) {
    this.setState({ modal: null }, () =>
      this.setState({
        modal: (
          <ModalBlock
            audit={audit ? audit : null}
            patient={this.props.patient}
            prescribers={this.props.prescribers}
            helpers={this.props.helpers}
            prescriber={
              audit.prescriber && this.getClient(audit.prescriber._id)
            }
            helper={audit.helper && this.getClient(audit.helper._id)}
            clients={this.props.clients}
            sendEmails={(emails, audit) => this.sendEmails(emails, audit)}
            close={() => this.closeModal()}
          />
        ),
      }),
    );
  }

  sendEmails(emails, audit) {
    if (emails.length > 0) {
      const data = {
        patientId: this.props.patient._id,
        auditId: audit._id,
        emailsToSend: emails,
      };

      this.setState({
        disabled: true,
      });

      let successCallback = (response) => {
        let title = <FormattedMessage id="Mail.Sent.Audit" />;
        let content = <FormattedMessage id="Mail.Sent.Success" />;
        let variant = "success";

        if (response.data !== "OK") {
          content = <FormattedMessage id="Mail.Sent.Error" />;
          variant = "danger";
        }

        this.setState({
          modal: (
            <ConfirmationModal
              variant={variant}
              title={title}
              cancelButtonDisabled
              confirmButtonLabel={this.props.intl.formatMessage({
                id: "Close",
              })}
              onAccept={() => {
                this.closeModal();
              }}
            >
              {content}
            </ConfirmationModal>
          ),
        });
      };

      this.props.onSendAudit(data, successCallback);
    } else {
      this.props.close();
    }
  }

  // DELETE AN AUDIT
  _deleteAudit(auditId) {
    const onConfirm = () => {
      let data = {
        auditId: auditId,
        patienId: this.props.patient._id,
      };

      this.props.onDeleteAudit(data, () => {
        this.props.onGetAuditsAdmin();
      });
    };

    this.setState({
      modal: (
        <ConfirmationModal
          onAccept={onConfirm}
          onDecline={() => this.closeModal()}
          mandatoryConfirmation
        >
          <Alert variant="warning">
            <FormattedMessage id="Confirm.Audit.Removal" />
          </Alert>
        </ConfirmationModal>
      ),
    });
  }

  render() {
    const { audits, patient } = this.props;

    var patientAuditsNode = null;

    if (!patient || !audits) return null;

    const { user } = this.props;
    const { role } = user;

    if (role === undefined) return null;

    const canManage =
      !user.supervisor_id ||
      (user.supervisor_id && user.has_write_permission);

    let auditsPatient = this.getAuditsPatient(audits, patient);
    let fileNameAudit =
      "audit_" +
      this.props.patient.first_name +
      "_" +
      this.props.patient.name +
      "_";

    if (auditsPatient.length > 0) {
      patientAuditsNode = auditsPatient.map((audit) => {
        var hasCmt = 0;
        if (audit.private_comments && audit.private_comments.length) {
          audit.private_comments.forEach((cmt) => {
            if (cmt.comment) {
              hasCmt = 1;
            }
          });
        }

        let failureCallback = (error) => {
          this.setState({
            modal: (
              <ConfirmationModal
                variant="danger"
                title={<FormattedMessage id="Error" />}
                cancelButtonDisabled
                confirmButtonLabel={this.props.intl.formatMessage({
                  id: "Close",
                })}
                onAccept={() => {
                  this.closeModal();
                }}
              >
                <FormattedMessage id="Documents.NotFound.Content" />
              </ConfirmationModal>
            ),
          });
        };
        //fetch(APIUrl.getAuditsPDF + audit.patient_id + "/cerfa_" + audit._id + "?token=" + APIUrl.jwtToken).then(response =>{res = response.status})

        return (
          <tr key={"audit-" + audit._id}>
            <td className="align-middle">
              {audit && DateUtil.toDate(audit.createdAt)}
            </td>
            <td className="align-middle text-center">
              <MenuButton
                icon="download"
                onClick={() =>
                  FileUtil.dowloadFileHack(
                    APIUrl.getAuditsPDF + audit.patient_id + "/" + audit._id,
                    fileNameAudit + DateUtil.toyyyyMMdd(audit.createdAt),
                    "pdf",
                    (error) => failureCallback(error),
                  )
                }
                hover={<FormattedMessage id="Display.PDF" />}
              />
            </td>
            <td className="align-middle text-center">
              <MenuButton
                icon="download"
                onClick={() =>
                  FileUtil.dowloadFileHack(
                    APIUrl.getAuditsPDF +
                      audit.patient_id +
                      "/cerfa_" +
                      audit._id +
                      "?token=" +
                      APIUrl.jwtToken,
                    "cerfa_" + audit._id,
                    "pdf",
                    (error) => failureCallback(error),
                  )
                }
                hover={<FormattedMessage id="Display.PDF" />}
              />
            </td>

            {canManage && role !== Roles.CLIENT && (
              <>
                <td className="align-middle text-center">
                  <MenuButton
                    icon="paper-plane"
                    onClick={(e) =>
                      FileUtil.checkRemoteFileExists(
                        APIUrl.getCheckAuditsPDF +
                          audit.patient_id +
                          "/" +
                          audit._id,
                        (error) => failureCallback(error),
                        () => this.emailModal(audit),
                      )
                    }
                    hover={<FormattedMessage id="Mail.Resend" />}
                  />
                </td>

                {audit._id && audit.products && (
                  <td className="align-middle text-center">
                    <MenuButton
                      icon={hasCmt ? "comment" : "comment-slash"}
                      variant={hasCmt ? "warning" : "primary"}
                      onClick={(e) => this.commentModal(audit)}
                      disabled={!hasCmt}
                      hover={
                        hasCmt ? (
                          <FormattedMessage id="Available.Comment" />
                        ) : (
                          ""
                        )
                      }
                    />
                  </td>
                )}

                <td className="text-center">
                  <MenuButton
                    icon="trash"
                    onClick={(e) => this._deleteAudit(audit._id)}
                    hover={<FormattedMessage id="Delete" />}
                  />
                </td>
              </>
            )}
          </tr>
        );
      });
    }

    return (
      <React.Fragment>
        {patient && auditsPatient && auditsPatient.length <= 0 && (
          <Alert variant="secondary" className="mt-3">
            <FormattedMessage id={"Empty.Audit.Done"} />
          </Alert>
        )}

        {patient && auditsPatient && auditsPatient.length > 0 && (
          <table className="table tablee4mad mt-3">
            <thead>
              <tr className="tablerowhead">
                <th>
                  <FormattedMessage id="Creation.Date" />
                </th>
                <th className="text-center">
                  <FormattedMessage id="Elements.PDF.Title" />
                </th>
                <th className="text-center">
                  <FormattedMessage id="Elements.PDF.Cerfa" />
                </th>
                {canManage && role !== Roles.CLIENT && (
                  <>
                    <th className="text-center">
                      <FormattedMessage id="Email" />
                    </th>
                    <th className="text-center">
                      <FormattedMessage id="Internal.Comment" />
                    </th>
                    <th className="text-center col-auto">
                      <FormattedMessage id="Actions" />
                    </th>
                  </>
                )}
              </tr>
            </thead>
            <tbody>{patientAuditsNode}</tbody>
          </table>
        )}

        {this.state.modal}
      </React.Fragment>
    );
  }
}

class ModalBlock extends React.Component {
  render() {
    return (
      <Modal
        show={true}
        onHide={() => this.props.close()}
        backdrop="static"
        keyboard={false}
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id="Send.Report.To" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EmailModal
            patient={this.props.patient}
            prescribers={this.props.prescribers}
            helpers={this.props.helpers}
            clients={this.props.clients}
            prescriber={this.props.prescriber}
            helper={this.props.helper}
            audit={this.props.audit}
            origin={"list"}
            sendEmails={(emails, audit) => this.props.sendEmails(emails, audit)}
            close={() => this.props.close()}
          />
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    clients: state.clients,
    helpers: Util.filterClients(state.clients, "helper"),
    prescribers: Util.filterClients(state.clients, "prescriber"),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onDeleteAudit: (data, successCallback) =>
      dispatch(deleteAudit(data, successCallback)),
    onGetAuditsAdmin: (data, successCallback) =>
      dispatch(getAuditsAdmin(data, successCallback)),
    onSendAudit: (data, successCallback) =>
      dispatch(sendAudit(data, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(PatientAudit));
