import React from "react";
import { FormattedMessage } from "react-intl";
import { Alert, Col, Container, Row } from "react-bootstrap";
import Icon from "../sub/Icon.js";
import ConfirmationModal from "../sub/modals/ConfirmationModal.js";
import Notification from "../sub/Notification";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { deleteMercurial } from "../../actions/mercurials/mercurials.js";
import MercurialStatus from "../../enums/MercurialStatus.js";
import PopoverHelper from "../sub/bootstrap/PopoverHelper.js";
import Select from "react-select";
import { updateBulkClientsCatalog } from "../../actions/clients/clients.js";
import SwitchToggle from "../sub/SwitchToggle.js";

class MercurialArchiveModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isDeleteCatalog: false,
      isReplaceCatalog: false,
      selectedCatalog: null,
    };
  }

  getCatalogSelectOptions() {
    const options = [];
    for (const mercurial of this.props.mercurials) {
      const now = new Date();
      const hasExpired =
        mercurial.end_date &&
        (new Date(mercurial.start_date) > now ||
          new Date(mercurial.end_date) < now);
      if (
        !hasExpired &&
        mercurial.status !== MercurialStatus.INACTIVE &&
        mercurial._id !== this.props.mercurial._id
      ) {
        options.push({ value: mercurial._id, label: mercurial.name });
      }
    }

    options.unshift({ value: "", label: <FormattedMessage id="None" /> });

    return options;
  }

  render() {
    const newCatalogId =
      (this.state.selectedCatalog && this.state.selectedCatalog.value) || "";

    const modalTitle = (
      <div>
        <FormattedMessage id="Mercurial.Archive.Title" />
        <small>
          <PopoverHelper>
            <FormattedMessage id={"Mercurial.Archive.Info.1"} />
          </PopoverHelper>
        </small>
      </div>
    );

    const successCallback = () => {
      const callback = () => {
        if (this.state.isDeleteCatalog) {
          this.props.onDeleteMercurial({
            mercurialId: this.props.mercurial._id,
          });

          Notification({
            type: "success",
            description: this.props.intl.formatMessage({
              id: "Mercurial.Deleted",
            }),
          });
        } else {
          this.props.updateStatus(
            MercurialStatus.INACTIVE,
            this.props.mercurial,
          );
        }

        this.props.closeModal();
      };

      if (this.state.isReplaceCatalog && this.state.selectedCatalog) {
        const data = {
          oldCatalogId: this.props.mercurial._id,
          newCatalogId: this.state.selectedCatalog.value,
        };

        this.props.onUpdateBulkClientsCatalog(data, callback);
      } else {
        callback();
      }
    };

    return (
      <ConfirmationModal
        size={"lg"}
        title={modalTitle}
        mandatoryConfirmation
        onAccept={successCallback}
        onDecline={() => this.props.closeModal()}
      >
        <div>
          <Alert variant="warning">
            <h5>
              <Icon icon="triangle-exclamation" className="me-2" />
              <FormattedMessage id="Warning" />
            </h5>
            <p>
              <FormattedMessage id="Mercurial.Archive.Warning.1" />
            </p>
            <p>
              <FormattedMessage id="Mercurial.Archive.Warning.2" />
            </p>
          </Alert>

          <p>
            <FormattedMessage id="Mercurial.Archive.Info.2" />
          </p>

          <Container>
            <Row className="mb-3">
              <Col className="col-auto">
                {" "}
                <SwitchToggle
                  id="delete"
                  onChange={(e) => {
                    this.setState({
                      isDeleteCatalog: !this.state.isDeleteCatalog,
                    });
                  }}
                  checked={this.state.isDeleteCatalog}
                />
              </Col>
              <Col>
                <FormattedMessage id="Mercurial.Delete" />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col className="col-auto">
                {" "}
                <SwitchToggle
                  id="replace"
                  className="me-2"
                  onChange={() => {
                    this.setState({
                      isReplaceCatalog: !this.state.isReplaceCatalog,
                    });
                  }}
                  checked={this.state.isReplaceCatalog}
                />
              </Col>
              <Col>
                <FormattedMessage id="Mercurial.Replace" />
              </Col>
            </Row>
            {this.state.isReplaceCatalog && (
              <Row className="align-items-center mb-3">
                <Col>
                  <Select
                    id="new-catalog"
                    placeholder={this.props.intl.formatMessage({
                      id: "Select.Mercurial",
                    })}
                    options={this.getCatalogSelectOptions()}
                    noOptionsMessage={() => (
                      <FormattedMessage id={"No.Result"} />
                    )}
                    onChange={(option) => {
                      this.setState({ selectedCatalog: option });
                    }}
                    value={this.getCatalogSelectOptions().find(
                      (option) => option.value === newCatalogId,
                    )}
                  />
                </Col>
              </Row>
            )}
          </Container>
        </div>
      </ConfirmationModal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    mercurials: state.mercurials,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onDeleteMercurial: (data) => dispatch(deleteMercurial(data)),
    onUpdateBulkClientsCatalog: (data, successCallback) =>
      dispatch(updateBulkClientsCatalog(data, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(MercurialArchiveModal));
