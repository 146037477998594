import React from "react";
import { connect } from "react-redux";

import Orders from "../../orders/Orders";
import Estimates from "../../estimates/Estimates";
import Patients from "../../patients/Patients";
import SplitHomeItem from "./SplitHomeItem";
import OrdersAdmin from "../../orders/admin/OrdersAdmin";
import Prescribers from "../../prescribers/Prescribers";

import Accessibilities from "../../../enums/MenuAccessibilities";
import Roles from "../../../enums/Roles";
import PatientsEquipmentsReminded from "../../patients/PatientsEquipmentsReminded";
import { getPatientEquipmentsReminded } from "../../../actions/patients/patients";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import DeskOngoingPage from "../../patients/desk/pages/DeskOngoingPage";
import MenuButton from "../../sub/bootstrap/MenuButton";

class SplitHome extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      remindedEquipments: [],
      modal: null,
    };
  }

  componentDidMount() {
    if (
      (this.props.user.role !== Roles.ADMIN &&
        this.props.user.role !== Roles.SALES_REP) ||
      this.props.prescriberId ||
      this.props.clientType === "patient"
    )
      return;

    this.props.onGetPatientEquipmentsReminded((data) => {
      this.setState({ remindedEquipments: data });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (this.props.user.role !== Roles.ADMIN &&
        this.props.user.role !== Roles.SALES_REP) ||
      this.props.prescriberId ||
      this.props.clientType === "patient"
    )
      return;

    if (prevProps.patients !== this.props.patients) {
      const callback = () => {
        if (this.state.modal !== null && this.state.isReminderModal)
          this.openRemindedEquipmentsModal(this.state.remindedEquipments);
      };
      this.props.onGetPatientEquipmentsReminded((data) => {
        this.setState({ remindedEquipments: data }, callback);
      });
    }
  }

  getAccessibilityByRole(itemAccessibility) {
    switch (this.props.user.role) {
      case Roles.CLIENT:
        if (
          itemAccessibility === Accessibilities.CLIENT_ONLY ||
          itemAccessibility === Accessibilities.COMMON
        )
          return true;
        break;
      case Roles.ADMIN:
      case Roles.SALES_REP:
        if (
          itemAccessibility === Accessibilities.ADMIN_AND_SALES_REP ||
          itemAccessibility === Accessibilities.COMMON
        )
          return true;
        break;
      default:
    }
    return false;
  }

  getAccessibilityByModules(itemModules, user = this.props.user) {
    for (let i = 0; i < itemModules.length; i++) {
      if (
        (itemModules[i] === "DESK" &&
          (user.access_pharmamad || user.access_module_desk)) ||
        (itemModules[i] === "HOME" &&
          (user.access_pharmamad || user.access_module_home)) ||
        (itemModules[i] === "STORE" &&
          (user.access_pharmamad || user.access_module_store))
      )
        return true;
    }
    return false;
  }

  closeModal() {
    this.setState({ modal: null });
  }

  openCatalog() {
    this.setState({
      modal: <DeskOngoingPage close={() => this.closeModal()} isReadOnly />,
    });
  }

  render() {
    const items = [
      this.props.user.client_type !== "patient" && {
        col: 6,
        link: "/home/patients",
        formattedMessageId: "Summary.Patients.Monitoring",
        accessibility: Accessibilities.COMMON,
        modules: ["DESK", "STORE", "HOME"],
        content: <Patients limit={5} />,
      },
      {
        col: 6,
        link: "/home/orders",
        formattedMessageId: "Summary.Orders",
        accessibility: Accessibilities.CLIENT_ONLY,
        modules: ["STORE"],
        content: <Orders limit={5} />,
      },
      {
        col: 6,
        link: "/home/estimates",
        formattedMessageId: "Summary.Estimates",
        accessibility: Accessibilities.CLIENT_ONLY,
        modules: ["DESK", "STORE", "HOME"],
        content: <Estimates limit={5} />,
      },

      {
        col: 6,
        link: "/home/clients",
        formattedMessageId: "Summary.Clients.Admin",
        accessibility: Accessibilities.ADMIN_AND_SALES_REP,
        modules: ["DESK", "STORE", "HOME"],
        content: <Prescribers limit={5} />,
      },

      {
        col: 6,
        link: "/home/orders",
        formattedMessageId: "Summary.Orders.Admin",
        accessibility: Accessibilities.ADMIN_AND_SALES_REP,
        modules: ["STORE"],
        content: <OrdersAdmin limit={5} />,
      },
      {
        col: 6,
        link: "/home/patients",
        formattedMessageId: "Alerts.Renewal",
        accessibility: Accessibilities.ADMIN_AND_SALES_REP,
        modules: ["DESK", "STORE", "HOME"],
        content: (
          <PatientsEquipmentsReminded
            equipments={this.state.remindedEquipments}
            limit={5}
          />
        ),
      },
      {
        col: 12,
        link: "",
        formattedMessageId: "Ressources",
        accessibility: Accessibilities.ADMIN_AND_SALES_REP,
        modules: ["DESK", "STORE", "HOME"],
        content: (
          <MenuButton icon="file" onClick={() => this.openCatalog()}>
            <FormattedMessage id="Browse.Catalog.MAD" />
          </MenuButton>
        ),
      },
    ];

    const itemNode = (items) => {
      return items.map((item, index) => {
        return (
          this.getAccessibilityByRole(item.accessibility) &&
          this.getAccessibilityByModules(item.modules) && (
            <Col key={index} md={item.col}>
              <SplitHomeItem
                title={<FormattedMessage id={item.formattedMessageId} />}
                content={item.content}
                link={item.link}
              />
            </Col>
          )
        );
      });
    };

    return (
      <>
        <Row className="g-3">{itemNode(items)}</Row>
        {this.state.modal}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    patients: state.patients,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetPatientEquipmentsReminded: (successCallback) =>
      dispatch(getPatientEquipmentsReminded(successCallback)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SplitHome);
