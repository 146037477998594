import APIUrl from "../../APIUrl";
import axios from "axios";
import { getCart } from "../cart/cart";

export const GET_ORDERS = "GET_ORDERS";

function getOrdersAction(orders) {
  return { type: GET_ORDERS, orders: orders };
}

export const passOrder = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.addOrder, data)
      .then(function (response) {
        dispatch(getCart());
        dispatch(getOrders());
        if (successCallback) return successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};
export const syncOrderLomacoAdmin = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.syncOrderLomacoAdmin, data)
      .then(function (response) {
        dispatch(getOrdersAdmin());
        successCallback(response);
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const syncOrderMustAdmin = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.syncOrderMustAdmin, data)
      .then(function (response) {
        dispatch(getOrdersAdmin());
        successCallback(response);
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const getOrders = function () {
  return function (dispatch) {
    return axios
      .get(APIUrl.getOrders)
      .then(function (response) {
        dispatch(getOrdersAction(response.data));
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const deleteOrder = function (data) {
  return function (dispatch) {
    return axios
      .post(APIUrl.deleteOrder, data)
      .then(function (response) {
        // Immediately refresh the order so it's up-to-date
        dispatch(getOrders());
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const duplicate = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.duplicate, data)
      .then(function (response) {
        // Immediately refresh the order so it's up-to-date
        dispatch(getCart());
        successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

// ======================================================================
// ============================= ADMIN ==================================
// ======================================================================

export const getOrdersAdmin = function () {
  return function (dispatch) {
    return axios
      .get(APIUrl.getOrdersAdmin)
      .then(function (response) {
        dispatch(getOrdersAction(response.data));
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const updateAdmin = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.updateAdmin, data)
      .then(function (response) {
        dispatch(getOrdersAdmin());
        if (successCallback) successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const deleteOrderAdmin = function (data) {
  return function (dispatch) {
    return axios
      .post(APIUrl.deleteOrderAdmin, data)
      .then(function (response) {
        // Immediately refresh the order so it's up-to-date
        dispatch(getOrdersAdmin());
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const splitOrderAdmin = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.splitOrderAdmin, data)
      .then(function (response) {
        // Immediately refresh the order so it's up-to-date
        dispatch(getOrdersAdmin());
        successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};
