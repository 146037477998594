import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { Badge, Col, Row } from "react-bootstrap";
import DateUtil from "../../util/DateUtil.js";
import Roles from "../../enums/Roles";
import { getClientsByPatientId } from "../../actions/clients/clients.js";
import { getAdmins } from "../../actions/admin/admin.js";
import {
  createMessage,
  getMessages,
  messageNotifPatientById,
  updateSeen,
  deleteMessage,
} from "../../actions/coordination/coordination.js";
import Icon from "../sub/Icon.js";
import Select from "react-select";
import TableToolbar from "../sub/bootstrap/TableToolbar";
import CoordinationChat from "./CoordinationChat";
import Notification from "../sub/Notification";
import Util from "../../util/Util.js";

class Coordination extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      patientId: null,
      selectedPatient: null,
      sendingMessage: null,
      messages: [],
      clientsList: [],
      connectedList: [],
      wsInitiated: false,
      unreadMessage: [],
      optionsList: [],
    };
  }

  componentDidMount() {
    this.props.onMessageNotifPatientById(
      { patient_id: this.getIdsOfPatients() },
      (data) => {
        this.setState({ unreadMessage: data });
      },
    );
    this.setState({ optionsList: this.getSelectOptions() });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.getSelectOptions() !== this.state.optionsList &&
      prevState.optionsList === this.state.optionsList
    ) {
      this.setState({
        optionsList: this.getSelectOptions(),
        selectedPatient: this.getSelectedOptions(this.state.selectedPatient),
      });
    }
    if (this.state.patientId !== null) {
      if (
        this.state.messages === null ||
        prevState.patientId !== this.state.patientId
      ) {
        this.props.onUpdateSeen({ patient_id: this.state.patientId });
        this.props.onGetAllMessages(
          { patient_id: this.state.patientId },
          (data) => {
            this.setState({ messages: data });
          },
        );
        this.setState({ optionsList: this.getSelectOptions() });
      } else if (
        prevProps.coordination.length !== this.props.coordination.length &&
        this.props.coordination !== this.state.messages
      ) {
        this.props.onGetAllMessages(
          { patient_id: this.state.patientId },
          (data) => {
            this.setState({ messages: data });
          },
        );
      }
    }

    if (
      this.props.patients.length > 0 &&
      this.state.unreadMessage.length === 0 &&
      prevState.unreadMessage === this.state.unreadMessage
    ) {
      this.props.onMessageNotifPatientById(
        { patient_id: this.getIdsOfPatients() },
        (data) => {
          this.setState({ unreadMessage: data });
        },
      );
    }

    if (this.props.socket.url && !this.state.wsInitiated) {
      this.props.socket.on("update_coordination_notifications", () => {
        this.props.onMessageNotifPatientById(
          { patient_id: this.getIdsOfPatients() },
          (data) => {
            this.setState({ unreadMessage: data });
          },
        );
      });
      this.props.socket.emit("connectedlist", {
        data: { company_id: this.props.user.company_id },
      });
      this.props.socket.on("connectedlist", (data) => {
        this.setState({ connectedList: data.list });
      });
      this.setState({ wsInitiated: true });
    }
  }

  getIdsOfPatients() {
    let response = [];
    for (let patient of this.props.patients) {
      response.push({ patient_id: patient._id });
    }
    return response;
  }

  getClientsByPatient() {
    let response = [];
    for (let client of this.props.clients) {
      if (
        client.client_type === "prescriber" &&
        this.isPatientOfPrescriber(this.state.patientId, client._id)
      ) {
        response.push(client);
      }
    }
    return response;
  }

  changeMessage(value) {
    this.setState({ sendingMessage: value });
  }

  removeMessage(messageId) {
    this.props.onDeleteMessage({ message_id: messageId });
  }

  send() {
    const msg = {
      patient_id: this.state.patientId,
      company_id: this.props.company_id,
      message: this.state.sendingMessage,
      sender: this.props.user._id,
      seen: [{ user_id: this.props.user._id }],
      updated_date: DateUtil.DateTime().now(),
    };
    this.props.onCreateMessage(msg, () => {
      this.props.onGetAllMessages(
        { patient_id: this.state.patientId },
        (data) => {
          this.setState({ messages: data, sendingMessage: null });
        },
      );
      Notification({
        type: "success",
        description: this.props.intl.formatMessage({
          id: "Message.Create.Success",
        }),
      });
    });

    this.setState({ messages: "", sendingMessage: "" }, () => {});
  }

  getSelectedOptions(patient) {
    let option = null;
    if (patient) {
      for (const c of this.props.patients) {
        if (this.state.unreadMessage.length > 0) {
          for (let count of this.state.unreadMessage) {
            if (count.patient_id === c._id && patient.value === c._id) {
              if (count.count > 0)
                option = {
                  value: c._id,
                  label: (
                    <div>
                      <span className="text-danger font-weight-bold">
                        {Util.formatFullName(c.first_name, c.name)}
                      </span>{" "}
                      <Badge bg="danger">
                        {count.count}{" "}
                        {count.count > 1 ? (
                          <FormattedMessage id="Message.Unreads" />
                        ) : (
                          <FormattedMessage id="Message.Unread" />
                        )}
                      </Badge>
                    </div>
                  ),
                  searchTerm: Util.formatFullName(c.first_name, c.name),
                };
              else
                option = {
                  value: c._id,
                  label: Util.formatFullName(c.first_name, c.name),
                  searchTerm: Util.formatFullName(c.first_name, c.name),
                };
            }
          }
        } else {
          option = {
            value: c._id,
            label: Util.formatFullName(c.first_name, c.name),
          };
        }
      }
    }
    return option;
  }

  getSelectOptions() {
    let options = [];
    for (const c of this.props.patients.filter((p) => p.active)) {
      if (this.state.unreadMessage.length > 0) {
        for (let count of this.state.unreadMessage) {
          if (count.patient_id === c._id) {
            if (count.count > 0)
              options.push({
                value: c._id,
                label: (
                  <div>
                    <span className="text-danger font-weight-bold">
                      {Util.formatFullName(c.first_name, c.name)}
                    </span>{" "}
                    <Badge bg="danger">
                      {count.count}{" "}
                      {count.count > 1 ? (
                        <FormattedMessage id="Message.Unreads" />
                      ) : (
                        <FormattedMessage id="Message.Unread" />
                      )}
                    </Badge>
                  </div>
                ),
                searchTerm: Util.formatFullName(c.first_name, c.name),
              });
            else
              options.push({
                value: c._id,
                label: Util.formatFullName(c.first_name, c.name),
                searchTerm: Util.formatFullName(c.first_name, c.name),
              });
          }
        }
      } else {
        options.push({
          value: c._id,
          label: Util.formatFullName(c.first_name, c.name),
        });
      }
    }

    return options;
  }

  isPatientOfPrescriber(patientId, clientId) {
    for (let patient of this.props.patients) {
      for (let prescriber_id of patient.prescribers_ids) {
        if (patientId === patient._id && prescriber_id === clientId) {
          return true;
        }
      }
    }
    return false;
  }

  getCollabs(collabs, clients) {
    let response = [];
    let pushcollab = false;
    let isAlreadyClient = false;
    for (let collab of collabs) {
      pushcollab = false;
      for (let client of clients) {
        for (let prescriberList of collab.prescribers) {
          if (
            !response.includes(collab) &&
            prescriberList.prescriber_id === client._id &&
            isAlreadyClient === false
          ) {
            pushcollab = true;
          }
          if (collab._id === client._id) {
            pushcollab = false;
            isAlreadyClient = true;
          }
        }
      }
      if (pushcollab === true) {
        response.push(collab);
      }
    }
    return response;
  }

  render() {
    //modification de la recherche afin qu'il prenne en compte une autre option.
    const customFilterOption = (option, rawInput) => {
      const words = rawInput.split(" ").filter((word) => word.trim() !== "");
      return words.reduce((acc, cur) => {
        if (acc) {
          return (
            (typeof option.label === "string" &&
              option.label.toLowerCase().includes(cur.toLowerCase())) ||
            (option.data.searchTerm &&
              option.data.searchTerm.toLowerCase().includes(cur.toLowerCase()))
          );
        }
        return false;
      }, true);
    };

    return (
      <React.Fragment>
        <TableToolbar>
          <Row className="w-100 align-items-center">
            <Col className="text-end">
              <Icon icon="user" className="me-2" />
            </Col>
            <Col md={7}>
              <Select
                className="text-start w-50"
                placeholder={<FormattedMessage id={"Select.Patient"} />}
                options={this.state.optionsList}
                isClearable
                filterOption={customFilterOption}
                noOptionsMessage={() => <FormattedMessage id={"No.Result"} />}
                onChange={(option) => {
                  if (option) {
                    if (this.props.socket.url) {
                      this.props.socket.emit("connectedlist", {
                        data: { company_id: this.props.user.company_id },
                      });
                    }
                    if (this.props.user.role !== Roles.ADMIN) {
                      this.props.onGetClientsByPatientId(
                        { patient_id: option.value },
                        (value) => {
                          this.props.onGetAdmins((admins) => {
                            let filteredValue = value.filter((client) => {
                              if (client._id === this.props.user._id)
                                return false;
                              return true;
                            });
                            let filteredAdmin = admins.filter((c) => {
                              if (c._id === this.props.user._id) return false;
                              return true;
                            });
                            filteredValue = filteredValue.concat(filteredAdmin);
                            this.setState({
                              clientsList: filteredValue,
                            });
                          });
                        },
                      );
                    }
                    this.setState(
                      {
                        selectedPatient: option,
                        patientId: option && option.value,
                      },
                      () => {
                        if (this.props.user.role === Roles.ADMIN) {
                          this.props.onGetAdmins((admins) => {
                            let clientsList = this.getClientsByPatient();
                            this.setState({ clientsList: clientsList });
                            let filteredAdmins = admins.filter((client) => {
                              if (client._id === this.props.user._id)
                                return false;
                              return true;
                            });
                            clientsList = clientsList.concat(filteredAdmins);
                            clientsList = clientsList.concat(
                              this.getCollabs(
                                this.props.collaborators,
                                clientsList,
                              ),
                            );
                            this.setState({
                              clientsList: clientsList,
                            });
                          });
                        }
                      },
                    );
                  } else {
                    this.setState({
                      selectedPatient: option,
                      patientId: option && option.value,
                    });
                  }
                }}
                value={this.state.selectedPatient}
              />
            </Col>
          </Row>
        </TableToolbar>
        {this.state.selectedPatient && (
          <CoordinationChat
            connectedList={this.state.connectedList}
            sendingMessage={this.state.sendingMessage}
            clients={this.state.clientsList}
            patient_id={this.state.patientId}
            messages={this.state.messages}
            send={() => this.send()}
            onChangeMessage={(e) => {
              this.changeMessage(e);
            }}
            onRemoveMessage={(messageId) => {
              this.removeMessage(messageId);
            }}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    patients: state.patients,
    user: state.user,
    coordination: state.coordination,
    socket: state.socket,
    clients: state.clients,
    collaborators: state.collaborators,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCreateMessage: (data, successCallback) =>
      dispatch(createMessage(data, successCallback)),
    onGetAllMessages: (data, successCallback) =>
      dispatch(getMessages(data, successCallback)),
    onGetClientsByPatientId: (data, successCallback) =>
      dispatch(getClientsByPatientId(data, successCallback)),
    onGetAdmins: (successCallback) => dispatch(getAdmins(successCallback)),
    onMessageNotifPatientById: (data, successCallback) =>
      dispatch(messageNotifPatientById(data, successCallback)),
    onUpdateSeen: (data) => dispatch(updateSeen(data)),
    onDeleteMessage: (data) => dispatch(deleteMessage(data)),
    //
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(Coordination));
