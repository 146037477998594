import React from "react";
import { FormattedMessage } from "react-intl";
import APIUrl from "../../../APIUrl";
import {
  Row,
  Col,
  Container,
  Alert,
  Badge,
  Card,
  Modal,
} from "react-bootstrap";
import MenuButton from "../../sub/bootstrap/MenuButton";
import Icon from "../../sub/Icon";
import ImageWithFallback from "../../sub/ImageWithFallback";
import { connect } from "react-redux";
import ProductsParserUtil from "../../../util/ProductsParserUtil";
import ProductDetailsTemplatePDF from "../../products/ProductDetailsTemplatePDF";

class DeskProductPanier extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
    };
  }

  backToCategory(categoryId) {
    this.props.setState({ category_id: categoryId });
    this.props.setOngoingState({
      panier: null,
      product_id: null,
      relatedCategories: null,
    });
  }

  next(titleConfirm, contentConfirmFinalyseAudit) {
    const isEstimateEnabled = this.props.user.access_module_store;

    if (isEstimateEnabled && this.props.deskCart.length > 0) {
      return this.props.setOngoingState({ panier: null, estimate: true });
    }

    this.props.openConfirmationModal(
      titleConfirm,
      contentConfirmFinalyseAudit,
      () => this.props.addAudit(),
    );
  }

  closeModal() {
    this.setState({ modal: null });
  }

  openProductDetailModal(product) {
    this.setState({
      modal: (
        <Modal
          show={true}
          onHide={() => this.closeModal()}
          size="xl"
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton></Modal.Header>

          <Modal.Body>
            <ProductDetailsTemplatePDF
              id={"preview-product-store"}
              generalSettings={this.props.generalSettings}
              product={product}
            />
          </Modal.Body>
        </Modal>
      ),
    });
  }

  render() {
    const {
      deskProducts,
      deskCategories,
      deskStore,
      deskCart,
      setOngoingState,
      scrollToTopDesk,
      mandatoryCategoryId,
      openConfirmationModal,
      delDeskStore,
      template,
      templateCerfa,
      openCommentModal,
      openRelatedCatalogProductsModal,
      user,
      isLoading,
    } = this.props;

    if (!mandatoryCategoryId) return null;

    const mandatCategory = deskCategories.find(
      (c) => c._id === mandatoryCategoryId,
    );

    if (!mandatCategory || !mandatCategory._id) return null;

    let hasMandatoryProduct = false;

    const titleConfirm = <FormattedMessage id="Confirm" />;
    const contentConfirmFinalyseAudit = <FormattedMessage id="Confirm.Audit" />;
    const contentConfirmDeleteProduct = (
      <FormattedMessage id="Product.Remove.Confirmation.2" />
    );

    const isEstimateEnabled = user.access_module_store;

    if (deskStore) {
      const productsCleanIds = [];
      for (const _productId in deskStore) {
        productsCleanIds.push(_productId);
      }

      var productsNodes = productsCleanIds.map((_productId, idx) => {
        const product = deskProducts.find((p) => p._id === _productId);
        const category = deskCategories.find(
          (c) => c._id === product.category_id,
        );
        const imgSrc = `${APIUrl.getDeskProductImg}${product._id}?token=${APIUrl.jwtToken}`;
        let productsFromCatalog = [];

        if (deskCart) {
          for (const pFromC of product.productsFromCatalog) {
            let pFromCatalog = deskCart.find(
              (p) => p._id === pFromC.product_id,
            );
            if (pFromCatalog) {
              productsFromCatalog.push(pFromCatalog);
            }
          }
        }

        if (category._id === mandatoryCategoryId) hasMandatoryProduct = true;

        return (
          <Card className="mb-1" key={"product-" + idx}>
            <Card.Body>
              <Row className="d-flex align-items-center">
                <Col sm={2} className="text-center">
                  <ImageWithFallback
                    src={imgSrc}
                    alt={"desk_product_img" + Math.random()}
                    style={{ maxWidth: "8em" }}
                    thumbnail
                  />
                </Col>
                <Col>
                  <Row>
                    <Col>
                      <strong>
                        <FormattedMessage id="Product" />
                      </strong>{" "}
                      : {product.name}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <strong>
                        <FormattedMessage id="Reference" />
                      </strong>{" "}
                      : {product.ref}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <strong>
                        <FormattedMessage id="Category" />
                      </strong>{" "}
                      : {category.name}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <strong>
                        <FormattedMessage id="Type" />
                      </strong>{" "}
                      : {deskStore[product._id].vte_loc}
                    </Col>
                  </Row>
                  {productsFromCatalog && productsFromCatalog.length !== 0 && (
                    <Row>
                      <Col>
                        <strong>
                          <FormattedMessage id="Products.From.Catalog" />
                        </strong>{" "}
                        :{" "}
                        {productsFromCatalog.map((p) => (
                          <Row key={p._id}>
                            <Col md={12} className="text-start">
                              <MenuButton
                                key={p._id}
                                variant="link"
                                className="p-0"
                                onClick={() => this.openProductDetailModal(p)}
                              >
                                {ProductsParserUtil.swapDesignationDelimiter(
                                  p.designation,
                                )}
                              </MenuButton>
                            </Col>
                          </Row>
                        ))}
                      </Col>
                    </Row>
                  )}
                </Col>
                <Col sm={2}>
                  <Row>
                    <Col className="text-end">
                      <Row className="mb-2">
                        <Col>
                          <MenuButton
                            icon="eye"
                            size="sm"
                            variant="outline-secondary"
                            className="w-100"
                            onClick={(e) =>
                              setOngoingState({
                                panier: null,
                                relatedCategories: null,
                                product_id: product._id,
                              })
                            }
                          >
                            <FormattedMessage id="Consult" />
                          </MenuButton>
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        <Col>
                          <MenuButton
                            icon={
                              deskStore[product._id].comment
                                ? "check"
                                : "comment"
                            }
                            size="sm"
                            className="w-100"
                            variant={
                              deskStore[product._id].comment
                                ? "warning"
                                : "outline-secondary"
                            }
                            onClick={(e) =>
                              openCommentModal(product, deskStore[product._id])
                            }
                          >
                            <FormattedMessage id="Comment" />
                          </MenuButton>
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        <Col>
                          <MenuButton
                            icon="trash"
                            size="sm"
                            className="w-100"
                            variant="outline-danger"
                            onClick={() =>
                              openConfirmationModal(
                                titleConfirm,
                                contentConfirmDeleteProduct,
                                () => delDeskStore(product._id),
                              )
                            }
                          >
                            <FormattedMessage id="Delete" />
                          </MenuButton>
                        </Col>
                      </Row>
                      {isEstimateEnabled && (
                        <Row>
                          <Col>
                            <MenuButton
                              icon={
                                productsFromCatalog &&
                                productsFromCatalog.length !== 0
                                  ? "check"
                                  : "cart-plus"
                              }
                              size="sm"
                              className="w-100"
                              variant={
                                productsFromCatalog &&
                                productsFromCatalog.length !== 0
                                  ? "success"
                                  : "outline-secondary"
                              }
                              onClick={() =>
                                openRelatedCatalogProductsModal(product, null)
                              }
                            >
                              {productsFromCatalog &&
                              productsFromCatalog.length !== 0 ? (
                                <FormattedMessage id="Product.Added" />
                              ) : (
                                <FormattedMessage id="Select" />
                              )}
                            </MenuButton>
                          </Col>
                        </Row>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        );
      });
    }

    return (
      <Container>
        <Row>
          <Col>
            <Row>
              <Col className="text-center mb-3">
                <h1>
                  <Badge bg="dark">
                    <FormattedMessage id="Selection.Header" />
                  </Badge>
                </h1>
              </Col>
            </Row>
            <Card>
              <Card.Body>
                {!hasMandatoryProduct ? (
                  <Alert variant="danger">
                    <Row className="d-flex align-items-center">
                      <Col>
                        <Icon icon="exclamation-triangle" className="me-2" />
                        <FormattedMessage id="Selection.Mandatory.Error" />
                        {mandatCategory.name}
                      </Col>
                      <Col sm={3} className="text-end">
                        <MenuButton
                          onClick={(e) =>
                            this.backToCategory(mandatoryCategoryId)
                          }
                          variant="danger"
                        >
                          <FormattedMessage id="Selection.Mandatory.Back" />
                        </MenuButton>
                      </Col>
                    </Row>
                  </Alert>
                ) : (
                  <Alert variant="success">
                    <Icon icon="circle-check" className="me-2" />
                    <FormattedMessage id="Selection.Mandatory" />:{" "}
                    {mandatCategory.name}
                  </Alert>
                )}

                {productsNodes}
              </Card.Body>
            </Card>
            <Card className="mt-3">
              <Card.Body>
                <Row>
                  <Col>
                    <MenuButton
                      icon="arrow-left"
                      onClick={(e) => {
                        const relatedCategoriesValue = this.props
                          .initialProductId
                          ? true
                          : false;
                        setOngoingState({
                          panier: null,
                          relatedCategories: relatedCategoriesValue,
                        });
                        scrollToTopDesk();
                      }}
                    >
                      <FormattedMessage id="Return" />
                    </MenuButton>
                  </Col>

                  {hasMandatoryProduct && (
                    <Col className="text-end">
                      <MenuButton
                        icon="circle-plus"
                        onClick={() => {
                          setOngoingState({ initialProductId: null });
                          this.backToCategory(null);
                        }}
                      >
                        <FormattedMessage id="Change.Main.Category" />
                      </MenuButton>
                      <MenuButton
                        icon="circle-check"
                        processing={isLoading}
                        onClick={() =>
                          !isLoading &&
                          this.next(titleConfirm, contentConfirmFinalyseAudit)
                        }
                        disabled={!hasMandatoryProduct || isLoading}
                        className="ms-2"
                        variant="success"
                      >
                        <FormattedMessage id="End.Audit" />
                      </MenuButton>
                    </Col>
                  )}
                </Row>
              </Card.Body>
            </Card>

            {this.state.modal}

            {template && (
              <div
                className="d-none"
                style={{ position: "absolute", marginLeft: "-10000px" }}
              >
                {template}
              </div>
            )}
            {templateCerfa && (
              <div
                className="d-none"
                style={{ position: "absolute", marginLeft: "-20000px" }}
              >
                {templateCerfa}
              </div>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    generalSettings: state.generalSettings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeskProductPanier);
