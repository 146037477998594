import React from "react";
import { connect } from "react-redux";
import DateUtil from "../../util/DateUtil";
import { injectIntl } from "react-intl";
import APIUrl from "../../APIUrl";
import { FormattedMessage } from "react-intl";
import Roles from "../../enums/Roles";
import ActionMenu from "../sub/ActionMenu";
import { Modal } from "react-bootstrap";
import EmailModal from "../sub/modals/EmailModal";
import Util from "../../util/Util";
import { sendIntervention } from "../../actions/interventions/Interventions";
import ConfirmationModal from "../sub/modals/ConfirmationModal";
import FileUtil from "../../util/FileUtil";

class InterventionRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
      disableExport: false,
    };
  }

  closeModal() {
    this.setState({ modal: null });
  }

  sendEmails(emails) {
    if (emails.length > 0) {
      const data = {
        patientId: this.props.patient._id,
        interventionId: this.props.intervention._id,
        emailsToSend: emails,
      };

      this.setState({
        disabled: true,
      });

      let successCallback = (response) => {
        let title = <FormattedMessage id="Mail.Sent.Intervention" />;
        let content = <FormattedMessage id="Mail.Sent.Success" />;
        let variant = "success";

        if (response.data !== "OK") {
          content = <FormattedMessage id="Mail.Sent.Error" />;
          variant = "danger";
        }

        this.setState({
          modal: (
            <ConfirmationModal
              variant={variant}
              title={title}
              cancelButtonDisabled
              confirmButtonLabel={this.props.intl.formatMessage({
                id: "Close",
              })}
              onAccept={() => {
                this.closeModal();
              }}
            >
              {content}
            </ConfirmationModal>
          ),
        });
      };

      this.props.onSendIntervention(data, successCallback);
    } else {
      this.closeModal();
    }
  }

  emailModal() {
    this.setState({ modal: null }, () =>
      this.setState({
        modal: (
          <Modal
            show={true}
            onHide={() => this.closeModal()}
            backdrop="static"
            keyboard={false}
            size="md"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <FormattedMessage id="Send.Report.To" />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <EmailModal
                patient={this.props.patient}
                prescribers={this.props.prescribers}
                helpers={this.props.helpers}
                clients={this.props.clients}
                sendEmails={(emails) => this.sendEmails(emails)}
                origin="list"
                close={() => this.closeModal()}
              />
            </Modal.Body>
          </Modal>
        ),
      }),
    );
  }

  render() {
    const { intervention, user } = this.props;
    const interventionDate =
      intervention.signature_date === undefined
        ? DateUtil.toDate(intervention.creation_date)
        : DateUtil.toDate(intervention.signature_date);

    const contactName =
      user.role !== Roles.CLIENT
        ? intervention.technician_name
        : intervention.patient_name;

    const canManage =
      !this.props.user.supervisor_id ||
      (this.props.user.supervisor_id &&
        this.props.user.has_write_permission);

    const failureCallback = (error) => {
      this.setState({
        modal: (
          <ConfirmationModal
            variant="danger"
            title={<FormattedMessage id="Error" />}
            cancelButtonDisabled
            confirmButtonLabel={this.props.intl.formatMessage({
              id: "Close",
            })}
            onAccept={() => {
              this.closeModal();
            }}
          >
            <FormattedMessage id="Documents.NotFound.Content" />
          </ConfirmationModal>
        ),
      });
    };

    let menuItems = [];

    menuItems.push(
      {
        icon: "file-pdf",
        action: () =>
          FileUtil.dowloadFileHack(
            APIUrl.getInterventionsPDF +
              intervention.patient_id +
              "/" +
              intervention._id +
              "?token=" +
              APIUrl.jwtToken,
            "intervention_" + intervention._id,
            "pdf",
            (error) => failureCallback(error),
          ),
        text: <FormattedMessage id="Download.Intervention" />,
        disabled: this.state.disableExport,
      },
      canManage &&
        user.role !== Roles.CLIENT && {
          icon: "paper-plane",
          action: () =>
            FileUtil.checkRemoteFileExists(
              APIUrl.getInterventionsPDF +
                intervention.patient_id +
                "/" +
                intervention._id +
                "?token=" +
                APIUrl.jwtToken,
              (error) => failureCallback(error),
              () => this.emailModal(intervention),
            ),

          text: <FormattedMessage id="Mail.Resend" />,
        },
      canManage &&
        user.role !== Roles.CLIENT && {
          icon: "trash",
          text: <FormattedMessage id="Delete" />,
          action: () => this.props.deleteIntervention(intervention),
          disabled: this.state.disableExport,
        },
    );

    const menuAction = <ActionMenu items={menuItems} />;

    return (
      <>
        <tr key={intervention._id}>
          <td className="align-middle">{interventionDate}</td>
          <td className="align-middle">
            <FormattedMessage
              id={"Intervention.Type." + intervention.intervention_type}
            />
          </td>
          {user.role !== Roles.CLIENT && !this.props.equipment && (
            <td className="align-middle">{intervention.patient_name}</td>
          )}
          <td className="align-middle">{contactName}</td>
          {!this.props.limit && (
            <td className="align-middle text-center">{menuAction}</td>
          )}
        </tr>

        {this.state.modal}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    clients: state.clients,
    helpers: Util.filterClients(state.clients, "helper"),
    prescribers: Util.filterClients(state.clients, "prescriber"),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSendIntervention: (data, successCallback) =>
      dispatch(sendIntervention(data, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(InterventionRow));
