import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import PriceUtil from "../../util/PriceUtil";
import Roles from "../../enums/Roles";
import ProductsParserUtil from "../../util/ProductsParserUtil";
import APIUrl from "../../APIUrl";
import { Gallery, Item } from "react-photoswipe-gallery";
import "photoswipe/dist/photoswipe.css";
import { addFavorite, deleteFavorite } from "../../actions/user/user";
import Notification from "../sub/Notification";
import { addProductsImgs } from "../../actions/mercurials/mercurials";
import MenuButton from "../sub/bootstrap/MenuButton";
import ActionMenu from "../sub/ActionMenu.js";
import ImageWithFallback from "../sub/ImageWithFallback";
import InputLength from "../../enums/InputLength";
import StringUtil from "../../util/StringUtil";
import InputNumber from "../sub/fields/input/InputNumber";
import { Badge, Col, Form, FormSelect, Row } from "react-bootstrap";
import CustomLabel from "../sub/CustomLabel";
import PopoverHelper from "../sub/bootstrap/PopoverHelper";

class ProductRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      _formatId: this.props.searchProductId
        ? this.props.searchProductId
        : this.props.product._id,
      formatName: null,
      disableButton: false,
      quantity: 0,
      isExpanded: false,
      isLoading: false,
      productsFromCatalog: this.props.productsFromCatalog || [],
    };
  }

  changeFormat(e) {
    e.preventDefault();
    e.stopPropagation();
    // Get Id & name for current format
    const formatId = e.target.value.split(";")[0];
    const formatName = e.target.value.split(";")[1];
    // Change state so that it now focuses on correct product format
    this.setState({ _formatId: formatId, formatName: formatName });
  }

  changeQuantity(value) {
    this.setState({ quantity: value });
  }

  add(e, product, quantity) {
    if (
      this.state.quantity < this.props.product.min_cde ||
      this.state.disableButton
    )
      return;
    // Disable button
    this.setState({ disableButton: true });

    const successCallback = () => {
      this.setState({ disableButton: false, quantity: 0 });
      Notification({
        type: "info",
        description: `${this.props.intl.formatMessage({ id: "Cart.Add.Success" })} ${ProductsParserUtil.swapDesignationDelimiter(product.designation)}`,
      });
    };
    // Call parent method
    this.props.onAdd(e, this.state._formatId, quantity, successCallback);
  }

  addMin(e, product) {
    this.add(e, product, product.min_cde);
  }

  getProduct(id) {
    // Id is main product. Return main product
    if (this.props.product._id === id) return this.props.product;

    // Id corresponds to a specific format. Use this format instead
    for (let format of this.props.product.formats) {
      if (format._id === id) return format;
    }
  }

  validMaxQuantity(value) {
    if (value > 1000) {
      this.setState({ quantity: 1000 });
    }
  }

  isClient() {
    return (
      this.props.user.role !== Roles.SALES_REP &&
      this.props.user.role !== Roles.ADMIN
    );
  }

  setFavorite(ref_frn) {
    const data = { ref_frn: ref_frn };

    const successCallback = (messageId) => {
      Notification({
        type: "success",
        description: this.props.intl.formatMessage({ id: messageId }),
      });
    };

    if (!this.checkFavorite(ref_frn)) {
      this.props.onUpdateFavorite(data, successCallback("Favorite.Added"));
    } else {
      this.props.onDeleteFavorite(data, successCallback("Favorite.Removed"));
    }
  }

  isDisabled() {
    return (
      this.state.quantity < this.props.product.min_cde ||
      this.state.disableButton
    );
  }

  checkFavorite(ref_frn) {
    if (this.props.user.favorite_products) {
      for (let favorite of this.props.user.favorite_products) {
        if (favorite.ref_frn === ref_frn) {
          return true;
        }
      }
    }
    return false;
  }

  downloadPDF(product) {
    this.setState({ isLoading: true }, () =>
      this.props.downloadPDF(product, () => {
        this.setState({ isLoading: false });
      }),
    );
  }

  addProductsFromCatalog(product) {
    const updatedProducts = [...this.state.productsFromCatalog];

    const add = () => {
      for (const p of product.formats) {
        updatedProducts.push({
          mercurial_id: p.mercurial_id,
          ref_frn: p.ref_frn,
          product_id: p._id,
        });
      }

      this.setState({ productsFromCatalog: updatedProducts }, () =>
        this.props.addProduct(product),
      );

      Notification({
        type: "info",
        description: `${this.props.intl.formatMessage({ id: "Product.Add.Success" })} ${ProductsParserUtil.getDesignation(product)}`,
      });
    };

    if (updatedProducts.find((p) => p._id === product.product_id)) {
      const modalTitle = <FormattedMessage id="Warning" />;
      const modalContent = (
        <div>
          <p>
            <FormattedMessage id="Product.From.Catalog.Already.Associated" />
          </p>

          <p>
            <FormattedMessage id="Confirm.Product.From.Catalog.Association" />
          </p>
        </div>
      );
      this.props.openConfModal(modalTitle, modalContent, add);
    } else {
      add();
    }
  }

  deleteProductsFromCatalog(product) {
    let updatedProducts = [...this.state.productsFromCatalog];

    for (const p of product.formats) {
      updatedProducts = updatedProducts.filter(
        (item) => item.product_id !== p._id,
      );
    }

    this.setState({ productsFromCatalog: updatedProducts }, () =>
      this.props.deleteProduct(product),
    );

    Notification({
      type: "info",
      description: this.props.intl.formatMessage({ id: "Product.Remove" }),
    });
  }

  displayMargin(marginRate, marginRateMin, marginRateMax) {
    const isMarginInOfBounds = (marginRate, marginRateMin, marginRateMax) =>
      marginRate >= marginRateMin && marginRate <= marginRateMax;

    let marginColor = "text-secondary";

    if (marginRate) {
      if (isMarginInOfBounds(marginRate, marginRateMin, marginRateMax)) {
        marginColor = "text-success";
      } else {
        marginColor = "text-danger";
      }
    }

    return (
      <strong className={marginColor}>
        {marginRate ? `${marginRate} %` : <FormattedMessage id="Undefined" />}
      </strong>
    );
  }

  render() {
    let productFormat = this.getProduct(this.state._formatId);

    let favoriteBtnClass = "outline-success";
    let favoriteIconClass = "star";
    let favoriteMessage = <FormattedMessage id="Favorite.Add" />;
    let disableActionMenu = false;
    let isAlreadySelected = false;
    let allowSelect = true;
    let menuActionVariant = "primary";
    let associatedEntryName;

    if (this.checkFavorite(this.props.product.ref_frn)) {
      favoriteBtnClass = "success";
      favoriteIconClass = "star";
      favoriteMessage = <FormattedMessage id="Favorite.Remove" />;
    }

    // Show a select list of the formats only if more than 1 format
    let formats = null;
    if (this.props.product.formats.length === 1) {
      const modelSubstrings = this.props.product.designation.split(
        process.env.REACT_APP_PRODUCT_DELIMITER,
      );
      const model =
        modelSubstrings && modelSubstrings.length > 1
          ? modelSubstrings[modelSubstrings.length - 1].trim()
          : "";

      formats = model;
    } else {
      formats = (
        <FormSelect
          size="sm"
          onChange={(e) => this.changeFormat(e)}
          defaultValue={this.state._formatId}
        >
          {this.props.product.formats.map((format) => (
            <option
              key={format._id}
              value={format._id + ";" + format.format.trim()}
            >
              {format.format.trim()}
            </option>
          ))}
        </FormSelect>
      );
    }
    let quantity = (
      <InputNumber
        id="quantity"
        min={0}
        max={1000}
        step={this.props.product.min_cde}
        defaultValue={parseInt(this.state.quantity)}
        onBlur={() => this.validMaxQuantity(this.state.quantity)}
        onChange={(e) => this.changeQuantity(e, this.props.product)}
        onKeyDown={(e) => {
          if (e.key === "Enter") this.add(e, this.props.product);
        }}
        className="product-quantity-input"
      />
    );

    let addButton = (
      <>
        <MenuButton
          variant="outline-success"
          icon="cart-plus"
          className={"ms-auto mb-1"}
          onClick={(e) => this.add(e, this.props.product, this.state.quantity)}
          disabled={
            this.state.quantity < this.props.product.min_cde ||
            this.state.disableButton
          }
          hover={
            !(
              this.state.quantity < this.props.product.min_cde ||
              this.state.disableButton
            ) ? (
              <FormattedMessage id="Basket.Add" />
            ) : (
              <FormattedMessage id="Basket.Set.Quantity" />
            )
          }
        />
        <MenuButton
          icon={favoriteIconClass}
          variant={favoriteBtnClass}
          className={"ms-1 mb-1"}
          onClick={() => this.setFavorite(this.props.product.ref_frn)}
          hover={favoriteMessage}
        />
        <MenuButton
          processing={this.state.isLoading}
          icon={"file-pdf"}
          variant={"outline-secondary"}
          className={"ms-1 mb-1"}
          onClick={() => this.downloadPDF(product)}
          hover={<FormattedMessage id="Download.Product.Sheet" />}
        />
      </>
    );

    // if user is not client, maybe he will upload new pictures. We avoid caching them to display changes instantly.
    // customImg modifications allow reloading of the display
    // Client wil use regular browser cache management.
    const product = productFormat ? productFormat : this.props.product;
    const forceCacheRefresh =
      this.props.user.role === Roles.CLIENT ? 0 : product.customImg;
    const currentProductImgUrl =
      APIUrl.getProductImg +
      this.props.product.mercurial_id +
      "/" +
      encodeURIComponent(product.ref_frn) +
      "/" +
      product.customImg +
      "/" +
      encodeURIComponent(product.ref) +
      "/" +
      encodeURIComponent(product.parentProductRefFrn) +
      "/?token=" +
      APIUrl.jwtToken;

    const formatsImages = [];

    if (this.props.product.formats.length > 0) {
      for (let i = 1; i < this.props.product.formats.length; i++) {
        formatsImages.push({
          title: `${ProductsParserUtil.getDesignation(this.props.product)} - ${this.props.product.formats[i].format}`,
          src: `${APIUrl.getProductImg}${this.props.product.mercurial_id}/${encodeURIComponent(this.props.product.formats[i].ref_frn)}/${forceCacheRefresh}/${encodeURIComponent(this.props.product.formats[i].ref)}/${encodeURIComponent(this.props.product.formats[i].parentProductRefFrn)}/?token=${APIUrl.jwtToken}`,
        });
      }
    }

    if (
      this.props.user.role === Roles.SALES_REP ||
      this.props.user.role === Roles.ADMIN
    ) {
      quantity = null;
      addButton = null;
    }

    const canManage =
      (!this.props.user.supervisor_id &&
        this.props.user.permissions.can_manage_catalog) ||
      (this.props.user.supervisor_id &&
        this.props.user.has_write_permission);

    const menuItems = [];

    if (!this.props.deskProductId) {
      menuItems.push(
        canManage && {
          icon: "circle-plus",
          action: () => this.props.openProductModal(product, true),
          text: <FormattedMessage id="Add.Product.Declination" />,
        },
        canManage && {
          icon: "pen-to-square",
          action: () => this.props.openProductModal(product),
          text: <FormattedMessage id="Modify" />,
        },
        {
          icon: "file-pdf",
          action: () => this.downloadPDF(product),
          text: <FormattedMessage id="Download.Product.Sheet" />,
        },
        canManage && {
          icon: "trash",
          action: () => this.props.openConfModal(product),
          text: <FormattedMessage id="Delete" />,
        },
      );
    } else {
      const deskProducts = this.props.deskProducts;

      // First loop through every desk category, looking for associated products
      outerLoop: for (let entry of deskProducts) {
        associatedEntryName = entry.name; // Get associated category name
        // Second loop (inner loop) to lookup for assiciated products
        for (let deskP of entry.productsFromCatalog) {
          // If current product is found (already associated)
          if (deskP.product_id === product._id) {
            isAlreadySelected = true; // Set selected

            if (entry._id.toString() === this.props.deskProductId.toString()) {
              // If current product is in the related category we are working on, great ! we can choose to unselect it
              menuActionVariant = "success";
              menuItems.push({
                action: () =>
                  this.deleteProductsFromCatalog(this.props.product),
                text: (
                  <FormattedMessage
                    id="Remove.Product.From.Desk.Category"
                    values={{ category: associatedEntryName }}
                  />
                ),
              });
              allowSelect = false;
            } else {
              // If current product is associated with another category, we notify the user about it (unselect is not permitted)
              menuActionVariant = "outline-secondary";
              menuItems.push({
                icon: "circle-info",
                text: (
                  <FormattedMessage
                    id="Product.Already.associated"
                    values={{ category: associatedEntryName }}
                  />
                ),
              });
              allowSelect = false;
            }
            break outerLoop;
          }
        }
      }

      // Retrive current related category to cacth it's name
      const relatedCategory = deskProducts.find(
        (item) => item._id === this.props.deskProductId,
      );

      menuItems.push(
        allowSelect && {
          action: () => this.addProductsFromCatalog(this.props.product),
          text: (
            <FormattedMessage
              id="Add.Product.From.Desk.Category"
              values={{ category: relatedCategory.name }}
            />
          ),
        },
      );
    }

    const menuAction = (
      <ActionMenu
        processing={this.state.isLoading}
        items={menuItems}
        variant={menuActionVariant}
        icon={isAlreadySelected ? "check" : "plus"}
        disabled={disableActionMenu}
      />
    );

    const marginValue =
      this.props.productsSettingsAdmin.margin_selected === "margin"
        ? this.displayMargin(
            product.taux_marge,
            this.props.productsSettingsAdmin.margin_rate_min,
            this.props.productsSettingsAdmin.margin_rate_max,
          )
        : this.displayMargin(
            product.taux_marque,
            this.props.productsSettingsAdmin.gross_margin_rate_min,
            this.props.productsSettingsAdmin.gross_margin_rate_max,
          );

    const previewMarginValue =
      this.props.productsSettingsAdmin.margin_selected === "margin"
        ? this.displayMargin(
            product.preview_taux_marge,
            this.props.productsSettingsAdmin.margin_rate_min,
            this.props.productsSettingsAdmin.margin_rate_max,
          )
        : this.displayMargin(
            product.preview_taux_marque,
            this.props.productsSettingsAdmin.gross_margin_rate_min,
            this.props.productsSettingsAdmin.gross_margin_rate_max,
          );

    const remains =
      (this.props.mode !== "margin_preview"
        ? PriceUtil.priceTtc(product, 1, 2)
        : product.preview_prix_ttc) - product.lpp_amount;

    const isOutOfPLV =
      product.preview_prix_ttc && product.prix_limite_ttc
        ? product.preview_prix_ttc > product.prix_limite_ttc
        : false;
    return (
      <tr>
        <td className="align-middle">
          <Gallery withCaption>
            <Item
              caption={ProductsParserUtil.swapDesignationDelimiter(
                this.props.product.designation,
              )}
              original={currentProductImgUrl}
              thumbnail={currentProductImgUrl}
              width="512"
              height="512"
            >
              {({ ref, open }) => (
                <div ref={ref} onClick={open} className="cursor-zoom-in">
                  <ImageWithFallback
                    src={currentProductImgUrl}
                    thumbnail
                    alt={ProductsParserUtil.swapDesignationDelimiter(
                      this.props.product.designation,
                    )}
                  />
                </div>
              )}
            </Item>
            {formatsImages.map((img, i) => (
              <Item
                key={i}
                caption={img.title}
                original={img.src}
                thumbnail={img.src}
                width="512"
                height="512"
              >
                {({ ref, open }) => (
                  <div ref={ref} onClick={open}>
                    <ImageWithFallback
                      src={img.src}
                      alt={img.title}
                      className="d-none"
                      thumbnail
                    />
                  </div>
                )}
              </Item>
            ))}
          </Gallery>
        </td>
        <td className="align-middle">
          <Row>
            <Col className="text-muted">
              <small>{this.props.product.sous_famille}</small>
            </Col>
          </Row>
          <Row>
            <Col>
              <strong>
                {ProductsParserUtil.getDesignation(this.props.product)}
              </strong>
            </Col>
          </Row>
          <Row>
            <Col>
              <Badge className="me-2">
                <FormattedMessage id="Ref.Short" />:{" "}
                <span className="product-ref-value">{product.ref}</span>
              </Badge>
              {(this.props.user.role === Roles.SALES_REP ||
                this.props.user.role === Roles.ADMIN) &&
                product.lpp_code && (
                  <Badge className="me-2" bg="info">
                    <FormattedMessage id="Lpp.Code" />:{" "}
                    <span className="product-lpp-code-value">
                      {product.lpp_code}
                    </span>
                  </Badge>
                )}
              {(this.props.user.role === Roles.SALES_REP ||
                this.props.user.role === Roles.ADMIN) &&
                product.prix_limite_ttc && (
                  <Badge bg="danger">
                    <FormattedMessage id="Unit.Selling.Limit.Price.Short" />
                  </Badge>
                )}
            </Col>
          </Row>
          <Row>
            <Col className="text-white-space">
              <small>
                {" "}
                {this.state.isExpanded ||
                this.props.product.caracteristiques.length <=
                  InputLength.TEXT ? (
                  <p>{this.props.product.caracteristiques}</p>
                ) : (
                  <p>
                    {StringUtil.truncate(
                      this.props.product.caracteristiques,
                      InputLength.TEXT,
                    )}
                  </p>
                )}
                {this.props.product.caracteristiques.length >
                  InputLength.TEXT && (
                  <MenuButton
                    size="sm"
                    onClick={() =>
                      this.setState({ isExpanded: !this.state.isExpanded })
                    }
                    variant="link"
                    className="text-info p-0"
                  >
                    [
                    <FormattedMessage
                      id={!this.state.isExpanded ? "Show.More" : "Show.Less"}
                    />
                    ]
                  </MenuButton>
                )}
              </small>
            </Col>
          </Row>
        </td>
        <td className="align-middle">
          {formats && (
            <Form.Group as={Row} className="align-items-center pb-3">
              <strong>
                <CustomLabel label={<FormattedMessage id="Declinations" />} />
              </strong>
              <Col>{formats}</Col>
            </Form.Group>
          )}
          <Row>
            <Col>
              <strong>
                <FormattedMessage id="Packaging" />
              </strong>
            </Col>
          </Row>
          <Row>
            <Col>{product.unite_de_vente}</Col>
          </Row>
        </td>
        <td className="text-nowrap">
          {(this.props.user.role === Roles.SALES_REP ||
            this.props.user.role === Roles.ADMIN) && (
            <>
              <h5 className="text-dark">
                <strong>
                  <span
                    className={
                      this.props.mode === "margin_preview" && !isOutOfPLV
                        ? "me-1 text-decoration-line-through"
                        : "me-1"
                    }
                  >
                    {PriceUtil.formatEuro(PriceUtil.priceHt(product, 1, 2))}
                  </span>
                  {this.props.mode === "margin_preview" && !isOutOfPLV && (
                    <span className="me-1">
                      {PriceUtil.formatEuro(product.preview_prix_ht)}
                    </span>
                  )}
                  <small>
                    <FormattedMessage id="Excl.Tax" />
                  </small>
                </strong>
              </h5>
              <h6 className="text-secondary">
                <small>
                  <span
                    className={
                      this.props.mode === "margin_preview" && !isOutOfPLV
                        ? "me-1 text-decoration-line-through"
                        : "me-1"
                    }
                  >
                    {PriceUtil.formatEuro(PriceUtil.priceTtc(product, 1, 2))}
                  </span>
                  {this.props.mode === "margin_preview" && !isOutOfPLV && (
                    <span className="me-1">
                      {PriceUtil.formatEuro(product.preview_prix_ttc)}
                    </span>
                  )}
                  <small>
                    <FormattedMessage id="Incl.Tax" />
                  </small>
                  {(this.props.user.role === Roles.SALES_REP ||
                    this.props.user.role === Roles.ADMIN) &&
                    product.lpp_code &&
                    product.lpp_amount > 0 && (
                      <PopoverHelper
                        title={<FormattedMessage id="Lpp.Support" />}
                        placement="right"
                      >
                        <ul className="list-group list-group-flush m-0">
                          <li className="list-group-item">
                            <FormattedMessage id="Lpp.Code" />:{" "}
                            <strong>{product.lpp_code}</strong>
                          </li>
                          {product.lpp_amount > 0 && (
                            <>
                              <li className="list-group-item">
                                <FormattedMessage id="Lpp.Amount" />:{" "}
                                <strong>
                                  {PriceUtil.formatEuro(product.lpp_amount)}
                                </strong>
                              </li>
                              <li className="list-group-item">
                                <FormattedMessage
                                  id="Remains"
                                  placement="right"
                                />
                                :{" "}
                                <strong>
                                  {PriceUtil.formatEuro(
                                    remains < 0 ? 0 : remains,
                                  )}
                                </strong>
                              </li>
                            </>
                          )}
                        </ul>
                      </PopoverHelper>
                    )}
                </small>
              </h6>
              <h6 className="text-secondary">
                <small>
                  (<FormattedMessage id="VAT" />: {PriceUtil.tva(product) * 100}
                  %)
                </small>
              </h6>
            </>
          )}
          {this.props.user.role === Roles.CLIENT && (
            <h5 className="text-dark">
              <strong>
                <span className="me-1">
                  {PriceUtil.formatEuro(PriceUtil.priceTtc(product, 1, 2))}
                </span>
                <small>
                  <FormattedMessage id="Incl.Tax" />
                </small>
              </strong>
            </h5>
          )}
        </td>
        {(this.props.user.role === Roles.SALES_REP ||
          this.props.user.role === Roles.ADMIN) &&
          canManage &&
          this.props.user.preferencies.is_rate_visible && (
            <td className="text-nowrap">
              <div
                className={
                  this.props.mode === "margin_preview" &&
                  !isOutOfPLV &&
                  (product.preview_taux_marge || product.preview_taux_marque)
                    ? "d-none d-lg-block text-center col-1 p-0 m-0 text-decoration-line-through"
                    : "d-none d-lg-block text-center col-1 p-0 m-0"
                }
              >
                {marginValue}
              </div>
              {this.props.mode === "margin_preview" &&
                !isOutOfPLV &&
                (product.preview_taux_marge || product.preview_taux_marque) && (
                  <div>{previewMarginValue}</div>
                )}
            </td>
          )}
        {this.props.user.role === Roles.CLIENT && (
          <td className="align-middle">{quantity}</td>
        )}
        <td className="text-end">
          {(this.props.user.role === Roles.SALES_REP ||
            this.props.user.role === Roles.ADMIN) &&
            this.props.mode !== "margin_preview" &&
            menuAction}
          {this.props.user.role === Roles.CLIENT && addButton}
        </td>
      </tr>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    productsSettingsAdmin: state.productsSettingsAdmin,
    deskProducts: state.deskProducts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateFavorite: (data, successCallback) =>
      dispatch(addFavorite(data, successCallback)),
    onDeleteFavorite: (data, successCallback) =>
      dispatch(deleteFavorite(data, successCallback)),
    onAddProductsImg: (mercurialId, data, successCallback) =>
      dispatch(addProductsImgs(mercurialId, data, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(ProductRow));
