import React from "react";
import APIUrl from "../../../APIUrl";
import { Col, Card, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import ImageWithFallback from "../../sub/ImageWithFallback";
import MenuButton from "../../sub/bootstrap/MenuButton";

export default class DeskCategoryCard extends React.Component {
  goToCategoryProducts() {
    const { category, setState, scrollToTopDesk } = this.props;

    setState(
      {
        category_id: category._id,
        initialCategoryId: category._id,
      },
      scrollToTopDesk(),
    );
  }

  render() {
    const { category } = this.props;
    const imgSrc = `${APIUrl.getDeskCategoryImg}${category._id}?token=${APIUrl.jwtToken}`;

    return (
      <Col className="mb-4" key={category._id}>
        <Card bg={"light"} className={"cursor-pointer h-100"}>
          <Card.Body
            className="d-flex flex-column cursor-pointer"
            onClick={() => this.goToCategoryProducts()}
          >
            <ImageWithFallback
              src={imgSrc}
              className="cursor-pointer"
              thumbnail
            />
            <Row>
              <Col className="text-center ps-0 pe-0 mt-2">
                <small>
                  <strong>
                    <span>{category.name}</span>
                  </strong>
                </small>
              </Col>
            </Row>
          </Card.Body>
          <Card.Footer className="text-center">
            <MenuButton
              icon="eye"
              className="nohover"
              variant="light"
              size="sm"
              onClick={() => this.goToCategoryProducts()}
            >
              <FormattedMessage id="Consult.Products" />
            </MenuButton>
          </Card.Footer>
        </Card>
      </Col>
    );
  }
}
