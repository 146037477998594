import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import ConfirmationModal from "../../../../sub/modals/ConfirmationModal.js";
import AlertModal from "../../../../sub/modals/AlertModal.js";
import Paginator from "../../../../sub/Paginator";
import Util from "../../../../../util/Util.js";
import CollaboratorsTypesRow from "./CollaboratorTypesRow.js";
import CollaboratorTypesModal from "./CollaboratorTypesModal.js";
import MenuButton from "../../../../sub/bootstrap/MenuButton";
import TableToolbar from "../../../../sub/bootstrap/TableToolbar.js";
import { Col, Row } from "react-bootstrap";
import InputTextElement from "../../../../sub/InputTextElement.js";

class CollaboratorTypesTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
      collaboratorTypeFilter: "",
    };
    this.paginator = new Paginator(this);
  }

  openConfModal(title, content, successCallback) {
    this.setState({
      modal: (
        <ConfirmationModal
          title={title}
          onAccept={successCallback}
          onDecline={() => this.closeModal()}
        >
          {content}
        </ConfirmationModal>
      ),
    });
  }

  openErrorModal(title, content) {
    this.setState({
      modal: (
        <AlertModal
          variant="danger"
          size="lg"
          title={title}
          content={content}
          onClose={() => this.closeModal()}
          buttonLabel={<FormattedMessage id="Cancel" />}
        />
      ),
    });
  }

  openCollaboratorTypesModal(collaboratorType) {
    this.setState({
      modal: (
        <CollaboratorTypesModal
          collaboratorTypes={this.props.collaboratorTypes}
          collaboratorType={collaboratorType}
          closeModal={() => this.closeModal()}
        />
      ),
    });
  }

  closeModal() {
    this.setState({ modal: null });
  }

  areResultsFiltered = () => {
    if (!Util.emptyString(this.state.collaboratorTypeFilter)) {
      return true;
    } else {
      return false;
    }
  };

  resetSearchFields() {
    this.setState({
      collaboratorTypeFilter: "",
    });
  }

  render() {
    let { collaboratorTypes, collaborators, intl } = this.props;

    this.paginator.init(collaboratorTypes.length);

    let disableFormInput = this.paginator.paginationIndex !== 1 ? true : false;

    let collaboratorTypesRowNode = collaboratorTypes.map((collaboratorType) => {
      if (
        this.state.collaboratorTypeFilter &&
        this.state.collaboratorTypeFilter !== ""
      ) {
        let collaboratorTypeName = collaboratorType.name.toLowerCase();
        if (
          collaboratorTypeName.indexOf(
            this.state.collaboratorTypeFilter.toLowerCase(),
          ) === -1
        )
          return null;
      }

      if (!this.paginator.keep()) return null;

      return (
        <CollaboratorsTypesRow
          key={collaboratorType._id}
          collaboratorType={collaboratorType}
          collaboratorTypes={collaboratorTypes}
          collaborators={collaborators}
          openConfModal={(title, content, successCallback) =>
            this.openConfModal(title, content, successCallback)
          }
          openErrorModal={(title, content) =>
            this.openErrorModal(title, content)
          }
          openEditModal={(CollaboratorType) =>
            this.openCollaboratorTypesModal(CollaboratorType)
          }
        />
      );
    });

    return (
      <React.Fragment>
        {this.state.modal}
        <TableToolbar>
          <Row className="align-items-center">
            <Col>
              <InputTextElement
                type="text"
                placeholder={intl.formatMessage({ id: "Collaborators.Types" })}
                onChange={(e) => {
                  this.setState({ collaboratorTypeFilter: e.target.value });
                }}
                disabled={disableFormInput}
                value={this.state.collaboratorTypeFilter}
              />
            </Col>
            <Col>
              <MenuButton
                onClick={() => this.resetSearchFields()}
                hover={
                  this.areResultsFiltered() &&
                  !disableFormInput && <FormattedMessage id="Remove.Filter" />
                }
                variant={
                  this.areResultsFiltered() ? "info" : "outline-secondary"
                }
                icon="filter"
                disabled={!this.areResultsFiltered() || disableFormInput}
              />
            </Col>
            <Col className="text-end">
              <MenuButton onClick={(e) => this.openCollaboratorTypesModal()}>
                <FormattedMessage id="Add.Collaborator.Type" />
              </MenuButton>
            </Col>
          </Row>
        </TableToolbar>

        <table className="table table-striped tablee4mad">
          <thead>
            <tr>
              <th className="col">
                <FormattedMessage id="Collaborators.Types" />
              </th>
              <th className="text-end">
                <FormattedMessage id="Actions" />
              </th>
            </tr>
          </thead>
          <tbody>{collaboratorTypesRowNode}</tbody>
        </table>

        {this.paginator.render()}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    //
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(CollaboratorTypesTable));
