import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import {
  deleteProductFromCatalog,
  sortProductFromCatalog,
  updateDeskProduct,
} from "../../../../actions/settings/deskCategories/admin/deskProducts.js";
import { getProductsByMercurialIdAdmin } from "../../../../actions/products/products.js";
import ProductsParserUtil from "../../../../util/ProductsParserUtil.js";
import MenuButton from "../../../sub/bootstrap/MenuButton";
import MercurialStatus from "../../../../enums/MercurialStatus.js";
import { FormattedMessage } from "react-intl";
import PopoverHelper from "../../../sub/bootstrap/PopoverHelper.js";

class DeskProductsCatalogProductRow extends React.Component {
  getCatalog() {
    return this.props.mercurials.find(
      (m) => m._id === this.props.product.mercurial_id,
    );
  }

  render() {
    const {
      product,
      productsFromCatalog,
      onSort,
      deleteProduct,
      lastSortIndex,
    } = this.props;

    let formats = "-";
    if (product.formats && product.formats.length > 0) {
      formats = (
        <ul className="m-0">
          {product.formats.map((format) => (
            <li key={format._id}>{format.format.trim()}</li>
          ))}
        </ul>
      );
    }

    const disabledUp = product.sortIndex === 0;
    const disabledDown =
      product.sortIndex >= productsFromCatalog.length - 1 ||
      product.formats[product.formats.length - 1].sortIndex >= lastSortIndex;

    const catalog = this.getCatalog();

    const catalogName = catalog ? catalog.name : "-";

    const now = new Date();
    const hasExpired =
      catalog.end_date &&
      (new Date(catalog.start_date) > now || new Date(catalog.end_date) < now);

    const isCatalogInactive =
      hasExpired || catalog.status === MercurialStatus.INACTIVE;

    return (
      <tr key={product._id}>
        <td
          className={
            isCatalogInactive ? "text-muted align-middle" : "align-middle"
          }
        >
          {catalogName}{" "}
          {isCatalogInactive && (
            <span>
              (<FormattedMessage id="Inactive" />)
              <PopoverHelper variant="danger">
                <FormattedMessage id="Hint.Product.From.Catalog.Inactive" />
              </PopoverHelper>
            </span>
          )}
        </td>
        <td
          className={
            isCatalogInactive ? "text-muted align-middle" : "align-middle"
          }
        >
          {product.famille}
        </td>
        <td
          className={
            isCatalogInactive ? "text-muted align-middle" : "align-middle"
          }
        >
          {ProductsParserUtil.getDesignation(product)}
        </td>
        <td
          className={
            isCatalogInactive ? "text-muted align-middle" : "align-middle"
          }
        >
          {formats}
        </td>
        <td className=" text-end align-middle">
          <MenuButton
            icon="arrow-up"
            onClick={() => !disabledUp && onSort(product, "up")}
            disabled={disabledUp}
            className="me-1"
          />
          <MenuButton
            icon="arrow-down"
            onClick={() => !disabledDown && onSort(product, "down")}
            disabled={disabledDown}
            className="me-1"
          />
          <MenuButton icon="trash" onClick={() => deleteProduct(product)} />
        </td>
      </tr>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    products: state.products,
    auditDeskSettings: state.auditDeskSettings,
    mercurials: state.mercurials,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSortProductFromCatalog: (data, successCallback) =>
      dispatch(sortProductFromCatalog(data, successCallback)),
    onDeleteProductFromCatalog: (data, successCallback) =>
      dispatch(deleteProductFromCatalog(data, successCallback)),
    onUpdateDeskProduct: (data) => dispatch(updateDeskProduct(data)),
    onGetProductsByMercurialIdAdmin: (mercurialId, getAllProducts) =>
      dispatch(getProductsByMercurialIdAdmin(mercurialId, getAllProducts)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(DeskProductsCatalogProductRow));
