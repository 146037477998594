import React from "react";
import { connect } from "react-redux";
import { injectIntl, FormattedMessage } from "react-intl";
import Roles from "../../enums/Roles";
import MercurialStatus from "../../enums/MercurialStatus";
import DateUtil from "../../util/DateUtil";
import Icon from "../sub/Icon.js";
import MenuButton from "../sub/bootstrap/MenuButton";
import Util from "../../util/Util";
import ActionMenu from "../sub/ActionMenu";
import PopoverHelper from "../sub/bootstrap/PopoverHelper";
class PrescriberRow extends React.Component {
  countPatients(client, patients) {
    if (!client || !patients) return 0;

    let patientFieldName = "prescribers_ids";
    if (client.client_type === "helper") patientFieldName = "helpers_ids";

    let count = 0;
    outer: for (let p of patients) {
      for (let pId of p[patientFieldName]) {
        if (pId === client._id) {
          count++;
          continue outer;
        }
      }
    }

    return count;
  }

  getMercurialName(client) {
    for (let m of this.props.mercurials) {
      if (
        m._id === client.mercurial_Id &&
        m.status === MercurialStatus.ACTIVE
      ) {
        let version = "";
        if (m.version !== 1) version = "(v" + m.version + ")";
        return m.name + " " + version;
      }
    }

    return "";
  }

  getMercurialDate(client) {
    let date = new Date();
    for (let m of this.props.mercurials) {
      if (m._id === client.mercurial_Id && !this.getMercurialStatus(client)) {
        date = DateUtil.toDate(m.end_date);
      }
    }
    return date;
  }

  getMercurialStatus(client) {
    let isActive = true;
    for (let m of this.props.mercurials) {
      let isOutDated = m.end_date && new Date(m.end_date) < new Date();
      if (m._id === client.mercurial_Id && isOutDated) {
        isActive = false;
      }
    }
    return isActive;
  }

  getType = (client) => {
    if (!client || !client.client_type) return "";
    if (client.client_type === "helper")
      return this.props.intl.formatMessage({ id: "Individual" });
    if (client.client_type === "prescriber")
      return this.props.intl.formatMessage({ id: "Professional" });
    if (client.client_type === "desk_user")
      return this.props.intl.formatMessage({ id: "Desk" });
  };

  getClientLabel = (client) => {
    const typePrescriber = this.props.prescriberTypes.find(
      (t) => t._id === client.type,
    );
    if (typePrescriber) {
      return typePrescriber.name;
    }
  };

  render() {
    const { client, patients, user } = this.props;

    if (!client) return null;

    const patientsNbr = this.countPatients(client, patients);

    // https://stackoverflow.com/questions/37308719/react-component-wait-for-required-props-to-render
    // https://zaiste.net/posts/javascript-destructuring-assignment-default-values/
    const { enabled: crmEnabled = false, software: crmSoftware = null } = this
      .props.company.crm
      ? this.props.company.crm
      : {};

    const canManage =
      !user.supervisor_id || (user.supervisor_id && user.has_write_permission);
    const menuItems = [];

    menuItems.push(
      {
        id: "edit" + client._id,
        icon: "pen-to-square",
        action: () => this.props.edit(client),
        text: <FormattedMessage id="Modify" />,
      },
      user.role !== Roles.CLIENT &&
        canManage && {
          id: "delete" + client._id,
          icon: "trash",
          disabled: client.client_type === "desk_user",
          action: () => this.props.delete(client),
          text: <FormattedMessage id="Delete" />,
        },
    );

    const menuAction = <ActionMenu items={menuItems} />;

    return (
      <React.Fragment>
        <tr key={client._id}>
          <td className="align-middle">
            {Util.formatFullName(client.first_name, client.name)}
          </td>
          <td className="align-middle">
            {this.getType(client)}
            {client.client_type === "desk_user" && (
              <PopoverHelper placement="right">
                <FormattedMessage id="Hint.Deskuser" />
              </PopoverHelper>
            )}
          </td>
          <td className="align-middle">{this.getClientLabel(client)}</td>
          <td className="align-middle">
            {client.address} {client.postal_code}{" "}
            {client.city && client.city.toUpperCase()}
          </td>
          {!this.props.limit && (
            <td className="align-middle text-center">
              {client.client_type !== "desk_user" && (
                <MenuButton
                  icon="person-cane"
                  onClick={() => this.props.edit(client, "clientPatients")}
                >
                  {patientsNbr.toString()}
                </MenuButton>
              )}
            </td>
          )}
          {!this.props.limit &&
            (this.props.user.access_pharmamad ||
              this.props.user.access_module_store) &&
            (this.getMercurialStatus(client) ? (
              <td className="align-middle">{this.getMercurialName(client)}</td>
            ) : (
              <td className="align-middle">
                <Icon
                  icon="triangle-exclamation"
                  className={"text-danger me-2"}
                  hover={
                    <>
                      <FormattedMessage
                        id="Mercurial.Outdated"
                        values={{ date: this.getMercurialDate(client) }}
                      />
                    </>
                  }
                />
                <b className="text-danger">{this.getMercurialName(client)}</b>
              </td>
            ))}
          {user.role !== Roles.CLIENT && crmEnabled && (
            <td className="align-middle text-center">
              <Icon
                icon={
                  client.crm_id ? "circle-check" : "fa-regular fa-circle-xmark"
                }
                size="xl"
                id={"synced_with_crm_" + client._id}
                className={client.crm_id ? "text-success" : "text-danger"}
                onClick={(e) => this.props.displayAPIModal(client)}
                hover={
                  <>
                    <FormattedMessage
                      id={
                        client.crm_id ? "API.CRM.Sync.Ok" : "API.CRM.Sync.Nok"
                      }
                      values={{ crmSoftware: crmSoftware }}
                    />
                  </>
                }
              />
            </td>
          )}
          {!this.props.limit && user.role !== Roles.CLIENT && (
            <td className="text-center">{menuAction}</td>
          )}
        </tr>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    patients: state.patients,
    prescriberTypes: state.prescriberTypes,
    mercurials: state.mercurials,
    company: state.company,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(PrescriberRow));
