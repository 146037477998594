import React from "react";
import { Row, Col, ListGroup, Badge, Accordion, Image } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import DeskProductCard from "./DeskProductCard";
import Icon from "../../sub/Icon";
import APIUrl from "../../../APIUrl";
import MenuButton from "../../sub/bootstrap/MenuButton";
export default class DeskProductRelatedCategories extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      categoriesVisited: !this.props.isReadOnly
        ? [...this.props.categoriesVisited, this.props.category_id]
        : [],
      category_id: this.props.category_id || this.props.deskCategories[0]?._id,
      isRelatedCategoriesOpened: true,
      isOtherCategoriesOpened: false,
    };
  }

  onChangeCategory(category, callback) {
    const updatedCategoriesVisited = !this.props.isReadOnly
      ? [...this.state.categoriesVisited, category._id]
      : [];

    const finalCallback = () => {
      if (this.props.isReadOnly) return;

      this.props.setState(
        {
          categoriesVisited: updatedCategoriesVisited,
          category_id: category._id,
        },
        callback,
      );
    };

    this.setState(
      {
        categoriesVisited: updatedCategoriesVisited,
        category_id: category._id,
      },
      finalCallback,
    );
  }

  getListGroupCategories(categories) {
    return categories.map((c) => {
      const countSelectedProductInCategory = this.props.deskStore
        ? Object.keys(this.props.deskStore).filter(
            (id) =>
              this.props.deskProducts.find((p) => p._id === id).category_id ===
              c._id,
          ).length
        : 0;
      return (
        <ListGroup.Item
          key={`category-${c._id}`}
          action
          className={
            this.state.categoriesVisited.find((visited) => visited === c._id) ||
            this.state.category_id === c._id
              ? "accordion-selected p-1"
              : "p-1"
          }
          onClick={() => this.onChangeCategory(c, this.props.scrollToTopDesk())}
        >
          <Row className="align-items-center">
            <Col md={2} className="pe-0">
              <Image
                src={`${APIUrl.getDeskCategoryImg}${c._id}?token=${APIUrl.jwtToken}`}
                thumbnail
                className={
                  this.state.categoriesVisited.find(
                    (visited) => visited === c._id,
                  ) && "border border-success"
                }
                alt=""
                onError={(e) =>
                  (e.target.src = `${APIUrl.rootUrl}/img/no_image_512.png`)
                }
              />
            </Col>
            <Col className="text-nowrap ps-1 pe-0">
              <small>{c.name}</small>
            </Col>
            {countSelectedProductInCategory > 0 && (
              <Col className="px-0">
                <Badge bg="danger" className="ms-2">
                  {countSelectedProductInCategory}
                </Badge>
              </Col>
            )}
            {(this.state.categoriesVisited.find(
              (visited) => visited === c._id,
            ) ||
              countSelectedProductInCategory > 0) && (
              <Col className="col-auto">
                <Icon
                  className="text-success pe-1"
                  icon="circle-check"
                  aria-hidden="true"
                />
              </Col>
            )}
          </Row>
        </ListGroup.Item>
      );
    });
  }

  render() {
    const {
      product,
      deskCategories,
      deskProducts,
      deskStore,
      setOngoingState,
      scrollToTopDesk,
      openRelatedProductDetail,
      openCommentModal,
      isReadOnly,
    } = this.props;

    let relatedcategoriesId = product && [...product.relatedCategories];
    relatedcategoriesId?.unshift(product.category_id);

    const relatedCategories =
      relatedcategoriesId &&
      deskCategories
        .filter((c) => relatedcategoriesId.includes(c._id))
        .sort(
          (a, b) =>
            relatedcategoriesId.indexOf(a._id) -
            relatedcategoriesId.indexOf(b._id),
        );

    const otherCategories =
      relatedcategoriesId &&
      deskCategories.filter((c) => !relatedcategoriesId.includes(c._id));

    const categoryProducts = deskProducts.filter(
      (p) => p.category_id === this.state.category_id,
    );

    const nextCategories =
      relatedCategories &&
      relatedCategories.filter(
        (c) => !this.state.categoriesVisited.includes(c._id),
      );

    return (
      <>
        <Row>
          <Col className="p-0 pt-3 pb-3 bg-light rounded">
            {!isReadOnly ? (
              <Accordion defaultActiveKey="related-categories">
                <Accordion.Item eventKey="related-categories">
                  <Accordion.Header>
                    <strong>
                      <FormattedMessage id="Categories.Associated" />
                    </strong>
                  </Accordion.Header>
                  <Accordion.Body className="p-1 bg-light">
                    <ListGroup id="ListGroupRelatedCategories">
                      {this.getListGroupCategories(relatedCategories)}
                    </ListGroup>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="other-categories">
                  <Accordion.Header>
                    <strong>
                      <FormattedMessage id="Other.Categories" />
                    </strong>
                  </Accordion.Header>
                  <Accordion.Body className="p-1 bg-light">
                    <ListGroup id="ListGroupOtherCategories">
                      {this.getListGroupCategories(otherCategories)}
                    </ListGroup>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            ) : (
              <Accordion defaultActiveKey="categories">
                <Accordion.Item eventKey="categories">
                  <Accordion.Header>
                    <strong>
                      <FormattedMessage id="Categories.Desk" />
                    </strong>
                  </Accordion.Header>
                  <Accordion.Body className="p-1 bg-light">
                    <ListGroup id="ListGroupCategories">
                      {this.getListGroupCategories(deskCategories)}
                    </ListGroup>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            )}
          </Col>
          <Col md={9} className="pt-3">
            <Row md={4}>
              {categoryProducts.map((p) => (
                <DeskProductCard
                  key={p._id}
                  scrollToTopDesk={() => scrollToTopDesk()}
                  setOngoingState={(state) =>
                    openRelatedProductDetail(state.product_id, "category")
                  }
                  product={p}
                  deskStore={deskStore}
                  openCommentModal={(product, deskStoreProduct) =>
                    openCommentModal(product, deskStoreProduct)
                  }
                />
              ))}
            </Row>
          </Col>
        </Row>
        <Row className="d-flex mt-3">
          <Col className="text-end" md={12}>
            {nextCategories && nextCategories.length > 0 && (
              <MenuButton
                icon="arrow-circle-right"
                className="me-3"
                onClick={() => {
                  this.onChangeCategory(
                    nextCategories[0],
                    this.setState(
                      {
                        isRelatedCategoriesOpened: true,
                        isOtherCategoriesOpened: false,
                      },
                      scrollToTopDesk(),
                    ),
                  );
                }}
              >
                <FormattedMessage id="Category.Next" />
              </MenuButton>
            )}

            {!isReadOnly && (
              <MenuButton
                icon="shopping-cart"
                variant="warning"
                onClick={() => setOngoingState({ modal: null, panier: true })}
                disabled={!deskStore || Object.keys(deskStore).length === 0}
              >
                <FormattedMessage id="End.Audit" />
                <Badge bg="danger" className="ms-2">
                  {Object.keys(deskStore).length}
                </Badge>
              </MenuButton>
            )}
          </Col>
        </Row>
      </>
    );
  }
}
