import CustomLabel from "../sub/CustomLabel";
import { useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import Util from "../../util/Util";
import {
  checkIfEmailIsValid,
  checkIfUsernameExists,
} from "../../actions/user/user";
import { updPatientAdmin } from "../../actions/patients/patients";
import { addClient } from "../../actions/clients/clients";
import InputLength from "../../enums/InputLength";
import InputTextElement from "../sub/InputTextElement";
import SwitchToggle from "../sub/SwitchToggle";
import MenuButton from "../sub/bootstrap/MenuButton";

function PatientAccountModal({
  patient,
  closeModal,
  company,
  intl,
  onCheckIfUsernameExists,
  onCheckIfEmailIsValid,
  onUpdPatientAdmin,
  onAddClient,
}) {
  const [email, setEmail] = useState(patient.email || "");
  const [emailError, setEmailError] = useState("");

  const [username, setUsername] = useState("");
  const [usernameError, setUsernameError] = useState("");

  const [mercurial_Id] = useState("");
  const [notifyByEmail, setNotifyByEmail] = useState(false);

  const onChange = (field, value) => {
    if (field === "username") {
      setUsernameError("");
      setUsername(value);

      if (Util.emptyString(value))
        return setUsernameError(<FormattedMessage id="Empty.Username" />);

      const checkUsername = Util.isValidUserName(value, 3, 20);

      if (checkUsername instanceof Object === true) {
        return setUsernameError(
          <FormattedMessage
            id="Invalid.Username"
            values={{
              minLength: checkUsername.minLength,
              maxLength: checkUsername.maxLength,
            }}
          />,
        );
      } else {
        setUsernameError("");
      }

      onCheckIfUsernameExists(
        value,
        () =>
          setUsernameError(
            <FormattedMessage
              id="Username.Already.Exists"
              values={{ username: value }}
            />,
          ),
        () => setUsernameError(""),
      );
    } else if (field === "email") {
      if (Util.emptyString(value)) setEmailError("");
      setEmail(value);
      if (!Util.emptyString(value) && !Util.isEmail(value)) {
        setEmailError(<FormattedMessage id="Invalid.Email.Error" />);
      } else if (!Util.emptyString(value) && Util.isEmail(value)) {
        onCheckIfEmailIsValid(
          value,
          (response) => {
            setEmailError("");
          },
          (response) => {
            setEmailError(
              <span>
                <FormattedMessage id="Invalid.Email.Error" /> (
                {response.data.reason})
              </span>,
            );
          },
        );
      }
    }
  };

  const isDisabled = () => {
    return (
      Util.emptyString(email) ||
      Util.emptyString(username) ||
      emailError ||
      usernameError
    );
  };

  const onSubmit = () => {
    const newClient = {
      first_name: patient.first_name,
      name: patient.name,
      address: patient.address,
      addressAdditionnal: patient.addressAdditionnal,
      postal_code: patient.postal_code,
      city: patient.city,
      phone: patient.phone,
      mobile: patient.mobile,
      email: email,
      username: username,
      client_type: "patient",
      account_type: "0",
      notifyByEmail: notifyByEmail,
      patient_id: patient._id,
    };

    if (mercurial_Id !== "") newClient.mercurial_Id = mercurial_Id;

    const successCallback = (client) => {
      const updatedPatient = {
        _id: patient._id,
        user_id: client._id,
        email: email,
      };

      onUpdPatientAdmin(updatedPatient, closeModal());
    };

    if (patient.user_id.length === 0)
      return onAddClient(newClient, successCallback);
  };

  return (
    <Modal
      show={true}
      onHide={() => closeModal()}
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage id="Patient.Account" />
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form.Group as={Row} className="mb-3 align-items-center">
          <CustomLabel
            label={intl.formatMessage({ id: "Email" })}
            htmlFor="email"
            displayAsCol
            required
          />
          <Col md={7}>
            <InputTextElement
              type="text"
              id="email"
              autoComplete="off"
              maxLength={InputLength.EMAIL}
              value={email}
              onChange={(e) => onChange("email", e.target.value)}
            />
            <div className="text-danger">
              <small>{emailError}</small>
            </div>
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3 align-items-center">
          <CustomLabel
            label={intl.formatMessage({ id: "Username.Connexion" })}
            htmlFor="username"
            displayAsCol
            required
          />
          <Col md={7}>
            <Form.Group as={Row} className="align-items-center mb-0">
              <Col>
                <InputTextElement
                  type="text"
                  id="username"
                  autoComplete="off"
                  maxLength={InputLength.NAME}
                  value={username}
                  onChange={(e) =>
                    onChange("username", e.target.value.toLowerCase())
                  }
                />
              </Col>
              <Col md={4}>-{company.url}</Col>
            </Form.Group>
            <div className="text-danger">
              <small>{usernameError}</small>
            </div>
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3 align-items-center">
          <CustomLabel
            label={intl.formatMessage({
              id: "Send.Mail.Notify.Created.Account",
            })}
            htmlFor="notifyByEmail"
            displayAsCol
          />
          <Col md={7}>
            <SwitchToggle
              htmlFor={"notifyByEmail"}
              id="notifyByEmail"
              onChange={(e) => setNotifyByEmail(e)}
              checked={notifyByEmail}
            />
          </Col>
        </Form.Group>
      </Modal.Body>

      <Modal.Footer>
        <MenuButton variant="secondary" onClick={() => closeModal()}>
          <FormattedMessage id="Cancel" />
        </MenuButton>
        <MenuButton onClick={() => onSubmit()} disabled={isDisabled()}>
          <FormattedMessage id="Update" />
        </MenuButton>
      </Modal.Footer>
    </Modal>
  );
}

const mapStateToProps = (state) => {
  return {
    company: state.company,
    mercurials: state.mercurials,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCheckIfUsernameExists: (username, existsCallback, noExistsCallback) =>
      dispatch(
        checkIfUsernameExists(username, existsCallback, noExistsCallback),
      ),
    onCheckIfEmailIsValid: (email, isValidCallback, notValidCallback) =>
      dispatch(checkIfEmailIsValid(email, isValidCallback, notValidCallback)),
    onUpdPatientAdmin: (patient, successCallback) =>
      dispatch(updPatientAdmin(patient, successCallback)),
    onAddClient: (client, successCallback) =>
      dispatch(addClient(client, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(PatientAccountModal));
