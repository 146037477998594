import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import DeskProductCard from "../DeskProductCard";
import DeskCategoryCard from "../DeskCategoryCard";
import DeskProductDetail from "../DeskProductDetail";
import DeskProductPanier from "../DeskProductPanier";
import APIUrl from "../../../../APIUrl";
import DateUtil from "../../../../util/DateUtil";
import { Row, Col, Container, Modal, Badge, Alert } from "react-bootstrap";
import MenuButton from "../../../sub/bootstrap/MenuButton";
import ConfirmationModal from "../../../sub/modals/ConfirmationModal";
import DeskProductRelatedProductsModal from "../DeskProductRelatedProductsModal";
import DeskProductRelatedCategories from "../DeskProductRelatedCategories";
import Icon from "../../../sub/Icon";
import DeskProductComment from "../DeskProductComment";
import ImageWithFallback from "../../../sub/ImageWithFallback";
import DeskProductEstimate from "../DeskProductEstimate";
import DeskProductRelatedCatalogProducts from "../DeskProductRelatedCatalogProducts";
import { getProductsByMercurialIdAdmin } from "../../../../actions/products/products";
import Util from "../../../../util/Util";

class DeskOngoingPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      product_id: this.props.product_id,
      panier: null,
      estimate: null,
      modal: null,
      relatedCategories: false,
      initialProductId: this.props.product_id,
    };
  }

  componentDidMount() {
    if (this.props.isBackup) {
      this.setState({
        panier: null,
        product_id: null,
        relatedCategories: true,
      });
    }

    this.getProductsFromCatalog();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.deskStore !== this.props.deskStore) {
      this.getProductsFromCatalog();
    }
  }

  getProductsFromCatalog() {
    if (this.props.deskStore) {
      const productsCleanIds = [];
      for (const _productId in this.props.deskStore) {
        productsCleanIds.push(_productId);
      }

      const mercurialsIds = [];

      for (const id of productsCleanIds) {
        const product = this.props.deskProducts.find((p) => p._id === id);
        for (const p of product.productsFromCatalog) {
          if (p.mercurial_id && !mercurialsIds.includes(p.mercurial_id)) {
            mercurialsIds.push(p.mercurial_id);
          }
        }
      }

      if (mercurialsIds.length !== 0) {
        for (const id of mercurialsIds) {
          this.props.onGetProductsByMercurialIdAdmin(id);
        }
      }
    }
  }

  setOngoingState(state) {
    this.setState(state);
  }

  scrollToTopDesk(elementId) {
    elementId =
      typeof elementId !== "undefined" ? elementId : "DeskOngoingPage";
    document.getElementById(elementId).scrollIntoView({
      behavior: "smooth",
    });
  }

  backToCategory(categoryId) {
    this.setOngoingState({
      product: null,
      product_id: null,
      category_id: categoryId,
    });
    !this.props.isReadOnly && this.props.setState({ category_id: categoryId });
    this.scrollToTopDesk("DeskOngoingPage");
  }

  closeModal() {
    this.setState({ modal: null });
  }

  openConfirmationModal(title, content, successCallback) {
    this.setState({
      modal: (
        <ConfirmationModal
          title={title}
          onAccept={successCallback}
          onDecline={() => this.closeModal()}
        >
          {content}
        </ConfirmationModal>
      ),
    });
  }

  openCommentModal(product, deskStoreProduct) {
    this.setState({
      modal: (
        <DeskProductComment
          product={product}
          deskStoreProduct={deskStoreProduct}
          onDeskStore={(product, state, callback) =>
            this.props.onDeskStore(product, state, callback)
          }
          closeModal={() => this.closeModal()}
        />
      ),
    });
  }

  openDeskProductRelatedProductsModal(product) {
    this.setState({
      modal: (
        <DeskProductRelatedProductsModal
          product={product}
          deskProducts={this.props.deskProducts}
          deskStore={this.props.deskStore}
          closeModal={() => this.closeModal()}
          setOngoingState={(state) => this.setOngoingState(state)}
          scrollToTopDesk={() => this.scrollToTopDesk()}
          openRelatedProductDetail={(productId) =>
            this.openRelatedProductDetail(product, productId)
          }
        />
      ),
    });
  }

  openRelatedProductDetail(mainProduct, productId) {
    this.setState({
      modal: (
        <Modal
          show={true}
          onHide={() => this.closeModal()}
          backdrop={"static"}
          size={"xl"}
        >
          <Modal.Body>
            <DeskProductDetail
              product={this.props.deskProducts.find((p) => p._id === productId)}
              product_id={mainProduct._id}
              deskCategories={this.props.deskCategories}
              deskStore={this.props.deskStore}
              deskProducts={this.props.deskProducts}
              setState={(state) => this.props.setState(state)}
              setOngoingState={(state) => {
                this.setOngoingState(state);
              }}
              scrollToTopDesk={() => this.scrollToTopDesk()}
              initialCategoryId={this.props.initialCategoryId}
              initialProductId={this.state.initialProductId}
              backToCategory={(categoryId) => this.backToCategory(categoryId)}
              onDeskStore={(product, state, callback) => {
                this.props.onDeskStore(product, state, callback);
              }}
              delDeskStore={(product, state, callback) => {
                this.props.delDeskStore(product, state, callback);
              }}
              openDeskProductRelatedProductsModal={() =>
                this.openDeskProductRelatedProductsModal(
                  this.props.deskProducts.find(
                    (p) => p._id === mainProduct._id,
                  ),
                )
              }
              isRelatedProduct={true}
            />
          </Modal.Body>
        </Modal>
      ),
    });
  }

  openRelatedCatalogProductsModal(deskStoreProduct, productToSwap = null) {
    this.setState({
      modal: (
        <DeskProductRelatedCatalogProducts
          deskCart={this.props.deskCart}
          deskStoreProduct={deskStoreProduct}
          products={this.props.products}
          productToSwap={productToSwap}
          addToCart={(products, callback) =>
            this.props.addToCart(products, callback)
          }
          deleteFromCart={(products, callback) =>
            this.props.deleteFromCart(products, callback)
          }
          closeModal={() => this.closeModal()}
        />
      ),
    });
  }

  render() {
    const {
      patient,
      deskProducts,
      deskCategories,
      deskStore,
      deskCart,
      category_id,
      prescribers,
      helpers,
      helper_id,
      prescriber_id,
      close,
      categoriesVisited,
      generalSettings,
      user,
      delDeskStore,
      setState,
      addAudit,
      templateCerfa,
      template,
      templateEstimate,
      initialCategoryId,
      mandatoryCategoryId,
      onDeskStore,
      isLoading,
      updateCartProductQuantity,
      isReadOnly,
    } = this.props;

    const helper =
      helpers?.length !== 0 && helpers?.find((h) => h._id === helper_id);
    const prescriber =
      prescribers?.length !== 0 &&
      prescribers?.find((p) => p._id === prescriber_id);

    const date_today = DateUtil.DateTime().now().toLocaleString();
    const category = category_id
      ? deskCategories.find((c) => c._id === category_id)
      : {};
    const deskStoreLength = deskStore && Object.keys(deskStore).length;

    return (
      <Container fluid>
        <Modal
          show={true}
          onHide={() => close()}
          backdrop="static"
          keyboard={false}
          size="xl"
          id="DeskOngoingPage"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <FormattedMessage
                id={isReadOnly ? "Catalog.MAD" : "Audit.Desk"}
              />
            </Modal.Title>
          </Modal.Header>

          <Modal.Body className="pt-0" style={{ backgroundColor: "#e9e9e9" }}>
            {!isReadOnly && (
              <Row className="border-bottom border-secondary shadow-sm p-3 bg-white d-flex align-items-center mb-4">
                <Col md={2} className="pe-0">
                  {generalSettings.logo && (
                    <ImageWithFallback
                      src={`${APIUrl.getGeneralSettingsLogo}${user.company_id}/${generalSettings._id}`}
                      rounded
                      style={{
                        maxWidth: "10em",
                        maxHeight: "3em",
                        borderRadius: "50%",
                      }}
                      alt="logo"
                    />
                  )}
                </Col>
                <Col className="ps-0">
                  <Row>
                    <Col>
                      <FormattedMessage id="Date" />: {date_today}
                      <br />
                      <FormattedMessage id="Patient" />:{" "}
                      {Util.formatFullName(patient.first_name, patient.name)}
                    </Col>
                  </Row>
                </Col>
                {(helper || prescriber) && (
                  <Col className="ps-0">
                    {helper && (
                      <Row>
                        <Col>
                          <FormattedMessage id="Helper" />:{" "}
                          {Util.formatFullName(helper.first_name, helper.name)}
                        </Col>
                      </Row>
                    )}
                    {prescriber && (
                      <Row>
                        <Col>
                          <FormattedMessage id="Prescriber" />:{" "}
                          {Util.formatFullName(
                            prescriber.first_name,
                            prescriber.name,
                          )}
                        </Col>
                      </Row>
                    )}
                  </Col>
                )}
                <Col md={5}>
                  {deskStoreLength && deskStoreLength > 0 ? (
                    <Row>
                      <Col className="text-end">
                        <Row>
                          <Col>
                            <MenuButton
                              icon="shopping-cart"
                              variant="warning"
                              disabled={!deskStoreLength || deskStoreLength < 1}
                              onClick={() =>
                                this.setState({ panier: true, estimate: null })
                              }
                            >
                              <FormattedMessage id="Selection.Header" />
                              <Badge bg="danger" className="ms-2">
                                {deskStoreLength}
                              </Badge>
                            </MenuButton>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  ) : (
                    <Row>
                      <Col className="text-end">
                        <Row>
                          <Col>
                            <MenuButton icon="shopping-cart" disabled={true}>
                              <FormattedMessage id="Selection.Header" />
                              <Badge bg="danger" className="ms-2">
                                {deskStoreLength}
                              </Badge>
                            </MenuButton>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
            )}

            <Row>
              <Col>
                {this.state.estimate ? (
                  <DeskProductEstimate
                    deskCart={deskCart}
                    setOngoingState={(state) => this.setOngoingState(state)}
                    scrollToTopDesk={() => this.scrollToTopDesk()}
                    addAudit={() => addAudit()}
                    template={template}
                    templateCerfa={templateCerfa}
                    templateEstimate={templateEstimate}
                    openConfirmationModal={(title, content, successCallback) =>
                      this.openConfirmationModal(
                        title,
                        content,
                        successCallback,
                      )
                    }
                    openRelatedCatalogProductsModal={(
                      deskStoreProduct,
                      productToSwap,
                    ) =>
                      this.openRelatedCatalogProductsModal(
                        deskStoreProduct,
                        productToSwap,
                      )
                    }
                    isLoading={isLoading}
                    updateCartProductQuantity={(product, number) =>
                      updateCartProductQuantity(product, number)
                    }
                  />
                ) : this.state.panier ? (
                  <DeskProductPanier
                    deskStore={deskStore}
                    deskCategories={deskCategories}
                    deskProducts={deskProducts}
                    delDeskStore={(product, state, callback) =>
                      delDeskStore(product, state, callback)
                    }
                    setState={(state) => setState(state)}
                    setOngoingState={(state) => this.setOngoingState(state)}
                    addAudit={() => addAudit()}
                    templateCerfa={templateCerfa}
                    template={template}
                    scrollToTopDesk={() => this.scrollToTopDesk()}
                    initialCategoryId={initialCategoryId}
                    mandatoryCategoryId={mandatoryCategoryId}
                    onDeskStore={(product, state, callback) =>
                      onDeskStore(product, state, callback)
                    }
                    openConfirmationModal={(title, content, successCallback) =>
                      this.openConfirmationModal(
                        title,
                        content,
                        successCallback,
                      )
                    }
                    openCommentModal={(product, deskStoreProduct) =>
                      this.openCommentModal(product, deskStoreProduct)
                    }
                    initialProductId={this.state.initialProductId}
                    openRelatedCatalogProductsModal={(
                      deskStoreProduct,
                      productToSwap,
                    ) =>
                      this.openRelatedCatalogProductsModal(
                        deskStoreProduct,
                        productToSwap,
                      )
                    }
                    deskCart={deskCart}
                    isLoading={isLoading}
                  />
                ) : this.state.relatedCategories ||
                  (isReadOnly && !this.state.product_id) ? (
                  <DeskProductRelatedCategories
                    product={deskProducts.find(
                      (p) => p._id === this.state.initialProductId,
                    )}
                    deskCategories={this.props.deskCategories}
                    deskProducts={this.props.deskProducts}
                    deskStore={this.props.deskStore}
                    category_id={
                      this.props.category_id || this.state.category_id
                    }
                    categoriesVisited={this.props.categoriesVisited}
                    setState={(state) => this.props.setState(state)}
                    setOngoingState={(state) => this.setOngoingState(state)}
                    scrollToTopDesk={() => this.scrollToTopDesk()}
                    openRelatedProductDetail={(productId) =>
                      this.setState({
                        product_id: productId,
                        relatedCategories: false,
                      })
                    }
                    openCommentModal={(product, deskStoreProduct) =>
                      this.openCommentModal(product, deskStoreProduct)
                    }
                    isReadOnly={isReadOnly}
                  />
                ) : this.state.product_id ? (
                  <DeskProductDetail
                    product={deskProducts.find(
                      (p) => p._id === this.state.product_id,
                    )}
                    product_id={this.state.product_id}
                    deskCategories={deskCategories}
                    deskStore={deskStore}
                    deskProducts={deskProducts}
                    setState={(state) => setState(state)}
                    setOngoingState={(state) => this.setOngoingState(state)}
                    scrollToTopDesk={() => this.scrollToTopDesk()}
                    initialCategoryId={initialCategoryId}
                    initialProductId={this.state.initialProductId}
                    mandatoryCategoryId={mandatoryCategoryId}
                    backToCategory={(categoryId) =>
                      this.backToCategory(categoryId)
                    }
                    onDeskStore={(product, state, callback) => {
                      if (!this.state.initialProductId)
                        this.setState({ initialProductId: product._id });
                      onDeskStore(product, state, callback);
                    }}
                    delDeskStore={(product, state, callback) =>
                      delDeskStore(product, state, callback)
                    }
                    openDeskProductRelatedProductsModal={() =>
                      this.openDeskProductRelatedProductsModal(
                        deskProducts.find(
                          (p) => p._id === this.state.product_id,
                        ),
                      )
                    }
                    isReadOnly={isReadOnly}
                  />
                ) : !category_id ? (
                  <div>
                    <Alert variant="info">
                      <Icon icon="info-circle" className="me-2" />
                      <FormattedMessage id="Needs.Concerns" />
                    </Alert>
                    <Row>
                      <Col className="text-center mb-3"></Col>
                    </Row>
                    <Row md={5}>
                      {deskCategories.map((category) => (
                        <DeskCategoryCard
                          key={category._id}
                          setState={(state) => {
                            this.props.setState(state);
                            if (Object.keys(this.props.deskStore).length) {
                              this.props.setState({
                                categoriesVisited: [
                                  ...categoriesVisited,
                                  category._id,
                                ],
                              });
                            }
                          }}
                          categoriesVisited={categoriesVisited}
                          scrollToTopDesk={() =>
                            this.scrollToTopDesk("DeskOngoingPage")
                          }
                          initialCategoryId={initialCategoryId}
                          category={category}
                        />
                      ))}
                    </Row>
                  </div>
                ) : (
                  <>
                    <Row>
                      <Col>
                        <Row>
                          <Col>
                            <Row>
                              <Col className="text-center">
                                <h1>
                                  <Badge bg="dark" className="mb-3">
                                    {category.name}
                                  </Badge>
                                </h1>
                              </Col>
                            </Row>
                          </Col>
                        </Row>

                        <Row md={5}>
                          {deskProducts
                            .filter((p) => p.category_id === category_id)
                            .map((product) => (
                              <DeskProductCard
                                key={product._id}
                                scrollToTopDesk={() => this.scrollToTopDesk()}
                                setOngoingState={(state) =>
                                  this.setOngoingState(state)
                                }
                                product={product}
                                deskStore={deskStore}
                              />
                            ))}
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} className="text-center">
                        <MenuButton
                          icon="arrow-circle-left"
                          onClick={() => this.backToCategory()}
                        >
                          <FormattedMessage id="Go.Back.Categories" />
                        </MenuButton>
                      </Col>
                    </Row>
                  </>
                )}
              </Col>
            </Row>
            {this.state.modal}
          </Modal.Body>
        </Modal>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    deskCategories: state.deskCategories,
    deskProducts: state.deskProducts,
    user: state.user,
    generalSettings: state.generalSettings,
    products: state.products,
    auditDeskSettings: state.auditDeskSettings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetProductsByMercurialIdAdmin: (mercurialId) =>
      dispatch(getProductsByMercurialIdAdmin(mercurialId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeskOngoingPage);
