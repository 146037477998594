import React from "react";
import { connect } from "react-redux";
import ArrayUtil from "../../../util/ArrayUtil";
import CustomLabel from "../CustomLabel";
import { FormattedMessage, injectIntl } from "react-intl";
import AlertModal from "./AlertModal";
import {
  Modal,
  Row,
  Col,
  Alert,
  FormSelect,
  Container,
  Badge,
} from "react-bootstrap";
import Icon from "../Icon.js";
import SwitchToggle from "../SwitchToggle";
import MenuButton from "../bootstrap/MenuButton";
import PopoverHelper from "../bootstrap/PopoverHelper";
import Notification from "../Notification";

class ImportExcelFileColumnsModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
      disabled: false,
      autoMatching: false,
      missingRequiredColumns: [],
      isFormVisible: true,
      isProgressBarVisible: false,
      maxErrorToDisplay: 50, // Define the number of errors (if any) to be displayed in a parsed file
      allowSameSelectValue: true, // If true, value of the same column can be associated to multiple selects
    };
  }

  formatValue(value) {
    let formattedValue = "" + value;

    if (formattedValue.length > 100)
      formattedValue = formattedValue.substring(0, 100) + "...";

    return formattedValue;
  }

  // Build selects to match columns in the file
  buildSelects() {
    if (!this.props.fileData[0]) return this.openDataIntegrityModal(null);

    let selects = [];
    let firstRowKeys = Object.keys(this.props.fileData[0]);
    let hintIcon = (
      <PopoverHelper>
        Chosissez manuellement la colonne du fichier que vous souhaitez associer
        à ce champ ou bien activez la détection automatique ci-dessus.
      </PopoverHelper>
    );
    let selectClass;

    for (let mappingKey of Object.keys(this.props.itemMapping)) {
      // Help user and pre-select the closest column (select default value)
      let matchColumn =
        this.state.missingRequiredColumns.indexOf(
          this.props.itemMapping[mappingKey],
        ) !== -1
          ? false
          : this.props.itemMapping[mappingKey];

      if (this.state.autoMatching) {
        if (!matchColumn) {
          hintIcon = (
            <PopoverHelper variant="danger">
              <FormattedMessage
                id="Mercurials.Auto.Detect.No.Match"
                values={{ column: this.props.itemMapping[mappingKey] }}
              />
            </PopoverHelper>
          );

          selectClass = "text-danger";
        } else {
          hintIcon = (
            <PopoverHelper variant="success" icon="circle-check">
              <FormattedMessage
                id="Mercurial.Column.Reference.Name"
                values={{ column: this.props.itemMapping[mappingKey] }}
              />
            </PopoverHelper>
          );

          selectClass = "text-success";
        }
      }

      var optionsNode = firstRowKeys.map((key) => {
        let value = this.props.fileData[0][key];

        let optionElement;

        if (
          !this.state.autoMatching ||
          (this.state.autoMatching && !matchColumn)
        ) {
          // When autoMatching did not found matching column or autoMatching is disabled, we build a select with the whole list of columns in options
          optionElement = (
            <option key={key + "-" + value} value={key}>
              [{key}] : {this.formatValue(value)}
            </option>
          );
        } else {
          // When autoMatching is enabled and worked well (column found) we don't need to display the whole list of columns.
          // Only displaying the matched one in select options
          if (key === this.props.itemMapping[mappingKey]) {
            optionElement = (
              <option key={key + "-" + value} value={key}>
                {this.formatValue(value)}
              </option>
            );
          }
        }

        return optionElement;
      });

      selects.push(
        <Row key={mappingKey} className="align-items-center mb-3">
          <CustomLabel
            label={this.props.itemMapping[mappingKey]}
            htmlFor={mappingKey}
            displayAsCol
          />
          <Col id={mappingKey} className="pe-0">
            <FormSelect
              key={Math.random()}
              className="column-select"
              defaultValue={
                this.state.autoMatching && matchColumn && matchColumn
              }
              onChange={(e) => this.manageSelectChange(e, mappingKey + "_hint")}
            >
              <option value="">
                {this.props.intl.formatMessage({ id: "Select" })}...
              </option>
              {optionsNode}
            </FormSelect>
          </Col>
          <Col className="col-auto ps-0" id={mappingKey + "_hint"}>
            {hintIcon}
          </Col>
          <div
            id={mappingKey + "_hint"}
            className={"col-sm-1 d-flex align-items-center " + selectClass}
          ></div>
        </Row>,
      );
    }

    return selects;
  }

  getSelColumn(key) {
    let famDiv = document.getElementById(key);
    let select = famDiv.firstChild;
    return select.options[select.selectedIndex].value;
  }

  async onComplete() {
    // Prevent post to backend if button to next step is disabled
    // (means that some column matching already needs to be done)
    if (this.state.disabled || !this.selectsAllHaveValues()) return false;

    this.setState({
      disabled: true,
    });

    var columns = {};

    // Store association between required column and matching column in the file (labels can be different if we didnt used automatching or partial automatching)
    for (let key of Object.keys(this.props.itemMapping)) {
      columns[key] = this.getSelColumn(key);
    }

    /*
        PERFORM INTEGRITY CHECKS UPON DATA BEFORE SENDING MERCURIAL TO THE BACKEND
        */
    let errorsFound = await this.props.checkDataIntegrity(
      columns,
      this.state.maxErrorToDisplay,
    );

    if (errorsFound.length === 0) {
      this.setState({
        isFormVisible: false,
        isProgressBarVisible: true,
      });

      return this.props.onComplete(columns);
    } else {
      this.openDataIntegrityModal(errorsFound);
    }
  }

  openDataIntegrityModal(dataProblems) {
    var errorModalTitle = <FormattedMessage id="Error" />;
    var errorModalContent = (
      <div>
        <div className="alert alert-danger">
          <div>
            <FormattedMessage id="File.Missing.Data" />
          </div>
          {dataProblems &&
            Object.values(dataProblems).length ===
              this.state.maxErrorToDisplay && (
              <div>
                <FormattedMessage
                  id="File.Error.Count"
                  values={{ count: Object.values(dataProblems).length }}
                />
              </div>
            )}
        </div>
        {dataProblems && (
          <table className="table table-striped tablee4mad">
            <thead>
              <tr className="d-flex">
                <th scope="col" className="col-1">
                  <FormattedMessage id="Line" />
                </th>
                <th scope="col" className="col-3">
                  <FormattedMessage id="Column.In.File" />
                </th>
                <th scope="col" className="col-3">
                  <FormattedMessage id="Target.Field" />
                </th>
                <th scope="col" className="col-5">
                  <FormattedMessage id="Hint" />
                </th>
              </tr>
            </thead>
            <tbody>
              {dataProblems.map((row, index) => {
                return (
                  <tr key={index} className="d-flex">
                    <td className="col-1">{row.numRow}</td>
                    <td className="col-3">{row.field}</td>
                    <td className="col-3">{row.targetField}</td>
                    <td className="col-5">{row.hint}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    );

    this.setState({
      modal: (
        <AlertModal
          size="xl"
          variant="danger"
          title={errorModalTitle}
          content={errorModalContent}
          onClose={() => this.props.closeModal()}
        />
      ),
    });
  }

  manageSelectChange(e, selectHint) {
    let hintElement = document.getElementById(selectHint);

    if (e.target.value !== "") {
      hintElement.classList.remove("text-danger");
      hintElement.classList.add("text-success");
    } else {
      hintElement.classList.add("text-danger");
      hintElement.classList.remove("text-success");
    }

    this.updateSelectOptions();
  }

  componentDidMount() {
    this.updateSelectOptions();
  }

  updateSelectOptions() {
    // if allowSameSelectValue is true in state (default) we allow the user to choose the same select multiple times for different values (ex: client ref and manufacturer ref linked to the same col in file)
    if (!this.state.allowSameSelectValue) {
      let selects = document.getElementsByClassName("column-select");

      for (let s of selects) {
        for (let i = 0; i < s.length; i++) {
          s.options[i].disabled = false;
        }
      }

      for (let s1 of selects) {
        var value1 = s1.value;

        for (let s2 of selects) {
          if (s1 === s2) continue;

          for (let i = 0; i < s2.length; i++) {
            if (s2.options[i].value !== "" && s2.options[i].value === value1)
              s2.options[i].disabled = true;
          }
        }
      }
    }
    this.checkDisableButton();
  }

  updateAutoMatching() {
    if (!this.state.autoMatching) {
      // When automatching is enabled, we check if all the required columns are found in the provided file
      let firstRowKeys = Object.keys(this.props.fileData[0]);
      this.setState({
        missingRequiredColumns: ArrayUtil.difference(
          Object.values(this.props.itemMapping),
          firstRowKeys,
        ),
      });
    } else {
      // We reset the columns to check if automatching is disabled
      this.setState({ missingRequiredColumns: [] });
    }

    !this.state.autoMatching &&
      Notification({
        type: "info",
        description: this.props.intl.formatMessage({
          id: "Step2.Read.1st.Line",
        }),
      });

    this.setState(
      { autoMatching: !this.state.autoMatching },
      this.updateSelectOptions,
    );
  }

  checkDisableButton() {
    let button = document.getElementById("submit-button");
    button.disabled = this.state.disabled || !this.selectsAllHaveValues();
  }

  selectsAllHaveValues() {
    let selects = document.getElementsByClassName("column-select");

    for (let s of selects) {
      if (!s.value || s.value === "") return false;
    }

    return true;
  }

  render() {
    // Prepare as many selects as required for the mapping
    let selects = this.buildSelects();

    // Split the selects on 2 displayed columns
    let selects1stHalf = [];
    let selects2ndHalf = [];

    if (selects) {
      for (let i = 0; i < selects.length; i++) {
        if (i < selects.length / 2) selects1stHalf.push(selects[i]);
        else selects2ndHalf.push(selects[i]);
      }
    }

    let goToNextStepButton = (
      <MenuButton
        id="submit-button"
        onClick={() => this.onComplete()}
        disabled={this.state.disabled}
      >
        <FormattedMessage id="Import" />
      </MenuButton>
    );

    return (
      <Modal
        show={true}
        onHide={() => this.props.closeModal()}
        backdrop={"static"}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>{this.props.title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <h4 className="w-100 text-center">
            <FormattedMessage id="Step2.Desc" />
          </h4>

          {this.state.isProgressBarVisible && (
            <div className={"text-center mb-5 "}>
              <Icon
                icon="gear"
                size="3x"
                className="fa-spin text-success mb-3"
              />
              <div className="progress" style={{ height: "30px" }}>
                <div
                  className="progress-bar progress-bar-striped progress-bar-animated bg-success"
                  role="progressbar"
                  aria-valuenow="100"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style={{ width: "100%" }}
                >
                  <strong>
                    <FormattedMessage id="Import.Save.Data" />
                  </strong>
                </div>
              </div>
            </div>
          )}

          {this.state.isFormVisible && (
            <Container>
              <Alert variant="warning" className="pt-1 pb-0">
                <Col md={12} className="d-flex justify-content-center">
                  <Row>
                    <Col className="text-end pe-0">
                      <FormattedMessage id="File.Auto.Detect.Columns" />
                    </Col>
                    <Col sm={1}>
                      <SwitchToggle
                        id="auto-matching"
                        onChange={() => this.updateAutoMatching()}
                        checked={this.state.autoMatching}
                      />
                    </Col>
                  </Row>
                </Col>
              </Alert>
              <Row>
                <Col>{selects1stHalf}</Col>

                <Col>{selects2ndHalf}</Col>
              </Row>
              <Row>
                <Col>
                  {this.state.missingRequiredColumns.length > 0 && (
                    <Alert variant="danger">
                      <Row className="align-items-center">
                        <Col className="col-auto">
                          <Icon icon="triangle-exclamation" size="3x" />
                        </Col>
                        <Col>
                          <FormattedMessage id="File.Auto.Detect.Columns.No.Match.1" />
                          <ul className="pb-0 mb-2">
                            {this.state.missingRequiredColumns.map(
                              (requiredColumn, index) => (
                                <li key={index}>
                                  <Badge bg="danger">{requiredColumn}</Badge>
                                </li>
                              ),
                            )}
                          </ul>
                          <FormattedMessage id="File.Auto.Detect.Columns.No.Match.2" />
                        </Col>
                      </Row>
                    </Alert>
                  )}
                </Col>
              </Row>
            </Container>
          )}
        </Modal.Body>

        <Modal.Footer>
          <MenuButton
            variant="secondary"
            onClick={() => this.props.closeModal()}
          >
            <FormattedMessage id="Cancel" />
          </MenuButton>
          {goToNextStepButton}
        </Modal.Footer>
        {this.state.modal}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    //
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(ImportExcelFileColumnsModal));
