import React, { useEffect } from "react";
import { Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import { injectIntl } from "react-intl";
import Notification from "../Notification";
import Icon from "../Icon";
import { useIntl } from "react-intl";

interface MenuButtonProps {
  id?: string;
  icon?: string;
  size?: "sm" | "lg";
  processing?: boolean;
  hover?: string;
  placement?: "top" | "bottom" | "left" | "right";
  type?: "button" | "submit" | "reset";
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
  variant?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

const MenuButton: React.FC<MenuButtonProps> = ({
  id,
  icon,
  size,
  processing = false,
  hover,
  placement = "top",
  type = "button",
  className,
  disabled = false,
  onClick,
  variant = "primary",
  style,
  children,
}) => {
  const intl = useIntl();

  useEffect(() => {
    if (processing) {
      Notification({
        type: "success",
        title: intl.formatMessage({ id: "Processing" }),
        description: intl.formatMessage({ id: "Plz.Wait" }),
        duration: 2000,
      });
    }
  }, [processing, intl]);

  const setAdditionalClasses = () =>
    className ? `text-nowrap ${className}` : "text-nowrap";

  const handleClick = onClick || (() => {});

  const iconElement = icon ? (
    <Icon
      icon={processing ? "fa-solid fa-circle-notch" : icon}
      spin={processing}
      fixedWidth={true}
    />
  ) : null;

  return (
    <>
      {hover ? (
        <OverlayTrigger
          delay={{ hide: 0, show: 0 }}
          overlay={(props) => <Tooltip {...props}>{hover}</Tooltip>}
          placement={placement}
        >
          <Button
            id={id}
            variant={variant}
            className={setAdditionalClasses()}
            onClick={handleClick}
            disabled={disabled}
            size={size}
            type={type}
            style={style}
          >
            {iconElement}
            {children && " "}
            {children}
          </Button>
        </OverlayTrigger>
      ) : (
        <Button
          id={id}
          variant={variant}
          className={setAdditionalClasses()}
          onClick={handleClick}
          disabled={disabled}
          size={size}
          type={type}
          style={style}
        >
          {iconElement}
          {children && " "}
          {children}
        </Button>
      )}
    </>
  );
};

export default injectIntl(MenuButton);
