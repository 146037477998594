import React, { useState, useEffect } from "react";
import { Image, ImageProps } from "react-bootstrap";

interface ImageWithFallbackProps extends ImageProps {
  loadingSrc?: string;
  src: string;
  fallbackSrc?: string;
  alt?: string;
}

const ImageWithFallback: React.FC<ImageWithFallbackProps> = ({
  loadingSrc = "/images/loading.gif",
  src,
  fallbackSrc = "/images/no_image_512.png",
  alt = "",
  ...props
}) => {
  const [imageSrc, setImageSrc] = useState<string>(loadingSrc);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [hasError, setHasError] = useState<boolean>(false);

  useEffect(() => {
    setImageSrc(src);
    setIsLoading(true);
    setHasError(false);
  }, [src]);

  const handleLoad = () => {
    setIsLoading(false);
  };

  const handleError = () => {
    if (!hasError) {
      setImageSrc(fallbackSrc);
      setIsLoading(false);
      setHasError(true);
    }
  };

  return (
    <Image
      src={isLoading ? loadingSrc : imageSrc}
      alt={alt}
      onLoad={handleLoad}
      onError={handleError}
      {...props}
    />
  );
};

export default ImageWithFallback;
