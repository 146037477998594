import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import {
  deleteTypeofControl,
  updateDeskProduct,
} from "../../../../actions/settings/deskCategories/admin/deskProducts.js";
import ConfirmationModal from "../../../sub/modals/ConfirmationModal.js";
import { Alert, Col, Row } from "react-bootstrap";
import DeskProductsControlPointsModal from "./DeskProductsControlPointsModal.js";
import MenuButton from "../../../sub/bootstrap/MenuButton";

class DeskProductsControlPoints extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
    };
  }

  deleteDeskControl(index) {
    const data = {
      deskProductId: this.props.deskProductId,
      type: this.props.controlPointType,
      index: index,
    };

    this.props.onDeleteDeskControl(data);
  }

  closeModal() {
    this.setState({ modal: null });
  }

  openControlPointModal(index) {
    this.setState({
      modal: (
        <DeskProductsControlPointsModal
          index={index}
          deskProductId={this.props.deskProductId}
          controlPoints={this.props.controlPoints}
          controlPointType={this.props.controlPointType}
          closeModal={() => this.closeModal()}
        />
      ),
    });
  }

  openConfModal(index) {
    this.setState({
      modal: (
        <ConfirmationModal
          variant="danger"
          title={<FormattedMessage id="Confirm" />}
          onAccept={() => this.deleteDeskControl(index)}
          onDecline={() => this.closeModal()}
        >
          {this.props.controlPointType === "question"
            ? "Type.Questionnary.Remove.Confirmation"
            : "Type.Recommendation.Remove.Confirmation"}
        </ConfirmationModal>
      ),
    });
  }

  onSort(controlPointType, array, index, direction) {
    const fieldName =
      controlPointType === "question"
        ? "relatedQuestions"
        : "relatedRecommendations";
    direction = direction === "up" || direction === "down" ? direction : "down";

    const newIndex = direction === "up" ? index - 1 : index + 1;

    const newArray = [...array];
    const temp = newArray[index];
    newArray[index] = newArray[newIndex];
    newArray[newIndex] = temp;

    const data = {
      _id: this.props.deskProductId,
      [fieldName]: newArray,
    };

    this.props.onUpdateDeskProduct(data);
  }

  render() {
    const { deskProductId, controlPoints, controlPointType } = this.props;

    if (!deskProductId || !controlPoints || !controlPointType) return;

    const title =
      controlPointType === "question" ? "Questionnary" : "Recommendation";
    const EmptyTxt =
      controlPointType === "question"
        ? "Empty.Questionnary"
        : "Empty.Recommendations";

    let deskProductControlPointNodes;

    if (controlPoints && controlPoints.length > 0) {
      deskProductControlPointNodes = controlPoints.map((txt, index) => {
        const disabledUp = index === 0;
        const disabledDown = index >= controlPoints.length - 1;
        return (
          <tr key={`${controlPointType}-${deskProductId}-${index}`}>
            <td className="align-middle">{txt}</td>
            <td className="align-middle col-auto text-end text-nowrap">
              <MenuButton
                icon="arrow-up"
                className="me-1"
                onClick={(e) =>
                  !disabledUp &&
                  this.onSort(controlPointType, controlPoints, index, "up")
                }
                disabled={disabledUp}
              />
              <MenuButton
                icon="arrow-down"
                className="me-1"
                onClick={() =>
                  !disabledDown &&
                  this.onSort(controlPointType, controlPoints, index, "down")
                }
                disabled={disabledDown}
              />
              <MenuButton
                icon="pen-to-square"
                className="me-1"
                onClick={(e) => this.openControlPointModal(index)}
              />
              <MenuButton
                icon="trash"
                onClick={() => this.openConfModal(index)}
              />
            </td>
          </tr>
        );
      });
    } else {
      deskProductControlPointNodes = (
        <tr>
          <td colSpan={2}>
            <Alert variant="warning">
              <FormattedMessage
                id={EmptyTxt}
                values={{ type: this.props.productTypeName }}
              />
            </Alert>
          </td>
        </tr>
      );
    }

    return (
      <div>
        <Alert variant="info">
          <FormattedMessage id="Arguments.Notice" />
        </Alert>
        <table className="table table-striped col-12 tablee4mad mt-3">
          <thead>
            <tr>
              <th className="w-100">
                <Row className="align-items-center">
                  <Col className="col-auto pe-0">
                    <FormattedMessage id={title} />
                  </Col>
                  <Col>
                    <MenuButton
                      icon="square-plus"
                      size="sm"
                      onClick={() => this.openControlPointModal()}
                    />
                  </Col>
                </Row>
              </th>
              <th className="align-middle text-center">
                <FormattedMessage id="Actions" />
              </th>
            </tr>
          </thead>
          {deskProductControlPointNodes && (
            <tbody>{deskProductControlPointNodes}</tbody>
          )}
        </table>

        {this.state.modal}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    //
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onDeleteDeskControl: (data) => dispatch(deleteTypeofControl(data)),
    onUpdateDeskProduct: (data) => dispatch(updateDeskProduct(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(DeskProductsControlPoints));
