import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import APIUrl from "../../APIUrl";
import AutoSuggestProducts from "../sub/fields/autosuggest/AutoSuggestProducts";
import { getProductsByMercurialIdAdmin } from "../../actions/products/products";
import Util from "../../util/Util";
import MercurialStatus from "../../enums/MercurialStatus";
import ImageWithFallback from "../sub/ImageWithFallback";
import MenuButton from "../sub/bootstrap/MenuButton";
import TableToolbar from "../sub/bootstrap/TableToolbar";
import { Card, Col, Form, FormSelect, Row } from "react-bootstrap";
import Icon from "../sub/Icon";
import { nanoid } from "nanoid";
import Products from "../products/Products";
import {
  deleteFamilyAdmin,
  getFamilies,
  sortFamily,
} from "../../actions/families/families";
import FamilyModal from "./FamilyModal";
import ConfirmationModal from "../sub/modals/ConfirmationModal";
import Notification from "../sub/Notification";
import ProductsParserUtil from "../../util/ProductsParserUtil";
import ActionMenu from "../sub/ActionMenu";

class FamiliesAdmin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      clientId: this.props.clientId || "",
      mercurialId: this.props.mercurialId || "",
      selectedClient: "",
      selectedCatalog: "",
      family: null,
      product: null,
      modal: null,
    };
  }

  componentDidMount() {
    if (this.state.mercurialId === "") return;

    this.props.onGetFamiliesByMercurialId({
      mercurialId: this.state.mercurialId,
    });
    this.props.onGetProductsByMercurialIdAdmin(this.state.mercurialId, true);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.mercurialId === "") return;

    if (this.props.deskProductId) {
      if (prevProps.mercurialId !== this.props.mercurialId) {
        this.props.onGetFamiliesByMercurialId({
          mercurialId: this.props.mercurialId,
        });
        this.props.onGetProductsByMercurialIdAdmin(
          this.props.mercurialId,
          true,
        );
        this.setState({ family: null, product: null });
      }
    } else {
      if (
        prevState.mercurialId !== this.state.mercurialId ||
        prevState.clientId !== this.state.clientId
      ) {
        this.props.onGetFamiliesByMercurialId({
          mercurialId: this.state.mercurialId,
        });
        this.props.onGetProductsByMercurialIdAdmin(
          this.state.mercurialId,
          true,
        );
      }
    }
  }

  selectClient(clientId) {
    let client = this.props.clients.find((c) => c._id === clientId);
    if (!client) {
      this.setState({ clientId: "", mercurialId: "" });
      return null;
    }
    let mercurialId = client.mercurial_Id ? client.mercurial_Id : "";
    this.setState({ clientId: clientId, mercurialId: mercurialId });
  }

  selectMercurial(mercurialId) {
    this.setState({
      mercurialId: mercurialId,
      clientId: "",
      selectedClient: "",
    });
  }

  closeModal() {
    this.setState({
      modal: null,
    });
  }

  onSearch(value, products, mercurialId) {
    // If it's a product
    for (let product of products) {
      if (product.ref === value.ref && product.mercurial_id === mercurialId) {
        this.setState({ family: product.family, product: product });
      }
    }
  }

  openFamilyModal(family, currentImage) {
    this.setState({
      modal: (
        <FamilyModal
          family={family}
          closeModal={() => this.closeModal()}
          currentImage={currentImage}
          mercurialId={this.state.mercurialId}
        />
      ),
    });
  }

  openConfModal(title, content, successCallback) {
    this.setState({
      modal: (
        <ConfirmationModal
          title={title}
          context={"danger"}
          mandatoryConfirmation
          onAccept={successCallback}
          onDecline={() => this.closeModal()}
        >
          {content}
        </ConfirmationModal>
      ),
    });
  }

  deleteFamily(family) {
    const modalTitle = <FormattedMessage id="Confirm" />;
    const modalContent = (
      <React.Fragment>
        <FormattedMessage
          id="Family.Remove.Confirmation"
          values={{ name: family.name }}
        />
        <br />
        <br />
        <Card className="text-white bg-danger">
          <Card.Header>
            <Icon icon="triangle-exclamation" className="me-2 text-white" />
            <FormattedMessage id="Warning" />
          </Card.Header>
          <Card.Body>
            <Card.Text>
              <FormattedMessage id="Family.Remove.Warning.Content" />
            </Card.Text>
          </Card.Body>
        </Card>
      </React.Fragment>
    );

    const successCallback = () =>
      this.props.onDeleteFamilyAdmin(
        { mercurial_id: this.state.mercurialId, familyId: family._id },
        () => {
          Notification({
            type: "success",
            description: this.props.intl.formatMessage({
              id: "Family.Deleted",
            }),
          });
          this.closeModal();
        },
      );

    this.openConfModal(modalTitle, modalContent, successCallback);
  }

  onSort(family, direction) {
    if (!family) return;

    const data = {
      mercurial_id: this.state.mercurialId,
      familyId: family._id,
      direction: direction,
    };

    switch (direction) {
      case "up":
        data.updatedValue = family.sortIndex - 1;
        break;
      case "down":
        data.updatedValue = family.sortIndex + 1;
        break;
      case "first":
        data.updatedValue = 0;
        break;
      case "last":
        data.updatedValue = this.props.families.length;
        break;
      default:
        data.direction = "down";
        data.updatedValue = family.sortIndex + 1;
        break;
    }

    this.props.onSortFamily(data);
  }

  getClientsSelectOptions() {
    const options = [];
    for (const c of this.props.clients) {
      options.push({
        value: c._id,
        label: Util.formatFullName(c.first_name, c.name),
      });
    }
    return options;
  }

  getCatalogSelectOptions() {
    const options = [];
    for (const mercurial of this.props.mercurials) {
      const now = new Date();
      const hasExpired =
        mercurial.end_date &&
        (new Date(mercurial.start_date) > now ||
          new Date(mercurial.end_date) < now);
      if (!hasExpired && mercurial.status !== MercurialStatus.INACTIVE) {
        options.push({ value: mercurial._id, label: mercurial.name });
      }
    }
    return options;
  }

  render() {
    const mercurialId = this.props.deskProductId
      ? this.props.mercurialId
      : this.state.mercurialId;

    const clientSelectNode = (
      <Col>
        <FormSelect
          id="mercurials-filter-clientId"
          placeholder={this.props.intl.formatMessage({ id: "Select.Client" })}
          onChange={(e) => {
            this.selectClient(e.target.value);
            this.setState({ selectedClient: e.target.value });
          }}
          value={this.state.selectedClient}
        >
          {this.getClientsSelectOptions().map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </FormSelect>
      </Col>
    );

    const mercurialsSelectNode = (
      <Col>
        <FormSelect
          id="mercurials-filter-mercurialId"
          placeholder={this.props.intl.formatMessage({
            id: "Select.Mercurial",
          })}
          onChange={(e) => {
            this.selectMercurial(e.target.value);
            this.setState({ selectedCatalog: e.target.value });
          }}
          value={this.state.selectedCatalog}
        >
          {this.getCatalogSelectOptions().map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </FormSelect>
      </Col>
    );
    // Filters

    // No 'families'? No render
    if (
      this.props.families.length === 0 ||
      this.props.products.length === 0 ||
      mercurialId === ""
    ) {
      return (
        <TableToolbar
          message={
            <FormattedMessage
              id={
                mercurialId === ""
                  ? "Client.No.Associated.Catalog"
                  : "Empty.Families"
              }
            />
          }
        >
          <Form.Group as={Row} className="align-items-center">
            <Col className="col-auto">{clientSelectNode}</Col>
            <Col className="col-auto">{mercurialsSelectNode}</Col>
            <Col className="text-end">
              <MenuButton
                icon="arrow-circle-left"
                onClick={() => this.props.setState({ mercurialId: "" })}
              >
                {<FormattedMessage id="Return" />}
              </MenuButton>
            </Col>
          </Form.Group>
        </TableToolbar>
      );
    }

    if (this.state.family && mercurialId) {
      return (
        <Products
          mercurialId={mercurialId}
          family={this.state.family}
          product={this.state.product}
          deskProductId={this.props.deskProductId}
          setState={(state) => this.setState(state)}
          mode={this.props.mode}
          addProduct={(product) => this.props.addProduct(product)}
          deleteProduct={(product) => this.props.deleteProduct(product)}
          productsFromCatalog={this.props.productsFromCatalog}
        />
      );
    }

    let suggestions = [];

    this.props.products.forEach((element) => {
      let obj = {};
      obj.designation = ProductsParserUtil.swapDesignationDelimiter(
        element.designation,
      );
      obj.caracteristiques = element.caracteristiques;
      obj.ref = element.ref;
      obj.mercurial_id = element.mercurial_id;
      obj.ref_frn = element.ref_frn;
      obj.famille = element.famille;
      obj.sous_famille = element.sous_famille;
      suggestions.push(obj);
    });

    const canManage =
      (!this.props.user.supervisor_id &&
        this.props.user.permissions.can_manage_catalog) ||
      (this.props.user.supervisor_id &&
        this.props.user.has_write_permission);

    return (
      <React.Fragment>
        <TableToolbar>
          <Form.Group as={Row} className="align-items-center">
            {!this.props.deskProductId && (
              <>
                <Col className="col-auto">
                  <MenuButton
                    icon="arrow-circle-left"
                    onClick={() => this.props.setState({ mercurialId: "" })}
                  >
                    {<FormattedMessage id="Return" />}
                  </MenuButton>
                </Col>
                <Col className="col-auto">{clientSelectNode}</Col>
                <Col className="col-auto">{mercurialsSelectNode}</Col>
              </>
            )}
            {!Util.emptyString(mercurialId) && (
              <Col>
                <AutoSuggestProducts
                  id="search"
                  name="search"
                  onSuggestionSelected={(value) =>
                    this.onSearch(value, this.props.products, mercurialId)
                  }
                  suggestions={suggestions}
                  placeholder={"Search.Product.Placeholder"}
                />
              </Col>
            )}
            {!this.props.deskProductId && canManage && (
              <Col className="col-auto text-end">
                <MenuButton onClick={() => this.openFamilyModal(null, null)}>
                  <FormattedMessage id="Add.Family" />
                </MenuButton>
              </Col>
            )}
          </Form.Group>
        </TableToolbar>

        <Row>
          {this.props.families.map((family) => {
            const imgSrc = `${APIUrl.getFamilyImg}${family.mercurial_id}/${family._id}/${family.name}/${family.hasCustomImg}?token=${APIUrl.jwtToken}`;
            const disabledUp = family.sortIndex === 0;
            const disabledDown =
              this.props.families.length - 1 <= family.sortIndex;
            const menuItems = [];
            menuItems.push(
              {
                icon: "pen-to-square",
                action: () => this.openFamilyModal(family, imgSrc),
                text: <FormattedMessage id="Modify" />,
              },
              !disabledUp && {
                icon: "arrow-up",
                action: () => this.onSort(family, "first"),
                text: <FormattedMessage id="Move.First" />,
              },
              !disabledUp && {
                icon: "arrow-left",
                action: () => this.onSort(family, "up"),
                text: <FormattedMessage id="Move.Left" />,
              },
              !disabledDown && {
                icon: "arrow-right",
                action: () => this.onSort(family, "down"),
                text: <FormattedMessage id="Move.Right" />,
              },
              !disabledDown && {
                icon: "arrow-down",
                action: () => this.onSort(family, "last"),
                text: <FormattedMessage id="Move.Last" />,
              },
              {
                id: "delete" + family._id,
                icon: "trash",
                action: () => this.deleteFamily(family),
                text: <FormattedMessage id="Delete" />,
              },
            );
            const menuAction = (
              <ActionMenu className="ms-auto" size="sm" items={menuItems} />
            );

            return (
              <Col
                sm={12}
                md={6}
                lg={4}
                className="mb-4 mx-auto mw-400"
                key={nanoid()}
              >
                <Card>
                  <Card.Header>
                    <h6 className="text-dark text-center mb-0">
                      <Row>
                        <Col className="d-flex align-items-center pe-0">
                          <strong>{family.name}</strong>
                          {!this.props.deskProductId && canManage && menuAction}
                        </Col>
                      </Row>
                    </h6>
                  </Card.Header>
                  <Card.Body
                    className="cursor-pointer text-center"
                    onClick={() => this.setState({ family: family })}
                  >
                    <ImageWithFallback
                      src={imgSrc}
                      className="w-50 zoomable-sm m-1"
                    />
                  </Card.Body>
                </Card>
              </Col>
            );
          })}
        </Row>
        {this.state.modal}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    products: state.products,
    mercurials: state.mercurials,
    clients: state.clients,
    families: state.families,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetFamiliesByMercurialId: (data) => {
      dispatch(getFamilies(data));
    },
    onGetProductsByMercurialIdAdmin: (mercurialId, getAllProducts) =>
      dispatch(getProductsByMercurialIdAdmin(mercurialId, getAllProducts)),
    onDeleteFamilyAdmin: (data, successCallback) =>
      dispatch(deleteFamilyAdmin(data, successCallback)),
    onSortFamily: (data, successCallback) =>
      dispatch(sortFamily(data, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(FamiliesAdmin));
